<template>
  <div class="card">
    <div class="card-body">
      <div class="col-11 ml-5">
        <form @submit.prevent="joinAdventureByCode" class="d-flex align-items-center">
          <div>Utiliser un code : </div>
          <input class="form-control m-1" type="text" v-model="adventureCode" placeholder="Entrez le code" style="width: auto; max-width: 20ch;"/>
          <button type="submit" class="btn btn-outline-success">Rejoindre</button>
          <div class="m-2"><i>{{message}}</i></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {joinAdventureByCode} from "@/services/dndgate/endpoints/adventures";

export default {
    data() {
      return {
        adventureCode: '',
        adventure: null,
        message: ''
      };
    },
    methods: {
      async joinAdventureByCode() {
        this.isLoading = true
        try {
          this.adventure = await joinAdventureByCode(this.adventureCode)
          this.$store.dispatch('showTemporaryPopup', {
            message: 'Aventure rejointe',
          });
          this.$router.push({name: 'tavern/adventure', params:{adventureId: this.adventure.id}})
        } catch (error) {
          this.$store.dispatch('showPopup', {
            title: '/!\\ Erreur /!\\',
            message: error.response.data,
            isSuccess: false
          });
        } finally {
          this.isLoading = false
        }
      },

    },
  };
</script>

<style scoped>

</style>
  