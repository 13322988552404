import * as THREE from 'three';
import * as CANNON from "cannon-es";

import face1 from '@/assets/forge/dice/d6/face1.png'
import face2 from '@/assets/forge/dice/d6/face2.png'
import face3 from '@/assets/forge/dice/d6/face3.png'
import face4 from '@/assets/forge/dice/d6/face4.png'
import face5 from '@/assets/forge/dice/d6/face5.png'
import face6 from '@/assets/forge/dice/d6/face6.png'

function createDice(type) {
    switch (type) {
        case 4:
            return createD4Model()
        case 6:
            return createD6Model()
        case 8:
            return createD8Model()
        case 10:
            return createD10Model()
        case 12:
            return createD12Model()
        case 20:
            return createD20Model()
    }
}

function createD4Model() {
    /*
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4)
    ];*/

    // Création de la géométrie du tétraèdre
    const geometry = new THREE.TetrahedronGeometry(1);

    // Les sommets du tétraèdre
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        vertices.push(new CANNON.Vec3(positions[i], positions[i + 1], positions[i + 2]));
    }

    // Les faces doivent être définies dans l'ordre CCW (sens antihoraire)
    const faces = [
        [0, 1, 2], // face 1
        [0, 3, 1], // face 2
        [0, 2, 3], // face 3
        [1, 3, 2]  // face 4
    ];

    const shape = new CANNON.ConvexPolyhedron({vertices, faces});

    // Appliquer les textures aux matériaux du dé
    //const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    const materials = new THREE.MeshBasicMaterial({ color: 0xffffff })

    return createModel(geometry, materials, shape);
}

function createD6Model() {
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4),
        textureLoader.load(face5),
        textureLoader.load(face6)
    ];

    // Appliquer les textures aux matériaux du dé
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const shape = new CANNON.Box(new CANNON.Vec3(0.5, 0.5, 0.5));
    const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));

    return createModel(geometry, materials, shape)
}

function createD8Model() {
    /*
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4),
        textureLoader.load(face5),
        textureLoader.load(face6),
        textureLoader.load(face7),
        textureLoader.load(face8)
    ];*/

    // Création de la géométrie de l'octaèdre
    const geometry = new THREE.OctahedronGeometry(1);

    // Les sommets de l'octaèdre
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        vertices.push(new CANNON.Vec3(positions[i], positions[i + 1], positions[i + 2]));
    }

    // Les faces doivent être définies dans l'ordre CCW (sens antihoraire)
    const faces = [
        [0, 2, 4], // face 1
        [0, 4, 6], // face 2
        [0, 6, 3], // face 3
        [0, 3, 2], // face 4
        [1, 2, 3], // face 5
        [1, 3, 6], // face 6
        [1, 6, 4], // face 7
        [1, 4, 2]  // face 8
    ];

    const shape = new CANNON.ConvexPolyhedron({vertices, faces});

    // Appliquer les textures aux matériaux du dé
    //const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    const materials = new THREE.MeshBasicMaterial({color: 0xffffff});

    return createModel(geometry, materials, shape);
}

function createD10Model() {
    /*
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4),
        textureLoader.load(face5),
        textureLoader.load(face6),
        textureLoader.load(face7),
        textureLoader.load(face8),
        textureLoader.load(face9),
        textureLoader.load(face10)
    ];*/

    // Définir les sommets du décadécaèdre (bipyramide pentagonale)
    const vertices = [
        new CANNON.Vec3(0, 1, 0),      // 0: top vertex
        new CANNON.Vec3(0.951, 0.309, 0),  // 1: top pentagon vertices
        new CANNON.Vec3(0.588, 0.309, 0.809),
        new CANNON.Vec3(-0.588, 0.309, 0.809),
        new CANNON.Vec3(-0.951, 0.309, 0),
        new CANNON.Vec3(-0.588, 0.309, -0.809),
        new CANNON.Vec3(0.588, 0.309, -0.809),
        new CANNON.Vec3(0.951, -0.309, 0), // 7: bottom pentagon vertices
        new CANNON.Vec3(0.588, -0.309, 0.809),
        new CANNON.Vec3(-0.588, -0.309, 0.809),
        new CANNON.Vec3(-0.951, -0.309, 0),
        new CANNON.Vec3(-0.588, -0.309, -0.809),
        new CANNON.Vec3(0.588, -0.309, -0.809),
        new CANNON.Vec3(0, -1, 0)     // 13: bottom vertex
    ];

    // Définir les faces du décadécaèdre
    const faces = [
        [0, 2, 1], // Face 1
        [0, 3, 2], // Face 2
        [0, 4, 3], // Face 3
        [0, 5, 4], // Face 4
        [0, 6, 5], // Face 5
        [0, 1, 6], // Face 6
        [1, 2, 8], // Face 7
        [2, 3, 9], // Face 8
        [3, 4, 10], // Face 9
        [4, 5, 11], // Face 10
        [5, 6, 12], // Face 11
        [6, 1, 7], // Face 12
        [1, 8, 7], // Face 13
        [2, 9, 8], // Face 14
        [3, 10, 9], // Face 15
        [4, 11, 10], // Face 16
        [5, 12, 11], // Face 17
        [6, 7, 12], // Face 18
        [7, 8, 13], // Face 19
        [8, 9, 13], // Face 20
        [9, 10, 13], // Face 21
        [10, 11, 13], // Face 22
        [11, 12, 13], // Face 23
        [12, 7, 13], // Face 24
    ];

    const shape = new CANNON.ConvexPolyhedron({ vertices, faces });

    // Création de la géométrie Three.js correspondant à ces sommets et faces
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    faces.forEach(face => {
        face.forEach(index => {
            positions.push(vertices[index].x, vertices[index].y, vertices[index].z);
        });
    });

    const positionAttribute = new THREE.Float32BufferAttribute(positions, 3);
    geometry.setAttribute('position', positionAttribute);
    geometry.computeVertexNormals();

    // Appliquer les textures aux matériaux du dé
    //const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    const materials = new THREE.MeshBasicMaterial({ color: 0xffffff });

    return createModel(geometry, materials, shape);
}

function createD12Model() {
    /*
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4),
        textureLoader.load(face5),
        textureLoader.load(face6),
        textureLoader.load(face7),
        textureLoader.load(face8),
        textureLoader.load(face9),
        textureLoader.load(face10),
        textureLoader.load(face11),
        textureLoader.load(face12)
    ];*/

    // Création de la géométrie du dodécaèdre
    const geometry = new THREE.DodecahedronGeometry(1);

    // Les sommets du dodécaèdre
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        vertices.push(new CANNON.Vec3(positions[i], positions[i + 1], positions[i + 2]));
    }

    // Les faces doivent être définies dans l'ordre CCW (sens antihoraire)
    const faces = [
        [0, 1, 2, 3, 4],   // face 1
        [0, 4, 5, 10, 11], // face 2
        [1, 2, 6, 7, 8],   // face 3
        [3, 4, 8, 9, 10],  // face 4
        [5, 6, 7, 11, 12], // face 5
        [6, 7, 8, 12, 13], // face 6
        [8, 9, 10, 13, 14],// face 7
        [10, 11, 12, 14, 15],// face 8
        [0, 1, 5, 6, 9],   // face 9
        [1, 2, 7, 8, 10],  // face 10
        [2, 3, 8, 9, 11],  // face 11
        [3, 4, 9, 10, 12]  // face 12
    ];

    const shape = new CANNON.ConvexPolyhedron({ vertices, faces });

    // Appliquer les textures aux matériaux du dé
    //const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    const materials = new THREE.MeshBasicMaterial({ color: 0xffffff });

    return createModel(geometry, materials, shape);
}

function createD20Model() {
    /*
    const textureLoader = new THREE.TextureLoader();
    const textures = [
        textureLoader.load(face1),
        textureLoader.load(face2),
        textureLoader.load(face3),
        textureLoader.load(face4),
        textureLoader.load(face5),
        textureLoader.load(face6),
        textureLoader.load(face7),
        textureLoader.load(face8),
        textureLoader.load(face9),
        textureLoader.load(face10),
        textureLoader.load(face11),
        textureLoader.load(face12),
        textureLoader.load(face13),
        textureLoader.load(face14),
        textureLoader.load(face15),
        textureLoader.load(face16),
        textureLoader.load(face17),
        textureLoader.load(face18),
        textureLoader.load(face19),
        textureLoader.load(face20)
    ];*/

    // Création de la géométrie de l'icosaèdre
    const geometry = new THREE.IcosahedronGeometry(1);

    // Les sommets de l'icosaèdre
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        vertices.push(new CANNON.Vec3(positions[i], positions[i + 1], positions[i + 2]));
    }

    // Les faces doivent être définies dans l'ordre CCW (sens antihoraire)
    const faces = [
        [0, 11, 5],
        [0, 5, 1],
        [0, 1, 7],
        [0, 7, 10],
        [0, 10, 11],
        [1, 5, 9],
        [5, 11, 4],
        [11, 10, 2],
        [10, 7, 6],
        [7, 1, 8],
        [3, 9, 4],
        [3, 4, 2],
        [3, 2, 6],
        [3, 6, 8],
        [3, 8, 9],
        [4, 9, 5],
        [2, 4, 11],
        [6, 2, 10],
        [8, 6, 7],
        [9, 8, 1]
    ];


    const shape = new CANNON.ConvexPolyhedron({ vertices, faces });

    // Appliquer les textures aux matériaux du dé
    //const materials = textures.map(texture => new THREE.MeshBasicMaterial({ map: texture }));
    const materials = new THREE.MeshBasicMaterial({ color: 0xffffff });

    return createModel(geometry, materials, shape);
}

function createModel(geometry, material,  shape) {
    let body = new CANNON.Body({mass: 1});
    body.addShape(shape);
    const mesh = new THREE.Mesh(geometry, material);
    return {
        body,
        getObject() {
            return mesh;
        },
        updateBodyFromMesh() {
            body.position.copy(mesh.position);
            body.quaternion.copy(mesh.quaternion);
        },
        updateMeshFromBody() {
            mesh.position.copy(body.position);
            mesh.quaternion.copy(body.quaternion);
        }
    }
}

export {
    createDice,
}