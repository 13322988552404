<template>
  <div v-if="messageCompleted != null"
       class="message-card"
       :class="{ 'sent': messageCompleted.isSent, 'received': !messageCompleted.isSent }">
    <div class="message-content">
      <div class="username" v-if="!messageCompleted.isSent">
        {{ messageCompleted.sender.pseudo }}
        <hr>
      </div>
      <div class="message-text"><p>{{ messageCompleted.content }}</p></div>
    </div>
  </div>
</template>
  

<script>
  import {getUserById} from "@/services/dndgate/endpoints/users";

  export default {
    data() {
      return {
        userId: localStorage.getItem('id'),
        messageCompleted: null
      }
    },
		props: {
			message: Object,
		},
    mounted: async function() {
      await this.getMessageSender();
    },
    methods: {
      async getMessageSender() {
        this.isLoading = true
        try {
          const user = await getUserById(this.message.senderId)
          this.messageCompleted = {
            ...this.message,
            sender: user,
            isSent: (user.id === this.userId)};
        } finally {
          this.isLoading = false
        }
      },
    }
  };
</script>
  
<style scoped>

.message-card {
  display: flex;
  margin: 10px 0;
  align-items: flex-end;
}

.sent {
  flex-direction: row-reverse;
}

.received {
  flex-direction: row;
}

.message-content {
  max-width: 80%;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  background-color: #e2ffc7; /* couleur des messages envoyés */
}

.received .message-content {
  background-color: #5e5e5e; /* couleur des messages reçus */
  color: white;
  border: 1px solid #ddd;
}

.username {
  font-style: italic;
  color: lightgrey;
  font-size: 12px;
}

.username hr {
  margin: 1px;
}

.message-text p {
  margin-bottom: 0;
}

</style>
  