import axios from '../axios';

const endpoint = "/spellbooks"

/**
 * @param {SpellBookCreationRequestDto} requestBody
 */
const createSpellBook = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating spell book: ', error)
        throw error;
    }
}

/**
 * @param id
 * @param {SpellBookCreationRequestDto} requestBody
 */
const updateSpellBook = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating spell book: ', error)
        throw error;
    }
}

const deleteSpellBook = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting spell book: ', error)
        throw error;
    }
}

const getPersonalSpellBooks = async () => {
    try {
        const response = await axios.get(`${endpoint}/getMySpellBooks`);
        return response.data;
    } catch (error) {
        console.error('Error while getting spell book: ', error)
        throw error;
    }
}

const getSpellBookDetails = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting spell book details: ', error)
        throw error;
    }
}

/**
 *
 * @param {string} id
 * @param {AddSpellToBookRequestDto} requestBody
 */
const addSpellToBook = async (id, requestBody) => {
    try {
        const response = await axios.post(`${endpoint}/${id}/addSpell`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while adding spell in spell book: ', error)
        throw error;
    }
}

const removeSpellToBook = async (id, spellIndex) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}/removeSpell/${spellIndex}`);
        return response.data;
    } catch (error) {
        console.error('Error while removing spell in spell book: ', error)
        throw error;
    }
}

const exportSpellBook = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}/export-file`, { responseType: 'blob' });
        return response.data;
    } catch (error) {
        console.error('Error while removing spell in spell book: ', error)
        throw error;
    }
}

const importSpellBook = async (file) => {
    try {
        let formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(`${endpoint}/import-file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error while removing spell in spell book: ', error)
        throw error;
    }
}

export {
    createSpellBook,
    updateSpellBook,
    deleteSpellBook,
    getPersonalSpellBooks,
    getSpellBookDetails,
    addSpellToBook,
    removeSpellToBook,
    exportSpellBook,
    importSpellBook,
}