export default class GameCharacterCreationRequestDto {
    constructor(adventureId, name, race, martialClass, personality, background) {
        this.adventureId = adventureId
        this.name = name
        this.race = race
        this.martialClass = martialClass
        this.personality = personality
        this.background = background
    }
}
