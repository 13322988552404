<template>
  <div id="principal-view">
    <HeaderBar :title="this.selectedOption" @login="handleLogin" @logout="handleLogout"></HeaderBar>
    <div id="principal-body">
      <ForgeNavMenu id="principal-menu"></ForgeNavMenu>
      <div id="forge-content-background" class="d-flex justify-content-center">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <LoginComponents v-if="showLogin" @close-popup="closeLoginPopup" @userConnectedEvent="handleUserConnectedEvent"></LoginComponents>
</template>
  
<script>
  import HeaderBar from "@/views/shared/HeaderBar";
  import ForgeNavMenu from "@/views/forge/menu/ForgeNavMenu";
  import LoginComponents from "@/views/login/LoginComponents";

  export default {
    components: {HeaderBar, ForgeNavMenu, LoginComponents},
    data() {
      return {
        selectedOption: 'Forge',
        showLogin: false,
      };
    },
    methods: {
      handleLogin() {
        this.showLogin = true;
      },
      handleLogout() {
        this.$router.push({name: 'forge'});
      },
      closeLoginPopup() {
        this.showLogin = false;
      },
      handleUserConnectedEvent() {
        this.showLogin = false;
      }
    },
  };
</script>
  
<style scoped>

#forge-content-background {
  flex-grow: 1;
  background-image: url(@/assets/forge/background-forge.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-justify: none;
}

</style>
