<template>
  <div class="popup-overlay">
    <div class="popup-content">
      <h2>Importing spell book</h2>

      <form @submit.prevent="importSpellBook">
        <div>
          <input type="file" @change="handleFileUpload" required/>
        </div>

        <div class="d-flex justify-content-center m-4">
          <button type="submit" class="btn btn-outline-success">Import</button>
          <button class="btn btn-outline-danger" @click="cancel">Cancel</button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>

import {importSpellBook} from "@/services/dndgate/endpoints/spellbooks";

export default {
  data() {
    return {
      file: null,
      isLoading: false
    }
  },
  methods: {
    async handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async importSpellBook() {
      this.isLoading = true
      if (this.file) {
        try {
          await importSpellBook(this.file);
          this.$store.dispatch('showTemporaryPopup', {
            message: 'Spell book imported',
          });
          this.$emit('close')
        } catch (error) {
          this.$store.dispatch('showPopup', {
            title: '/!\\ Erreur /!\\',
            message: error.response.data,
            isSuccess: false
          });
        } finally {
          this.isLoading = false
        }
      }
    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>



</style>