<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="rule != null && !isLoading" class="markdown">
          <div v-html="this.formattedRule"></div>
          <hr>
          <div v-for="formattedSubsection in this.formattedSubsections" :key="formattedSubsection">
            <div v-html="formattedSubsection.html" ></div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getRuleDescription} from "@/services/dndofficial/endpoints/rules";
import MarkdownIt from "markdown-it/lib/index.mjs";

export default {
  data() {
    return {
      isLoading: false,
      rule: null,
      formattedRule: null,
      formattedSubsections: []
    };
  },
  watch: {
    ruleIndex() {
      this.getRuleDescription()
    }
  },
  computed: {
    ruleIndex() {
      return this.$route.params.ruleIndex;
    }
  },
  mounted: async function(){
    await this.getRuleDescription()
  },
  methods: {
    async getRuleDescription() {
      this.isLoading = true
      try {
        const response = await getRuleDescription(this.ruleIndex)
        this.rule = response.data.rule
        this.convertMarkdownToHtml()
      } catch (error) {
        this.rule = null
        this.formattedRule = null
        this.formattedSubsections = []
      } finally {
        this.isLoading = false
      }
    },
    convertMarkdownToHtml() {
      const md = new MarkdownIt();
      this.formattedRule = md.render(this.rule.desc)
      this.formattedSubsections = this.rule.subsections.map(subsection => ({
        ...subsection,
        html: md.render(subsection.desc)
      }));
    }
  }
}

</script>

<style scoped>

  .markdown {

  }

  .markdown h1 {
    color: #4C6477;
  }

  .markdown h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  .markdown h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  .markdown h4 {
    color: #435A3F;
    margin-bottom: 0;
  }

  .markdown hr {
    margin-top: 2px;
  }

  .markdown p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .markdown table {
    margin: 10px 70px 70px;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
  }

  .markdown table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 90%;
    max-width: 90%;
    white-space: nowrap;
    background-color: white;
  }

  .markdown table td, .markdown table th {
    padding: 8px;
  }

  .markdown table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  .markdown table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }

  .markdown table tr:nth-child(even) {
    background: #F8F8F8;
  }

</style>