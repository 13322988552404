<template>
  <div id="principal-view">
    <HeaderBar :title="selectedOption" @logout="handleLogout"></HeaderBar>
    <div id="principal-body">
      <TavernNavMenu id="principal-menu"></TavernNavMenu>
      <div id="tavern-content-background">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/views/shared/HeaderBar";
import TavernNavMenu from "@/views/tavern/menu/TavernNavMenu";

export default {
  components: { HeaderBar, TavernNavMenu },
  data() {
    return {
      selectedOption: 'Taverne',
    };
  },
  methods: {
    handleLogout() {
      this.$router.push({name: 'home'});
    },
  }
};
</script>


<style scoped>

#tavern-content-background {
  flex-grow: 1;
  background-image: url(@/assets/tavern/tavern-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-justify: none;
}

</style>
