<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="background != null && !isLoading">
          <h1>{{ background.name }}</h1>
          <h2 class="mt-4">{{ background.feature.name }}</h2>
          <hr>
          <p v-for="desc in background.feature.desc" :key="desc">{{ desc }}</p>
          <h2 class="mt-4">Suggested personalities</h2>
          <hr>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">D{{background.personality_traits.from.options.length}}</th>
                <th class="left-align">Personality trait</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(personality, index) in background.personality_traits.from.options" :key="personality">
                <td class="center-align"><b>{{ index+1 }}</b></td>
                <td class="left-align">{{ personality.string }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">D{{background.ideals.from.options.length}}</th>
                <th class="left-align">Ideal</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(ideal, index) in background.ideals.from.options" :key="ideal">
                <td class="center-align"><b>{{ index+1 }}</b></td>
                <td class="left-align"><b>{{ ideal.desc.split('.')[0] }}.</b> {{ ideal.desc.split('.')[1] }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">D{{background.bonds.from.options.length}}</th>
                <th class="left-align">Bond</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(bond, index) in background.bonds.from.options" :key="bond">
                <td class="center-align"><b>{{ index+1 }}</b></td>
                <td class="left-align">{{ bond.string }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">D{{background.flaws.from.options.length}}</th>
                <th class="left-align">Flaw</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(flaw, index) in background.flaws.from.options" :key="flaw">
                <td class="center-align"><b>{{ index+1 }}</b></td>
                <td class="left-align">{{ flaw.string }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import {getBackgroundDescription} from "@/services/dndofficial/endpoints/backgrounds";

  export default {
    data() {
      return {
        isLoading: false,
        background: null,
      };
    },
    watch: {
      backgroundIndex() {
        this.getBackgroundDescription()
      }
    },
    computed: {
      backgroundIndex() {
        return this.$route.params.backgroundIndex;
      }
    },
    mounted: async function(){
      await this.getBackgroundDescription()
    },
    methods: {
      async getBackgroundDescription() {
        this.isLoading = true
        try {
          const response = await getBackgroundDescription(this.backgroundIndex)
          this.background = response.data.background
        } catch (error) {
          this.background = null
        } finally {
          this.isLoading = false
        }
      },
    }
  };
</script>
  
<style scoped>

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  h4 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .table-wrapper{
    margin: 15px 0;
    box-shadow: 0 5px 10px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }

  .fl-table td, .fl-table th {
    padding: 8px;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: left;
  }

  .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  .fl-table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }


  .fl-table tr:nth-child(even) {
    background: #F8F8F8;
  }

</style>