<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="!isLoading">
          <h1>Personality</h1>
          <h2 class="mt-4">Alignment</h2>
          <hr>
          <div v-for="alignment in alignments" :key="alignment">
            <h3 class="mt-4">{{ alignment.name }} ({{ alignment.abbreviation }})</h3>
            <hr>
            <p>{{ alignment.desc }}</p>
          </div>

          <h2 class="mt-4">Languages</h2>
          <hr>
          <h3 class="mt-4">Common languages</h3>
          <hr>
          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">Language</th>
                <th class="left-align">Spoken by...</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="language in languages.filter(l => l.type === 'STANDARD')" :key="language">
                <td class="center-align"><b>{{ language.name }}</b></td>
                <td class="left-align">
                  <a v-for="speaker in language.typical_speakers" :key="speaker">{{ speaker }}, </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <h3 class="mt-4">Exotic languages</h3>
          <hr>
          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="center-align">Language</th>
                <th class="left-align">Spoken by...</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="language in languages.filter(l => l.type === 'EXOTIC')" :key="language">
                <td class="center-align"><b>{{ language.name }}</b></td>
                <td class="left-align">
                  <a v-for="speaker in language.typical_speakers" :key="speaker">{{ speaker }}, </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import {getPersonality} from "@/services/dndofficial/endpoints/personality";

  export default {
    data() {
      return {
        isLoading: false,
        alignments: [],
        languages: [],
      };
    },
    mounted: async function(){
      await this.getPersonality()
    },
    methods: {
      async getPersonality() {
        this.isLoading = true
        try {
          const response = await getPersonality()
          this.alignments = response.data.alignments
          this.languages = response.data.languages
        } catch (error) {
          this.alignments = []
          this.languages = []
        } finally {
          this.isLoading = false
        }
      },
    }
  };
</script>
  
<style scoped>

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  h4 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .table-wrapper{
    margin: 15px 0;
    box-shadow: 0 5px 10px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }

  .fl-table td, .fl-table th {
    padding: 8px;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: left;
  }

  .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  .fl-table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }


  .fl-table tr:nth-child(even) {
    background: #F8F8F8;
  }

</style>