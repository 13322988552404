<template>
  <div id="menu-content">
    <ul id="menu-list">
      <li>
        <character-category :isVisible="this.isVisible.character" @clicked="toggleCategory"/>
      </li>
      <li>
        <map-category :isVisible="this.isVisible.map" @clicked="toggleCategory"/>
      </li>
      <li>
        <div class="menu-title" @click="select('diceRoller')">Lanceur de dés</div>
      </li>
    </ul>
  </div>
</template>
  
<script>

  import CharacterCategory from "@/views/forge/menu/category/CharacterCategory";
  import MapCategory from "@/views/forge/menu/category/MapCategory";

  export default {
    components: {CharacterCategory, MapCategory},
    data() {
      return {
        isLoading: false,
        isVisible: {
          character: false,
          map: false,
        },
      };
    },
    methods: {
      toggleCategory(category) {
        event.stopPropagation();
        for (let visibility in this.isVisible) {
          if (visibility.toString() !== category.toString()) {
            this.isVisible[visibility] = false
          }
        }
        this.isVisible[category] = !this.isVisible[category];
      },
      select(index) {
        this.$router.push({ name: `forge/${index}`});
      }
    },
  };
</script>
  
<style scoped>

</style>