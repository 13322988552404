<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="d-flex justify-content-between">
          <h3 v-if="this.adventure.adventureNotes.length === 0">Aucune note</h3>
          <h3 v-else>Note du MJ</h3>
          <button class="btn btn-outline-primary" @click="getAdventureDetails"><font-awesome-icon icon="sync" /></button>
          <button v-if="imMaster" class="btn btn-outline-success" @click="createAdventureNote"><font-awesome-icon icon="plus" /> <font-awesome-icon icon="sticky-note" /></button>
        </div>
        <div class="overflow-auto mt-4">
          <ul>
            <li v-for="adventureNote in this.adventure.adventureNotes" :key="adventureNote">
              <adventure-note-item
                  v-if="imMaster || adventureNote.noteVisibility === 'PUBLIC'"
                  :im-master="imMaster"
                  :adventureNote=adventureNote
                  @deleted="handleAdventureNoteDeleted"></adventure-note-item>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getAdventureDetails} from "@/services/dndgate/endpoints/adventures";
import {getUserById} from "@/services/dndgate/endpoints/users";
import {getAdventureNotesByAdventureId} from "@/services/dndgate/endpoints/adventure-notes";
import AdventureNoteItem from "@/views/tavern/content/adventure/adventurenote/AdventureNoteItem";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { AdventureNoteItem, FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
      adventure: null,
      imMaster: false,
    };
  },
  watch: {
    adventureId() {
      this.getAdventureDetails()
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    }
  },
  mounted: async function() {
    await this.getAdventureDetails();
    this.imMaster = (this.adventure.gameMaster.id.toString() === localStorage.getItem('id').toString())
  },
  methods: {
    async getAdventureDetails() {
      this.isLoading = true
      try {
        this.adventure = await getAdventureDetails(this.adventureId)

        const gameMaster = await getUserById(this.adventure.creatorId);
        this.adventure = { ...this.adventure, gameMaster: gameMaster }
        this.adventure.adventureNotes = await getAdventureNotesByAdventureId(this.adventureId)
      } finally {
        this.isLoading = false
      }
    },
    createAdventureNote() {
      this.$router.push({name: 'tavern/adventure/createAdventureNote'})
    },
    handleAdventureNoteDeleted() {
      this.getAdventureDetails()
    }
  },
}
</script>

<style scoped>

</style>