import axios from '../axios';

const endpoint = "/planning";
const disponibilityEndpoint = `${endpoint}/disponibility`;

// Obtenir tous les plannings
const getPlannings = async () => {
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error while getting plannings:', error);
        throw error;
    }
};

/**
 * @param {PlanningCreationRequestDto} requestBody
 */
const createPlanning = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating planning: ', error)
        throw error;
    }
};

// Voir un planning via l'adventureId
const getPlanningByAdventure = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/adventure/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting spell book details: ', error)
        throw error;
    }
}

// Obtenir un planning par id
const getPlanningById = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting planning by ID:', error);
        throw error;
    }
};

/**
 * @param {PlanningCreationRequestDto} requestBody
 */
const updatePlanning = async (requestBody) => {
    try {
        const response = await axios.put(`${endpoint}`, requestBody);
        console.log("Update successful:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error while updating planning:', error.response || error.message);
        throw error;
    }
};

// Créer une disponibilitée
const createDisponibility = async (headers, requestBody) => {
    try {
        const response = await axios.post(disponibilityEndpoint, requestBody, { headers });
        return response.data;
    } catch (error) {
        console.error('Error while creating disponibility:', error);
        throw error;
    }
};

// Obtenir toutes les disponibilités par ID de planning
const getDisponibilitiesByPlanningId = async (planningId) => {
    try {
        const response = await axios.get(`${disponibilityEndpoint}/${planningId}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting disponibilities by planning ID:', error);
        throw error;
    }
};

// Mettre à jour une disponibilité
const updateUserDisponibility = async (headers, requestBody) => {
    try {
        const response = await axios.put(disponibilityEndpoint, requestBody, { headers });
        return response.data;
    } catch (error) {
        console.error('Error while updating disponibility:', error);
        throw error;
    }
};

export {
    getPlannings,
    createPlanning,
    getPlanningById,
    getPlanningByAdventure,
    updatePlanning,
    createDisponibility,
    getDisponibilitiesByPlanningId,
    updateUserDisponibility
};
