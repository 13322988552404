<template>
  <div class="menu-title" @click="handleClick">
    Races
    <i v-if="!isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i>
    <i v-if="isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-up theme--light"></i>
  </div>
  <ul id="sub-menu-list" v-show="this.isVisible">
    <li class="menu-title" v-for="race in this.races" :key="race" @click="select(race.index)">
      {{ race.name }}
    </li>
  </ul>
</template>

<script>
import {getRaces} from "@/services/dndofficial/endpoints/races";

export default {
  data() {
    return {
      races: [],
    };
  },
  props: {
    isVisible: Boolean(false),
  },
  emits: [
    'clicked'
  ],
  mounted: async function() {
    await this.getRaces()
  },
  methods: {
    async getRaces() {
      this.isLoading = true
      try {
        const response = await getRaces()
        this.races = response.data.races
      } catch (error) {
        this.races = []
      } finally {
        this.isLoading = false
      }
    },
    handleClick() {
      this.$emit('clicked', 'races');
    },
    select(raceIndex) {
      this.$router.push({ name: `library/race`, params: {raceIndex: raceIndex}});
    }
  }
}
</script>

<style scoped>


</style>