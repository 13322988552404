<template>
  <div class="popup-overlay">
    <div class="popup-content d-flex flex-column">
      <h2>Inviter un joueur</h2>

      <label for="filter">Pseudo</label>
      <input id="filter" v-model="searchTerm" type="text" placeholder="Rechercher..." />
      <ul v-if="filteredPlayers.length > 0">
        <li v-for="user in filteredPlayers" :key="user.id">
          <div class="card d-flex flex-row justify-content-between align-items-center p-2">
            {{ user.pseudo }}
            <button class="btn btn-success" @click="invitePlayer(user.id)"><font-awesome-icon icon="plus" /></button>
          </div>
        </li>
      </ul>
      <div v-else>Aucun utilisateur correspondant au pseudo</div>

      <button type="button" class="btn btn-danger m-2" @click="cancel">Annuler</button>

    </div>
  </div>
</template>

<script>

import {getAllUser} from "@/services/dndgate/endpoints/users";
import {sendInvitationCodeToUser} from "@/services/dndgate/endpoints/adventures";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      players: [],
      filteredPlayers: [],
      searchTerm: ''
    }
  },
  props: {
    adventure: null
  },
  mounted: async function() {
    await this.getPlayers();
  },
  methods: {
    async getPlayers() {
      this.isLoading = true
      try {
        this.players = await getAllUser();
        this.filteredPlayers = this.players.filter(user =>
            user.id.toString() !== this.adventure.creatorId.toString()
            && !this.adventure.players.some((p) => p.id.toString() === user.id.toString())
            && user.pseudo.toLowerCase().includes(this.searchTerm.toLowerCase()))
      } finally {
        this.isLoading = false
      }
    },
    async invitePlayer(userId) {
      await sendInvitationCodeToUser(this.adventure.id, userId)
    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

ul {
  margin-top: 10px;
  max-height: 30vh;
  overflow-y: auto;
  padding: 0;
}

</style>