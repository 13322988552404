import axios from 'axios';

const BASE_URL = "https://www.dnd5eapi.co";
const REST_ROOT = "/api";
const GRAPHQL_ROOT = "/graphql";

const rest_instance = axios.create({
    baseURL: BASE_URL + REST_ROOT,
});

const gql_instance = axios.create({
    baseURL: BASE_URL + GRAPHQL_ROOT,
});

export {rest_instance, gql_instance};

