import axios from "@/services/dndgate/axios";
import {formatDateForInput} from "@/utils/dateutils";

const endpoint = "/historical-notes"

/**
 * @param {HistoricalNoteCreationRequestDto} requestBody
 */
const createHistoricalNote = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating historic: ', error)
        throw error;
    }
}

const getHistoricalNotesByAdventureId = async (adventureId) => {
    try {
        const response = await axios.get(`${endpoint}/getByAdventureId/${adventureId}`);
        response.data.map((historicalNote) => {
            historicalNote.date = formatDateForInput(historicalNote.date)
            return historicalNote
        });
        return response.data.sort((a, b) => b.date.localeCompare(a.date))
    } catch (error) {
        console.error('Error while getting historic: ', error)
        throw error;
    }
}

const getHistoricalNoteDetails = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        response.data.date = formatDateForInput(response.data.date)
        return response.data;
    } catch (error) {
        console.error('Error while getting historic: ', error)
        throw error;
    }
}

/**
 * @param id
 * @param {HistoricalNoteCreationRequestDto} requestBody
 */
const updateHistoricalNote = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while patching historic: ', error)
        throw error;
    }
}

const deleteHistoricalNote = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting historic: ', error)
        throw error;
    }
}

export {
    createHistoricalNote,
    getHistoricalNotesByAdventureId,
    getHistoricalNoteDetails,
    updateHistoricalNote,
    deleteHistoricalNote
}