<template>
  <div class="card">
    <div class="card-body">
      <div class="col-11 ml-5">
        <form @submit.prevent="createAdventureNote">
          <div class="d-flex justify-content-between mb-4">
            <div class="center-list-label">
              <h3>Ajouter une note</h3>
            </div>
          </div>

          <label for="date">Date</label>
          <input id="date" class="form-control m-1" type="date" v-model="date" required/>

          <label for="visibility">Visibilité</label>
          <div>
            <select id="visibility" v-model="visibility">
              <option value="PRIVATE">Privé</option>
              <option value="PUBLIC">Public</option>
            </select>
          </div>

          <label for="content">Contenue</label>
          <textarea id="content" rows="15" class="form-control m-1" v-model="content" placeholder="Vous avez ..." required minlength="1"></textarea>

          <div class="d-flex justify-content-center m-4">
            <button type="submit" class="btn btn-success">Ajouter</button>
            <button type="button" class="btn btn-danger ms-2" @click="cancel">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
    import AdventureNoteCreationRequestDto from "@/dto/request/AdventureNoteCreationRequestDto";
    import {createAdventureNote} from "@/services/dndgate/endpoints/adventure-notes";
    
    export default {
      data() {
        return {
          date: null,
          visibility: '',
          content: '',
        };
      },
      watch: {
        adventureId() {
          this.getAdventureDetails()
        }
      },
      computed: {
        adventureId() {
          return this.$route.params.adventureId;
        }
      },
      methods: {
        async createAdventureNote() {
          this.isLoading = true
          const requestDto = new AdventureNoteCreationRequestDto(
              this.adventureId,
              this.content.trim(),
              this.date,
              this.visibility,
          );
          try {
            await createAdventureNote(requestDto)
            this.$router.push({name: 'tavern/adventure/adventureNoteList'})
          } finally {
            this.isLoading = false
          }
        },
        cancel() {
          this.$router.push({name: 'tavern/adventure/adventureNoteList'})
        },
      },
    };
</script>
  
<style scoped>

  .form-adventure label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }


</style>
