<template>
  <div id="menu-content">
    <ul id="menu-list">
      <li>
        <div class="menu-title" @click="select('adventures')">Tableau des quêtes</div>
      </li>
      <li>
        <div class="menu-title" @click="select('myAdventures')">Mes aventures en cours</div>
      </li>
      <li>
        <div class="menu-title" @click="select('myAdventuresFinished')">Mes aventures terminées</div>
      </li>
      <li>
        <div class="menu-title" @click="select('createAdventure')">Créer une aventure</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    select(index) {
      this.$router.push({ name: `tavern/${index}`});
    }
  },
};
</script>


<style scoped>

</style>