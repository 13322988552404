<template>
  <loading-spinner :isLoading="isLoading" />

  <div v-if="monster != null && !isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <h1>{{ monster.name }}</h1>
          <h3 class="mt-4">{{ capitalizeFirstLetter(monster.type) }} - Size {{ capitalizeFirstLetter(monster.size) }} - {{ monster.alignment }}</h3>
          <p><b>Armor class : </b>
            <span v-for="ac in monster.armor_class" :key="ac">
              {{ ac.value }} ({{ ac.type }}),
            </span>
          </p>
          <p><b>Hit points : </b>{{ monster.hit_points }} ({{ monster.hit_points_roll }})</p>
          <p><b>Speed : </b>
            <span v-if="monster.speed.walk">walk {{ monster.speed.walk }}, </span>
            <span v-if="monster.speed.swim">swim {{ monster.speed.swim }}, </span>
            <span v-if="monster.speed.fly">fly {{ monster.speed.fly }}, </span>
            <span v-if="monster.speed.burrow">burrow {{ monster.speed.burrow }}, </span>
            <span v-if="monster.speed.climb">climb {{ monster.speed.climb }}, </span>
            <span v-if="monster.speed.hover">hover {{ monster.speed.hover }}, </span>
          </p>

          <br>
          <table class="fl-table">
            <thead>
              <tr>
                <th>Str</th>
                <th>Dex</th>
                <th>Con</th>
                <th>Int</th>
                <th>Wis</th>
                <th>Cha</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ monster.strength }} (<span v-if="monster.strength > 10">+</span>{{ Math.floor((monster.strength - 10) / 2) }})</td>
                <td>{{ monster.dexterity }} (<span v-if="monster.dexterity > 10">+</span>{{ Math.floor((monster.dexterity - 10) / 2) }})</td>
                <td>{{ monster.constitution }} (<span v-if="monster.constitution > 10">+</span>{{ Math.floor((monster.constitution - 10) / 2) }})</td>
                <td>{{ monster.intelligence }} (<span v-if="monster.intelligence > 10">+</span>{{ Math.floor((monster.intelligence - 10) / 2) }})</td>
                <td>{{ monster.wisdom }} (<span v-if="monster.wisdom > 10">+</span>{{ Math.floor((monster.wisdom - 10) / 2) }})</td>
                <td>{{ monster.charisma }} (<span v-if="monster.charisma > 10">+</span>{{ Math.floor((monster.charisma - 10) / 2) }})</td>
              </tr>
            </tbody>
          </table>
          <br>

          <p><b>Saving throw : </b>
            <span v-for="p in monster.proficiencies.filter(pro => pro.proficiency.type === 'SAVING_THROWS')" :key="p">
              {{ capitalizeFirstLetter(p.proficiency.reference.name) }} +{{ p.value }},
            </span>
          </p>
          <p><b>Skills : </b>
            <span v-for="p in monster.proficiencies.filter(pro => pro.proficiency.type === 'SKILLS')" :key="p">
              {{ capitalizeFirstLetter(p.proficiency.reference.name) }} +{{ p.value }},
            </span>
          </p>
          <p v-if="monster.condition_immunities.length > 0"><b>Condition immunities : </b>
            <span v-for="ci in monster.condition_immunities" :key="ci">{{ ci.name }}, </span>
          </p>
          <p v-if="monster.damage_immunities.length > 0"><b>Damage immunities : </b>
            <span v-for="di in monster.damage_immunities" :key="di">{{ di }}, </span>
          </p>
          <p v-if="monster.damage_resistances.length > 0"><b>Damage resistances : </b>
            <span v-for="dr in monster.damage_resistances" :key="dr">{{ dr }}, </span>
          </p>
          <p v-if="monster.damage_vulnerabilities.length > 0"><b>Damage vulnerabilities : </b>
            <span v-for="dv in monster.damage_vulnerabilities" :key="dv">{{ dv }}, </span>
          </p>
          <p><b>Passive perception : </b> {{ monster.senses.passive_perception }}</p>
          <p><b>Senses : </b>
            <span v-if="monster.senses.blindsight">blind sight {{ monster.senses.blindsight }}, </span>
            <span v-if="monster.senses.darkvision">dark vision {{ monster.senses.darkvision }}, </span>
            <span v-if="monster.senses.truesight">true sight {{ monster.senses.truesight }}, </span>
            <span v-if="monster.senses.tremorsense">tremor sense {{ monster.senses.tremorsense }}, </span>
          </p>
          <p><b>Languages : </b> {{ monster.languages }}</p>
          <p><b>Dangerousness index : </b> {{ Number.isInteger(monster.challenge_rating) ? monster.challenge_rating : floatToFraction(monster.challenge_rating) }} ({{ monster.xp }}XP)</p>

          <div v-if="monster.special_abilities != null">
            <h2 class="mt-4">Abilities</h2>
            <hr>
            <p v-for="ability in monster.special_abilities" :key="ability">
              <i><b>{{ ability.name }}.</b></i> {{ ability.desc }}
            </p>
          </div>

          <div v-if="monster.actions != null">
            <h2 class="mt-4">Actions</h2>
            <hr>
            <p v-for="action in monster.actions" :key="action">
              <i><b>{{ action.name }}.</b></i> {{ action.desc }}
            </p>
          </div>

          <div v-if="monster.legendary_actions != null">
            <h2 class="mt-4">Legendary actions</h2>
            <hr>
            <p v-for="la in monster.legendary_actions" :key="la">
              <i><b>{{ la.name }}.</b></i> {{ la.desc }}
            </p>
          </div>

          <div v-if="monster.reactions != null">
            <h2 class="mt-4">Reactions</h2>
            <hr>
            <p v-for="r in monster.reactions" :key="r">
              <i><b>{{ r.name }}.</b></i> {{ r.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import {getMonsterDescription} from "@/services/dndofficial/endpoints/monsters";
  import {capitalizeFirstLetter} from "@/utils/stringutils";
  import {floatToFraction} from "@/utils/floatutils";
  import LoadingSpinner from "@/views/shared/LoadingSpinner";

  export default {
    components: { LoadingSpinner },
    data() {
      return {
        isLoading: false,
        monster: null,
      };
    },
    watch: {
      monsterIndex() {
        this.getMonsterDescription()
      }
    },
    computed: {
      monsterIndex() {
        return this.$route.params.monsterIndex;
      }
    },
    mounted: async function(){
      await this.getMonsterDescription()
    },
    methods: {
      async getMonsterDescription() {
        this.isLoading = true
        try {
          const response = await getMonsterDescription(this.monsterIndex)
          this.monster = response.data.monster
        } catch (error) {
          this.monster = null
        } finally {
          this.isLoading = false
        }
      },
      capitalizeFirstLetter(string) {
        return capitalizeFirstLetter(string)
      },
      floatToFraction(x) {
        return floatToFraction(x)
      }
    }
  };
</script>
  
<style scoped>

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .fl-table {
    border-collapse: collapse;
  }

  .fl-table thead tr:last-child {
    border-bottom: 1px solid black;
  }

  .fl-table td, .fl-table th {
    padding: 0 8px;
  }


</style>