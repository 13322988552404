<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <h3 class="text-center mb-4 mt-0">Create spell book</h3>

        <form @submit.prevent="createSpellBook">

          <label>Name</label>
          <input class="form-control m-1" type="text" v-model="name" placeholder="Name" required/>

          <label>Description</label>
          <textarea rows="10" class="form-control m-1" v-model="description" placeholder="Description" required></textarea>

          <div class="d-flex justify-content-center m-4">
            <button type="submit" class="btn btn-outline-success">Create</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>

    import {createSpellBook} from "@/services/dndgate/endpoints/spellbooks";
    import SpellBookCreationRequestDto from "@/dto/request/SpellBookCreationRequestDto";

    export default {
      data() {
        return {
          name: null,
          description: null,
          isLoading: false
        };
      },
      emits: ['spellBookCreatedEvent'],
      methods: {
        async createSpellBook() {
          this.isLoading = true
          const spellBookData = new SpellBookCreationRequestDto(
            this.name,
            this.description
          )
          try {
            await createSpellBook(spellBookData)
            this.$store.dispatch('showTemporaryPopup', {
              message: 'Spell book created',
            });
            this.$router.push({ name: `library/spellBookList`});
          } catch (error) {
            this.$store.dispatch('showPopup', {
              title: '/!\\ Erreur /!\\',
              message: error.response.data,
              isSuccess: false
            });
          }
          finally {
            this.isLoading = false
          }
        },
      }
    };
  </script>
  
  <style>

  button {
    margin: 10px;
    padding: 5px 10px;
  }

  </style>