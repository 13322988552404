import axios from "@/services/dndgate/axios";

const endpoint = "/ratings"

/**
 * @param {RatingCreationRequestDto} requestBody
 */
const putRating = async (requestBody) => {
    try {
        const response = await axios.put(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating rating: ', error)
        throw error;
    }
}

const getMeanRateOfUser = async (userId) => {
    try {
        const response = await axios.get(
            `${endpoint}/getMeanRateOfUser`,
            {
                params: {
                    userId: userId
                }
            });
        return response.data;
    } catch (error) {
        console.error('Error while getting rating: ', error)
        throw error;
    }
}


export { putRating, getMeanRateOfUser }