<template>
  <div class="menu-title" @click="handleClick">
    Rules
    <i v-if="!isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i>
    <i v-if="isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-up theme--light"></i>
  </div>
  <ul id="sub-menu-list" v-show="this.isVisible">
    <li class="menu-title" v-for="rule in this.rules" :key="rule" @click="select(rule.index)">
      {{ rule.name }}
    </li>
  </ul>
</template>

<script>
import {getRules} from "@/services/dndofficial/endpoints/rules";

export default {
  name: "RulesCategory",
  data() {
    return {
      rules: []
    };
  },
  props: {
    isVisible: Boolean(false)
  },
  emits: [
    'clicked'
  ],
  mounted: async function() {
    await this.getRules()
  },
  methods: {
    async getRules() {
      this.isLoading = true
      try {
        const response = await getRules()
        this.rules = response.data.rules
      } catch (error) {
        this.rules = []
      } finally {
        this.isLoading = false
      }
    },
    handleClick() {
      this.$emit('clicked', 'rules');
    },
    select(ruleIndex) {
      this.$router.push({ name: `library/rule`, params: {ruleIndex: ruleIndex}});
    }
  }
}
</script>

<style scoped>


</style>