<template>
  <loading-spinner :isLoading="isLoading"/>

  <div v-if="this.data != null &&!isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <div class="d-flex justify-content-between mb-2">
            <button class="btn btn-outline-primary" @click="exportSpellBook"><font-awesome-icon icon="file-export" /></button>
            <div>
              <button class="btn btn-primary me-2" @click="updateSpellBook"><font-awesome-icon icon="edit" /></button>
              <button class="btn btn-danger" @click="deleteSpellBook"><font-awesome-icon icon="trash" /></button>
            </div>
          </div>
          <h3> {{ this.data.name  }} </h3>
          <p>{{ this.data.description }}</p>
          <hr>

          <label for="spell-per-page-option">Spell per page : </label>
          <select id="spell-per-page-option" v-model="spellPerPage">
            <option v-for="option in spellPerPageOption" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
                <tr>
                  <th></th>
                  <th class="left-align">Name</th>
                  <th class="center-align">Level</th>
                  <th class="left-align">School</th>
                  <th class="left-align">Casting time</th>
                  <th class="left-align">Duration</th>
                  <th class="center-align">Concentration</th>
                  <th class="center-align">Ritual</th>
                  <th class="center-align">Components</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="spell in paginatedSpells" :key="spell">
                  <td><i @click="removeSpellToBook(spell.index)" aria-hidden="true" class="bookmark v-icon notranslate theme--light">&times;</i></td>
                  <td><a class="name" @click="this.select(spell.index)"><b>{{ spell.name }}</b></a></td>
                  <td>{{ spell.level }}</td>
                  <td>{{ spell.school.name }}</td>
                  <td>{{ spell.casting_time }}</td>
                  <td>{{ spell.duration }}</td>
                  <td><a v-if="spell.concentration">concentration</a></td>
                  <td><a v-if="spell.ritual">ritual</a></td>
                  <td>{{ spell.components.join(', ') }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="page-selection">
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
            </button>
            <button class="page-button" v-if="currentPage > 2" @click="changePage(1)">1</button>
            <span v-if="currentPage > 3">...</span>
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">{{ currentPage - 1 }}</button>
            <button id="selected-page-button" class="page-button"> {{ currentPage }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">{{ currentPage + 1 }}</button>
            <span v-if="currentPage < totalOfPages-2">...</span>
            <button class="page-button" v-if="currentPage < totalOfPages-1" @click="changePage(totalOfPages)">{{ totalOfPages }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <UpdateSpellBookPopup v-if="updatingSpellBook" :spell-book="data" @close="closeUpdateSpellBookPopup"></UpdateSpellBookPopup>

</template>

<script>
import {
  deleteSpellBook,
  exportSpellBook,
  getSpellBookDetails,
  removeSpellToBook
} from '@/services/dndgate/endpoints/spellbooks'
import {getSpells} from "@/services/dndofficial/endpoints/spells";
import UpdateSpellBookPopup from "@/views/library/content/personal_library/spellbook/UpdateSpellBookPopup";
import LoadingSpinner from "@/views/shared/LoadingSpinner";

export default {
  components: { LoadingSpinner, UpdateSpellBookPopup },
  data() {
    return {
      data: null,
      isLoading: false,
      spellPerPage: 10,
      spellPerPageOption: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '15', value: 15},
        {text: 'All', value: 0},
      ],
      currentPage: 1,
      currentPageOption: this.setupPagination(),
      allSpells: [],
      filteredSpells: [],
      updatingSpellBook: false,
    };
  },
  watch: {
    spellBookId() {
      this.getSpellBookDetails()
      this.getSpells()
    },
    spellPerPage(newValue) {
      if (newValue === 0)
        this.spellPerPage = this.allSpells.length
      this.currentPageOption = this.setupPagination()
      this.currentPage = 1
    },
  },
  computed: {
    spellBookId() {
      return this.$route.params.spellBookId;
    },
    paginatedSpells() {
      const start = (this.currentPage - 1) * this.spellPerPage;
      const end = start + this.spellPerPage;
      return this.allSpells.slice(start, end);
    },
    totalOfPages() {
      return Math.ceil(this.allSpells.length / this.spellPerPage);
    }
  },
  mounted: async function() {
    await this.getSpellBookDetails()
    await this.getSpells()
  },
  methods: {
    setupPagination() {
      let options = [];
      for (let i = 1; i <= this.totalOfPages; i++) {
        options.push({ text: `Page ${i}`, value: i });
      }
      return options;
    },
    changePage(page) {
      this.currentPage = page;
    },
    select(spellIndex) {
      this.$router.push({ name: `library/spell`, params: {spellIndex: spellIndex}});
    },
    async getSpellBookDetails() {
      this.isLoading = true
      try {
        this.data = await getSpellBookDetails(this.spellBookId)
      } finally {
        this.isLoading = false
      }
    },
    async getSpells() {
      this.isLoading = true
      try {
        const response = await getSpells(0,0)
        this.allSpells = response.data.spells.filter(
            obj => this.data.spellList.includes(obj.index)
        );
        this.filteredSpells = this.allSpells
      } catch (error) {
        this.allSpells = []
        this.filteredSpells = []
      }
      finally {
        this.isLoading = false
      }
    },
    async deleteSpellBook() {
      this.isLoading = true
      try {
        await deleteSpellBook(this.spellBookId)
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Spell book deleted',
        });
        this.$router.push({ name: `library/spellBookList`});
      } finally {
        this.isLoading = false
      }
    },
    async removeSpellToBook(spellIndex) {
      this.isLoading = true
      try {
        await removeSpellToBook(this.spellBookId, spellIndex)
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Spell deleted',
        });
        await this.getSpellBookDetails()
        await this.getSpells()
      } finally {
        this.isLoading = false
      }
    },
    async exportSpellBook() {
      this.isLoading = true
      try {
        const response = await exportSpellBook(this.data.id);
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `spellBook-${this.data.name.replace(/\s/g, '_')}.json`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Spell book exported',
        });
      } finally {
        this.isLoading = false
      }
    },
    updateSpellBook() {
      this.updatingSpellBook = true
    },
    async closeUpdateSpellBookPopup() {
      this.updatingSpellBook = false
      await this.getSpellBookDetails()
      await this.getSpells()
    },
  },
};
</script>

<style>
button {
  margin: 10px;
  padding: 5px 10px;
}

#page-selection {
  text-align: center;
}

#selected-page-button {
  background-color: var(--greyblue-color);
  color: white;
}

label {
  display: block;
  margin-bottom: 5px;
}

select {
  width: 30%;
  padding: 8px;
  margin-bottom: 20px;
}

/* Table Styles */
.table-wrapper{
  margin: 10px 0;
  box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
}

.fl-table {
  border-radius: 5px;
  font-size: 18px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td, .fl-table th {
  padding: 15px;
}

.fl-table td b {
  border-right: 1px solid #f8f8f8;
  color: #4C6477;
}

.fl-table thead th {
  color: #ffffff;
  background: #6F6F6F;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: start;
}

.name {
  cursor: pointer;
}

.page-button {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
  margin: 4px;
}

.page-button:hover {
  cursor: pointer;
}
</style>