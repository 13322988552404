<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="d-flex justify-content-between mb-4">
          <h3 v-if="this.adventure.gameCharacters.length === 0">Aucun personnage</h3>
          <h3 v-else>Personnages de l'aventure</h3>
          <button class="btn btn-outline-primary" @click="getAdventureDetails"><font-awesome-icon icon="sync" /></button>
          <button v-if="canCreateCharacter()" class="btn btn-outline-success" @click="createGameCharacter"><font-awesome-icon icon="plus" /> <font-awesome-icon icon="person" /></button>
        </div>
        <div class="overflow-auto mt-4">
          <ul>
            <li v-for="character in this.adventure.gameCharacters" :key="character">
              <character-item
                  :im-master="imMaster"
                  :character=character
                  @deleted="handleGameCharacterDeleted"></character-item>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserById} from "@/services/dndgate/endpoints/users";
import {getAdventureDetails} from "@/services/dndgate/endpoints/adventures";
import {getGameCharactersByAdventureId} from "@/services/dndgate/endpoints/game-characters";
import CharacterItem from "@/views/tavern/content/adventure/character/GameCharacterItem";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { CharacterItem, FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
      adventure: null,
      imMaster: false,
    };
  },
  watch: {
    adventureId() {
      this.getAdventureDetails()
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    }
  },
  mounted: async function() {
    await this.getAdventureDetails();
    this.imMaster = (this.adventure.gameMaster.id.toString() === localStorage.getItem('id').toString())
  },
  methods: {
    async getAdventureDetails() {
      this.isLoading = true
      try {
        this.adventure = await getAdventureDetails(this.adventureId)

        const gameMaster = await getUserById(this.adventure.creatorId);
        this.adventure = { ...this.adventure, gameMaster: gameMaster }
        this.isParticipant = this.adventure.players.includes(localStorage.getItem('id'))
        this.adventure.players = await Promise.all(this.adventure.players.map(async (playerId) => {
          return await getUserById(playerId)
        }))

        const characterList = await getGameCharactersByAdventureId(this.adventureId)

        this.adventure.gameCharacters = await Promise.all(characterList.map(async (character) => {
          const user = await getUserById(character.ownerId);
          return { ...character, ownerPseudo: user.pseudo }
        }))
      } finally {
        this.isLoading = false
      }
    },
    canCreateCharacter() {
      return !this.imMaster
          && this.adventure.status.toString() !== 'FINISH'.toString()
          && !this.adventure.gameCharacters.some(character => character.ownerId === localStorage.getItem('id'))
    },
    createGameCharacter() {
      this.$router.push({name: 'tavern/adventure/createGameCharacter'})
    },
    handleGameCharacterDeleted() {
      this.getAdventureDetails()
    }
  },
}
</script>

<style scoped>

</style>