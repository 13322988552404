<template>
  <loading-spinner :isLoading="isLoading"/>

  <div v-if="races != null && !isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <h1>Calculateur de caractéristiques</h1>
          <hr>

          <div class="flex-container">
            <div>
              <label for="race-option">Race : </label>
              <select id="race-option" v-model="selectedRace">
                <option v-for="race in races" :key="race" :value="race">
                  {{ race.name }}
                </option>
              </select>
            </div>

            <div v-if="selectedRace && selectedRace.subraces.length > 0">
              <label for="subrace-option">Variantes : </label>
              <select id="subrace-option" v-model="selectedSubRace">
                <option v-for="subRace in selectedRace.subraces" :key="subRace" :value="subRace">
                  {{ subRace.name }}
                </option>
              </select>
            </div>

            <div v-if="selectedRace && selectedRace.ability_bonus_options">
              <label for="bonus-option">Bonus aux caractéristiques : </label>
              <ul id="bonus-option">
                <li v-for="option in selectedRace.ability_bonus_options.from.options" :key="option">
                  <label>
                    <input
                        type="checkbox"
                        :value="option"
                        v-model="selectedAbilityBonuses"
                        :disabled="selectedAbilityBonuses.length >= selectedRace.ability_bonus_options.choose && !selectedAbilityBonuses.includes(option)"
                    >
                    {{ option.ability_score.name }}
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <label for="method-option">Méthode d'attribution: </label>
            <select id="method-option" v-model="selectedMethod">
              <option v-for="option in methodOption" :key="option.value" :value="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
              <tr>
                <th class="left-align">Caractéristique</th>
                <th class="center-align">Base</th>
                <th class="center-align"></th>
                <th class="center-align">Bonus racial</th>
                <th class="center-align"></th>
                <th class="center-align">Total</th>
                <th class="center-align">Modif.</th>
                <th class="center-align">Coût</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="ability in abilityScores" :key="ability">
                <td class="left-align">{{ ability.full_name }}</td>
                <td class="center-align">
                  <button class="score-button" :disabled="!canDecrement(ability.score)" @click="decrementScore(ability)">-</button>
                  {{ ability.score }}
                  <button class="score-button" :disabled="!canIncrement(ability.score)" @click="incrementScore(ability)">+</button>
                </td>
                <td class="center-align">+</td>
                <td class="center-align">{{ ability.raceBonus }}</td>
                <td class="center-align">=</td>
                <td class="center-align"><b>{{ ability.score + ability.raceBonus }}</b></td>
                <td class="center-align">
                  <b>
                    <span v-if="Math.floor((ability.score + ability.raceBonus - 10)/2) > 0">+</span>{{ Math.floor((ability.score + ability.raceBonus - 10)/2) }}
                  </b>
                </td>
                <td class="center-align">{{ ability.cost }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Points restants : {{currentPoints}}/{{defaultPoints}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import {getRacesWithAbilities} from "@/services/dndofficial/endpoints/races";
  import {getAbilityScores} from "@/services/dndofficial/endpoints/abilityScores";
  import LoadingSpinner from "@/views/shared/LoadingSpinner";

  export default {
    components: { LoadingSpinner },
    data() {
      return {
        baseScore: 8,
        maxScore: 15,
        defaultPoints: 27,
        currentPoints: 27,
        isLoading: false,
        abilities: null,
        abilityScores: [],
        races: null,
        selectedRace: null,
        selectedSubRace: null,

        selectedMethod: 0,
        methodOption: [
          {text: 'Méthode par répartition', value: 0},
          {text: 'Méthode fixe', value: 1},
        ],

        selectedAbilityBonuses: [],
      };
    },
    watch: {
      selectedRace(newRace) {
        this.computeRaceBonus(newRace)
      },
      selectedSubRace(newSubRace) {
        this.computeSubRaceBonus(newSubRace)
      },
      selectedAbilityBonuses(newAbilityBonuses) {
        this.computeAbilityBonuses(newAbilityBonuses)
      }
    },
    mounted: async function(){
      await this.getAbilityScores()
      await this.getRacesWithAbilities()
    },
    methods: {
      setupAbilityScores() {
        let scores = []

        for (let ability of this.abilities) {
          let score = {
            index: ability.index,
            name: ability.name,
            full_name: ability.full_name,
            score: this.baseScore,
            raceBonus: 0,
            cost: 0,
          }
          scores.push(score)
        }

        this.abilityScores = scores
      },
      computeRaceBonus(newRace) {
        this.abilityScores.forEach((abilityScore) => abilityScore.raceBonus = 0)
        for (let ability_bonus of newRace.ability_bonuses) {
          let ability_score = this.abilityScores.find((ability_score) => ability_score.index === ability_bonus.ability_score.index)
          ability_score.raceBonus = ability_bonus.bonus
        }
      },
      computeSubRaceBonus(newSubRace) {
        this.computeRaceBonus(this.selectedRace)
        for (let ability_bonus of newSubRace.ability_bonuses) {
          let ability_score = this.abilityScores.find((ability_score) => ability_score.index === ability_bonus.ability_score.index)
          ability_score.raceBonus += ability_bonus.bonus
        }
      },
      computeAbilityBonuses(newAbilityBonuses) {
        if (this.selectedSubRace) {
          this.computeSubRaceBonus(this.selectedSubRace)
        }
        else {
          this.computeRaceBonus(this.selectedRace)
        }
        for (let ability_bonus of newAbilityBonuses) {
          let ability_score = this.abilityScores.find((ability_score) => ability_score.index === ability_bonus.ability_score.index)
          ability_score.raceBonus = ability_bonus.bonus
        }
      },
      canDecrement(abilityScore) {
        return abilityScore > this.baseScore
      },
      decrementScore(ability) {
        let restored = ability.score > 13 ? 2 : 1
        this.currentPoints += restored
        ability.cost -= restored
        ability.score -= 1
      },
      canIncrement(abilityScore) {
        let cost = abilityScore < 13 ? 1 : 2
        return abilityScore < this.maxScore && cost <= this.currentPoints
      },
      incrementScore(ability) {
        let cost = ability.score < 13 ? 1 : 2
        this.currentPoints -= cost
        ability.cost += cost
        ability.score += 1
      },
      async getAbilityScores() {
        this.isLoading = true
        try {
          const response = await getAbilityScores()
          this.abilities = response.data.abilityScores
          this.setupAbilityScores()
        } catch (error) {
          this.abilities = null
        } finally {
          this.isLoading = false
        }
      },
      async getRacesWithAbilities() {
        this.isLoading = true
        try {
          const response = await getRacesWithAbilities()
          this.races = response.data.races
        } catch (error) {
          this.races = null
        } finally {
          this.isLoading = false
        }
      },
    }
  };
</script>
  
<style scoped>

label {
  display: block;
  margin-bottom: 5px;
}

select {
  width: 30%;
  padding: 8px;
  margin-bottom: 20px;
}

h1 {
  color: #4C6477;
}

h2 {
  color: #4C6477;
  margin-bottom: 0;
}

h3 {
  color: #435A3F;
  margin-bottom: 0;
}

hr {
  margin-top: 2px;
}

p {
  margin-top: 3px;
  margin-bottom: 3px;
}

/* Table Styles */
.table-wrapper{
  margin: 10px 0;
  box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
}

.fl-table {
  border-radius: 5px;
  font-size: 18px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td, .fl-table th {
  padding: 15px;
}

.fl-table td b {
  border-right: 1px solid #f8f8f8;
  color: #4C6477;
}

.fl-table thead th {
  color: #ffffff;
  background: #6F6F6F;
}

.center-align {
  text-align: center;
}

#bonus-option {
  list-style: none;
}

.flex-container {
  display: flex;
}

select {
  width: 200px;
}

</style>