<template>
    <div v-if="this.adventure != null && !isLoading" class="adventure-content">
      <div class="header">
        <h1 class="adventure-name">{{ adventure.name }}</h1>
        <button v-if="imMaster" class="btn btn-primary" @click="editAdventure"><font-awesome-icon icon="edit" /></button>
        <button v-if="isParticipant" class="btn btn-danger" @click="quitAdventure"><font-awesome-icon icon="sign-out-alt" /></button>
        <button v-else-if="imMaster && adventure.status.toString() !== 'FINISH'.toString()" @click="closeAdventure" class="btn btn-danger">Clore</button>
        <button v-else-if="imMaster && adventure.status.toString() === 'FINISH'.toString()" @click="deleteAdventure" class="btn btn-danger"><font-awesome-icon icon="trash" /></button>
      </div>
      <div v-if="showCalendar" class="calendar-popup">
        <vue-cal :events="selectedDates" @cell-click="selectDate" :max-date="10" />
        <calendar-content @close="closeCalendar" @datesSelected="handleDatesSelected" :adventureId="adventure.id"></calendar-content>
      </div>
      <div v-if="showTab" class="calendar-popup">
        <ViewCalendarContent v-if="showTab" :adventureId="adventure.id" @close="closeTab"/>
      </div>
      <div class="content">
        <div class="description">
          <h3 class="adventure-description-title">Description</h3>
          <p class="adventure-description">{{ adventure.description }}</p>
        </div>
        <div class="participants">
          <h3 class="adventure-participants-title">Maitre du jeu</h3>
          <hr>
          <ul class="adventure-participants">
            <li class="d-flex justify-content-between me-2">
              {{ adventure.gameMaster.pseudo }}
              <StarRating :rating="adventure.gameMaster.meanRate" :read-only="isMe(adventure.gameMaster.id)" @setRating="rate => setRating(adventure.gameMaster.id, rate)"></StarRating>
            </li>
          </ul>
          <div class="d-flex justify-content-between">
            <h3 class="adventure-participants-title">Joueurs</h3>
            <button v-if="imMaster" @click="inviteUser" class="btn btn-outline-success me-2">
              <font-awesome-icon icon="plus" />
              <font-awesome-icon icon="user" />
            </button>
          </div>
          <hr>
          <ul class="adventure-participants">
            <li v-for="player in adventure.players" :key="player.id" class="d-flex justify-content-between me-2">
              {{ player.pseudo }}
              <StarRating
                  :rating="player.meanRate"
                  :read-only="isMe(player.id)"
                  @setRating="rate => setRating(player.id, rate)"></StarRating>
            </li>
          </ul>
        </div>
      </div>

      <button v-if="false" @click="startCall" class="btn btn-outline-primary">Démarrer la visio</button>

      <!-- Horizontal Menu -->
      <div class="menu">

        <img v-if="isParticipant" src="@/assets/tavern/adventures/calendar.png" class="calendar-icon" :class="{ active: currentRouteName === 'tavern/adventure/viewCalendarContent' }" @click="handleNavigateToViewCalendar" />
        <img v-else src="@/assets/tavern/adventures/calendar.png" class="calendar-icon" :class="{ active: currentRouteName === 'tavern/adventure/calendarContent' }" @click="handleNavigateToCalendarContent" />
        <button
            @click="handleNavigateToGameCharacterList"
            :class="{ active: currentRouteName === 'tavern/adventure/gameCharacterList' }"
            class="tab-btn">PJ</button>
        <button
            @click="handleNavigateToHistoricList"
            :class="{ active: currentRouteName === 'tavern/adventure/historicalNoteList' }"
            class="tab-btn">Historique de l'aventure</button>
        <button
            @click="handleNavigateToNoteList"
            :class="{ active: currentRouteName === 'tavern/adventure/adventureNoteList' }"
            class="tab-btn">Notes du MJ</button>
        <button
            @click="handleNavigateToShowCalendarVote"
            v-if="imMaster"
            :class="{ active: currentRouteName === 'tavern/adventure/showCalendarVote' }"
            class="tab-btn">Voir les votes du calendrier</button>
      </div>

      <div class="tab-content">
        <router-view></router-view>
      </div>

    </div>
    <MessengerContent v-if="adventure !== null" :adventure-id="adventure.id"/>

    <InvitePlayerPopup v-if="invitingPlayer" @close="closeInvitePlayerPopup" :adventure="this.adventure"></InvitePlayerPopup>

</template>

<script>
import {
  deleteAdventure,
  getAdventureDetails,
  quitAdventure,
  setAdventureStatus
} from "@/services/dndgate/endpoints/adventures";
import {getUserById} from "@/services/dndgate/endpoints/users";
import {getMeanRateOfUser, putRating} from "@/services/dndgate/endpoints/ratings";
import MessengerContent from './messenger/MessengerContent.vue';
import StarRating from "@/views/shared/StarRating";
import CalendarContent from './CalendarContent.vue';
import ViewCalendarContent from './ViewCalendarContent.vue';
import InvitePlayerPopup from "@/views/tavern/content/adventure/InvitePlayerPopup";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    components: { MessengerContent, StarRating, CalendarContent, ViewCalendarContent, InvitePlayerPopup, FontAwesomeIcon},
    data() {
      return {
        isLoading: false,
        adventure: null,
        isParticipant: false,
        selectedTab: '',
        imMaster: false,
        showCalendar: false,
        showTab: false,
        selectedDates: [],
        newStatus: '',
        invitingPlayer: false
      };
    },
    watch: {
      adventureId() {
        this.getAdventureDetails()
      }
    },
    computed: {
      adventureId() {
        return this.$route.params.adventureId;
      },
      currentRouteName() {
        return this.$route.name;
      }
    },
    mounted: async function() {
      await this.getAdventureDetails();
      this.imMaster = (this.adventure.gameMaster.id.toString() === localStorage.getItem('id').toString())
    },
    methods: {
      toggleCalendar() {
        this.showCalendar = !this.showCalendar;
      },
      toggleTab() {
        this.showTab = !this.showTab;
      },
      closeCalendar() {
        this.showCalendar = false;
      },
      closeTab() {
        this.showTab = false;
      },
      inviteUser() {
        this.invitingPlayer = true
      },
      async closeInvitePlayerPopup() {
        this.invitingPlayer = false
        await this.getAdventureDetails()
      },
      isMe(id) {
        return id === localStorage.getItem('id')
      },
      startCall() {
        const url = `${window.location.origin}/videoCall/${this.adventureId}`;
        window.open(url, '_blank');
      },
      selectDate(date) {
        const index = this.selectedDates.findIndex(d => d.date === date.date);
        if (index === -1 && this.selectedDates.length < 10) {
          this.selectedDates.push({ date: date.date, class: 'selected-date' });
        } else if (index !== -1) {
          this.selectedDates.splice(index, 1);
        }
      },
      handleDatesSelected(dates) {
        this.selectedDates = dates;
      },
      async getAdventureDetails() {
        this.isLoading = true
        try {
          this.adventure = await getAdventureDetails(this.adventureId)
          const gameMaster = await getUserById(this.adventure.creatorId);
          const meanRateOfGameMaster = await getMeanRateOfUser(this.adventure.creatorId);
          this.adventure = { ...this.adventure, gameMaster: {...gameMaster, meanRate: meanRateOfGameMaster} }
          this.isParticipant = this.adventure.players.includes(localStorage.getItem('id'))
          this.adventure.players = await Promise.all(this.adventure.players.map(async (playerId) => {
            const user = await getUserById(playerId);
            const meanRate = await getMeanRateOfUser(playerId);
            return { ...user, meanRate: meanRate }
          }))
        } finally {
          this.isLoading = false
        }
      },
      async quitAdventure() {
        this.isLoading = true
        try {
          await quitAdventure(this.adventure.id)
          this.$router.push({name: `tavern/myAdventures`});
        } catch (error) {
          alert('Une erreur est survenue lors de la tentative de quitter le adventuree.');
        } finally {
          this.isLoading = false
        }
      },
      async closeAdventure() {
        this.isLoading = true
        try {
          const requestBody = {
            status: 'FINISH'
          }
          await setAdventureStatus(this.adventure.id, requestBody)
        } catch (error) {
          alert('Une erreur est survenue lors de la tentative de cloture du adventuree.');
        } finally {
          this.isLoading = false
        }
      },
      async deleteAdventure() {
        this.isLoading = true
        try {
          await deleteAdventure(this.adventure.id)
          this.$router.push({name: `tavern/myAdventures`});
        } catch (error) {
          alert('Une erreur est survenue lors de la tentative de suppression du adventuree.');
        } finally {
          this.isLoading = false
        }
      },
      async setRating(userId, rate) {
        this.isLoading = true
        try {
          const rating = {
            userTargetedId: userId,
            rate: rate
          }
          await putRating(rating)
          await this.getAdventureDetails()
        } finally {
          this.isLoading = false
        }
      },
      handleNavigateToGameCharacterList() {
        this.$router.push({name: 'tavern/adventure/gameCharacterList'})
      },
      handleNavigateToHistoricList() {
        this.$router.push({name: 'tavern/adventure/historicalNoteList'})
      },
      handleNavigateToNoteList() {
        this.$router.push({name: 'tavern/adventure/adventureNoteList'})
      },
      handleNavigateToShowCalendarVote() {
        this.$router.push({name: 'tavern/adventure/showCalendarVote'})
      },
      handleNavigateToCalendarContent() {
        this.$router.push({ name: 'tavern/adventure/calendarContent', params: { adventureId: this.adventureId }})
      },
      handleNavigateToViewCalendar() {
        this.$router.push({name: 'tavern/adventure/viewCalendarContent'})
      },
      editAdventure() {
        this.$router.push({ name: 'tavern/updateAdventureContent', params: { adventureId: this.adventureId }});
      },
    },
  };
</script>

<style scoped>
  .adventure-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    border: 1px solid #ccc;
    height: 100%;
    width: auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  .adventure-name {
    font-size: 24px;
    font-weight: bold;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .description {
    width: 70%;
    padding-right: 10px;
  }

  .participants {
    width: 30%;
    padding-left: 10px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .adventure-description-title,
  .adventure-participants-title {
    color: #435A3F;
    font-size: 20px;
    margin: 10px 0;
  }

  .adventure-description {
    font-size: 16px;
    text-align: justify;
    margin-bottom: 20px;
  }

  .adventure-participants {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
  }

  .adventure-participants li {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* Styles for the menu and tabs */
  .menu {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .tab-btn {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: #ccc;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .tab-btn.active {
    background-color: #007bff;
    color: white;
  }

  .tab-content {
    width: 100%;
  }

  hr {
    margin-top: 2px;
  }

  .calendar-icon {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 2px;
  }

  .calendar-popup {
    position: absolute;
    top: 50px;
    right: 20px;
    padding: 10px;
    z-index: 100;
  }
</style>
  