<template>
  <div v-if="!readOnly" class="star-rating">
    <span v-for="star in 5" :key="star"
          class="star"
          @click="setRating(star)"
          @mouseover="setHover(star)"
          @mouseleave="setHover(0)"
          :class="{'active': star <= rating && hover === 0, 'hovered': star <= hover}">
      ★
    </span>
  </div>

  <div v-if="readOnly" class="star-rating-read-only">
    <span v-for="star in 5" :key="star" class="star" :class="{'active': star <= rating}">
      ★
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: 0,
    }
  },
  props: {
    rating: Number,
    readOnly: Boolean
  },
  emits: ['setRating'],
  methods: {
    setRating(rate) {
      this.$emit('setRating', rate)
    },
    setHover(star) {
      this.hover = star;
    }
  }
};
</script>

<style scoped>

.star {
  font-size: 20px;
  cursor: pointer;
  color: #d3d3d3;
}

.star.active {
  color: #ffc107;
}

.star.hovered {
  color: #ffc107; /* Couleur pour les étoiles survolées */
}

</style>