import axios from "@/services/dndgate/axios";

const endpoint = "/game-characters"

/**
 * @param {GameCharacterCreationRequestDto} requestBody
 * @param {File} picturePresentation
 */
const createGameCharacter = async (requestBody, picturePresentation) => {
    try {
        let data = new FormData();

        data.append('picturePresentation', picturePresentation, `${requestBody.name}.png`);
        data.append('createGameCharacterRequest', new Blob(
            [JSON.stringify(requestBody)],
            {
                type: "application/json"
            }));

        const response = await axios.post(`${endpoint}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error while creating game character: ', error)
        throw error;
    }
}

const getGameCharactersByAdventureId = async (adventureId) => {
    try {
        const response = await axios.get(`${endpoint}/getByAdventureId/${adventureId}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting game character: ', error)
        throw error;
    }
}

const getGameCharacterDetails = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting game character: ', error)
        throw error;
    }
}

/**
 * @param id
 * @param {GameCharacterCreationRequestDto} requestBody
 */
const updateGameCharacter = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while patching game character: ', error)
        throw error;
    }
}

const deleteGameCharacter = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting game character: ', error)
        throw error;
    }
}

export {
    createGameCharacter,
    getGameCharacterDetails,
    getGameCharactersByAdventureId,
    updateGameCharacter,
    deleteGameCharacter
}