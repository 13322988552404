<template>
    <div id="adventures-content">
      <use-code-adventure-content></use-code-adventure-content>

      <div v-if="!showInfoVisible" class="card-list">
        <CardContent v-for="adventure in adventures" :key="adventure.id" :adventure="adventure" @view-details="viewDetails" class="card"></CardContent>
      </div>
      <Info 
        v-if="showInfoVisible" 
        :visible="showInfoVisible" 
        :adventure="selectedAdventure"
        @close="closeInfo" 
        @adventure-joined="fetchAdventures"
      ></Info>
    </div>
</template>

<script>
import CardContent from './CardContent.vue';
import Info from './InformationContent.vue';
import {getAvailableAdventures} from "@/services/dndgate/endpoints/adventures";
import {getUserById} from "@/services/dndgate/endpoints/users";
import UseCodeAdventureContent from "@/views/tavern/content/adventure/UseCodeAdventureContent";

export default {
    components: { CardContent, Info, UseCodeAdventureContent },
    data() {
      return {
        isLoading: false,
        adventures: [],
        showInfoVisible: false,
        selectedAdventure: null,
      };
    },
    methods: {
      async fetchAdventures() {
        this.isLoading = true
        try {
          this.adventures = await getAvailableAdventures()

          this.adventures = await Promise.all(this.adventures.map(async (adventure) => {
            const user = await getUserById(adventure.creatorId);
            return {...adventure, creatorPseudo: user.pseudo};
          }))
        } finally {
          this.isLoading = false
        }
      },
      viewDetails(adventure) {
        this.$emit('view-details', adventure);
        this.showInfo(adventure);
      },
      showInfo(adventure) {
        this.selectedAdventure = adventure;
        this.showInfoVisible = true;
      },
      closeInfo() {
        this.showInfoVisible = false;
        this.selectedAdventure = null;
      }
    },
    mounted: async function() {
      await this.fetchAdventures();
    },
  };
</script>  

<style scoped>

  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0 5% 5%;
    padding: 20px 5% 5%;
  }

</style>
