<template>
  <loading-spinner :isLoading="isLoading" />

  <div v-if="allSpells != null && !isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <div class="d-flex justify-content-between">
            <h2>Spell List ({{ this.filteredSpells.length }} spells)</h2>
            <input type="text" v-model="searchText" placeholder="Rechercher un sort" />
          </div>
          <hr>

          <label for="spell-per-page-option">Spell per page : </label>
          <select id="spell-per-page-option" v-model="spellPerPage">
            <option v-for="option in spellPerPageOption" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
                <tr>
                  <th></th>
                  <th class="left-align clickable" @click="sortSpells('name')">Name</th>
                  <th class="center-align clickable" @click="sortSpells('level')">Level</th>
                  <th class="left-align">School</th>
                  <th class="left-align">Casting time</th>
                  <th class="left-align">Duration</th>
                  <th class="center-align">Concentration</th>
                  <th class="center-align">Ritual</th>
                  <th class="center-align">Components</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="spell in paginatedSpells" :key="spell">
                  <td><i v-if="isAuthenticated" @click="addSpellToBook(spell)" aria-hidden="true" class="bookmark v-icon notranslate mdi mdi-bookmark-outline theme--light"></i></td>
                  <td><a class="name" @click="this.select(spell.index)"><b>{{ spell.name }}</b></a></td>
                  <td>{{ spell.level }}</td>
                  <td>{{ spell.school.name }}</td>
                  <td>{{ spell.casting_time }}</td>
                  <td>{{ spell.duration }}</td>
                  <td><a v-if="spell.concentration">concentration</a></td>
                  <td><a v-if="spell.ritual">ritual</a></td>
                  <td>{{ spell.components.join(', ') }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="page-selection">
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
            </button>
            <button class="page-button" v-if="currentPage > 2" @click="changePage(1)">1</button>
            <span v-if="currentPage > 3">...</span>
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">{{ currentPage - 1 }}</button>
            <button id="selected-page-button" class="page-button"> {{ currentPage }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">{{ currentPage + 1 }}</button>
            <span v-if="currentPage < totalOfPages-2">...</span>
            <button class="page-button" v-if="currentPage < totalOfPages-1" @click="changePage(totalOfPages)">{{ totalOfPages }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddSpellToBookPopup v-if="addingSpell" :spell="spellToAdd" @close="closeAddSpellPopup"></AddSpellToBookPopup>

</template>

<script>

import AddSpellToBookPopup from "@/views/library/content/player/AddSpellToBookPopup";
import { getSpells } from "@/services/dndofficial/endpoints/spells";
import {mapState} from "vuex";
import LoadingSpinner from "@/views/shared/LoadingSpinner";

export default {
  components: { AddSpellToBookPopup, LoadingSpinner },
  data() {
    return {
      spellPerPage: 10,
      spellPerPageOption: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '15', value: 15},
        {text: 'All', value: 0},
      ],
      currentPage: 1,
      currentPageOption: this.setupPagination(),
      isLoading: false,
      allSpells: [],
      addingSpell: false,
      spellToAdd: null,
      searchText: '',
      sortColumn: 'name',
      sortOrder: 'asc',
    };
  },
  watch: {
    spellPerPage(newValue) {
      if (newValue === 0)
        this.spellPerPage = this.filteredSpells.length
      this.currentPageOption = this.setupPagination()
      this.currentPage = 1
    },
  },
  computed: {
    ...mapState(['isAuthenticated']),
    filteredSpells() {
      let filtered =  this.allSpells.filter(spell => {
        return spell.name.toLowerCase().includes(this.searchText.toLowerCase());
      });

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1;
        if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
        if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
        return 0;
      });
    },
    paginatedSpells() {
      const start = (this.currentPage - 1) * this.spellPerPage;
      const end = start + this.spellPerPage;
      return this.filteredSpells.slice(start, end);
    },
    totalOfPages() {
      return Math.ceil(this.filteredSpells.length / this.spellPerPage);
    },
  },
  mounted: async function(){
    await this.getSpells()
  },
  methods: {
    sortSpells(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
    setupPagination() {
      let options = [];
      for (let i = 1; i <= this.totalOfPages; i++) {
        options.push({ text: `Page ${i}`, value: i });
      }
      return options;
    },
    changePage(page) {
      this.currentPage = page;
    },
    async getSpells() {
      this.isLoading = true
      try {
        const response = await getSpells(0,0)
        this.allSpells = response.data.spells
      } catch (error) {
        this.allSpells = []
      } finally {
        this.isLoading = false
      }
    },
    select(spellIndex) {
      this.$router.push({ name: `library/spell`, params: {spellIndex: spellIndex}});
    },
    addSpellToBook(spell) {
      this.addingSpell = true
      this.spellToAdd = spell
    },
    closeAddSpellPopup() {
      this.addingSpell = false
      this.spellToAdd = null
    }
  },
}

</script>

<style scoped>

  #page-selection {
    text-align: center;
  }

  #selected-page-button {
    background-color: #4C6477;
    color: white;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  select {
    width: 30%;
    padding: 8px;
    margin-bottom: 20px;
  }

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .table-wrapper{
    margin: 10px 0;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
    border-radius: 5px;
    font-size: 18px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }

  .fl-table td, .fl-table th {
    padding: 15px;
  }

  .fl-table td b {
    border-right: 1px solid #f8f8f8;
    color: #4C6477;
  }

  .fl-table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: start;
  }

  .name {
    cursor: pointer;
  }

  .page-button {
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
    margin: 4px;
  }

  .page-button:hover, .bookmark:hover {
    cursor: pointer;
  }

  .clickable:hover {
    cursor: pointer;
  }

</style>