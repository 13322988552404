<template>
	<article class="plan card">
		<div class="inner">
			<h2 class="menu-title">{{ adventure.name }}</h2>
			<ul class="features">
				<li class="arg-list">
					<span>
						<img src="@/assets/tavern/adventures/adventure-logo.png" class="icon" width="4" height="4" />
					</span>
					<span>{{ adventure.minPlayer }}/{{ adventure.maxPlayer }}</span>
				</li>
				<li>
					<span>
						<img src="@/assets/tavern/adventures/location-logo.png" class="icon" width="4" height="4" />
					</span>
					<span>{{ adventure.city }}, {{ adventure.postalCode }}</span>
				</li>
				<li>
					<span>
						<img src="@/assets/tavern/adventures/leader-logo.png" class="icon" width="4" height="4" />
					</span>
					<span>{{ adventure.creatorPseudo }}</span>
				</li>
			</ul>
		</div>
		<button class="tavern-button" @click="$emit('view-details', adventure)">Voir</button>
	</article>
</template>
  
  
  
<script>
	export default {
		props: {
			adventure: Object,
		},
	};
</script>
  
<style scoped>
	.card {
		font-weight: bold;
		text-align: center;
		color: #181a1a;
		width: 200px;
		height: 266px;
		border-radius: 10px;
		background-image: url(@/assets/tavern/adventures/card-background.png);
		background-size: cover;
		position: relative;
	}

	.plan {
		padding: 10px;
		background-color: var(--c-white);
		color: var(--c-del-rio);
	}

	.strong {
		font-weight: 600;
		color: var(--c-coffee);
	}

	.inner {
		padding-top: 4px;
		background-color: var(--c-fair-pink);
		border-radius: 10px;
		overflow: hidden;
	}
  
	.menu-title {
		font-weight: 600;
		font-size: 1.25rem;
		color: var(--c-coffee);
		& + * {
			margin-top: 0.05rem;
		}
	}
  
	.features {
		display: flex;
		flex-direction: column;
		li {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			& + * {
			margin-top: 0.75rem;
			}
		}
	}
  
	.icon {
		background-color: var(--c-java);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: var(--c-white);
		width: 20px;
		height: 20px;
		svg {
			width: 20px;
			height: 20px;
		}
	}

	& + * {
		margin-top: 1.25rem;
	}

	.arg-list {
		margin-top: 25px;
	}
  
	.tavern-button {
		font: inherit;
		background-color: var(--c-indigo);
		border-radius: 6px;
		color: var(--c-white);
		font-weight: 500;
		font-size: 1.125rem;
		width: 100%;
		border: 0;
		&:hover,
		&:focus {
			background-color: var(--c-governor);
		}
	}
</style>
  