<template>
  <div class="col-12 d-flex justify-content-evenly">
    <div class="col-10 d-flex justify-content-evenly">
      <div class="card w-100">
        <div class="card-body">
          <div v-if="imMaster" class="d-flex justify-content-between m-2">
            <button class="btn btn-outline-primary" @click="updateHistoricalNote"><font-awesome-icon icon="edit" /></button>
            <button class="btn btn-outline-danger" @click="deleteHistoricalNote"><font-awesome-icon icon="trash" /></button>
          </div>
          <p>{{ this.historicalNote.content }}</p>
          <i>{{ this.historicalNote.date }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteHistoricalNote} from "@/services/dndgate/endpoints/historical-notes";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    historicalNote: {
      type: Object,
      required: true
    },
    imMaster: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateHistoricalNote() {
      this.$router.push({
        name: 'tavern/adventure/updateHistoricalNote',
        params: {historicalNoteId: this.historicalNote.id}
      })
    },
    async deleteHistoricalNote() {
      this.isLoading = true
      try {
        await deleteHistoricalNote(this.historicalNote.id)
        this.$emit('deleted')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>