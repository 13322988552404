import axios from "@/services/dndgate/axios";

const endpoint = "/messages"

const getMessagesByAdventureId = async (adventureId) => {
    const params = {
        adventureId: adventureId
    }
    try {
        const response = await axios.get(`${endpoint}/getByAdventureId`, { params: params });
        return response.data;
    } catch (error) {
        console.error('Error while getting message by adventure id: ', error)
        throw error;
    }
}

/**
 * @param {MessageCreationRequestDto} requestBody
 */
const sendMessage = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while sending message: ', error)
        throw error;
    }
}

export { sendMessage, getMessagesByAdventureId }