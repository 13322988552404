<template>
    <div :class="['messenger-sidebar', { open: isOpen }]">
      <button class="toggle-button" @click="toggleSidebar">
        <img src="@/assets/tavern/adventures/messager.png" alt="Messenger" v-if="!isOpen"/>
        <span v-else>-></span>
      </button>
      <div v-show="isOpen" class="messenger-content">
        <div class="messenger-title">
          <h4>Discussion</h4>
        </div>
        <div class="message-list" ref="messageList">
          <ul>
            <li v-for="message in messages" :key="message.id" class="message-item">
              <MessageCard :message="message"></MessageCard>
            </li>
          </ul>
        </div>
        <form @submit.prevent="sendMessage" class="message-form">
          <textarea rows="2" class="form-control message-input" v-model="messageContent" placeholder="Entrez votre message ..."></textarea>
          <button type="submit" class="send-button btn btn-primary">
            <font-awesome-icon icon="paper-plane" />
          </button>
        </form>
      </div>
    </div>
</template>

<script>

import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import MessageCreationRequestDto from "@/dto/request/MessageCreationRequestDto";
import {getMessagesByAdventureId, sendMessage} from "@/services/dndgate/endpoints/messages";
import MessageCard from "@/views/tavern/content/adventure/messenger/MessageCard";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

  export default {
    components: {MessageCard, FontAwesomeIcon},
    data() {
      return {
        isLoading: false,
        isOpen: false,
        stompClient: null,
        messages: [],
        messageCompleted: [],
        messageContent: null,
      };
    },
    props: {
      adventureId: String
    },
    created() {
      this.connect();
    },
    mounted: async function() {
      await this.getMessages();
    },
    methods: {
      toggleSidebar() {
        this.isOpen = !this.isOpen;
      },
      connect() {
        const socket = new SockJS(`${process.env.DNDGATE_API_URL}/ws`);
        this.stompClient = Stomp.over(socket);

        this.stompClient.connect({}, () => {
          // Subscribe to the topic for receiving messages
          this.stompClient.subscribe(`/topic/adventure/chat/${this.adventureId}`, message => {
            const receivedMessage = JSON.parse(message.body);
            this.messages.push(receivedMessage);
          });
        });
      },
      async getMessages() {
        this.isLoading = true
        try {
          this.messages = await getMessagesByAdventureId(this.adventureId)
        } finally {
          this.isLoading = false
        }
      },
      async sendMessage() {
        if (this.messageContent.trim() !== '') {
          this.isLoading = true
          const message = new MessageCreationRequestDto(
            this.adventureId,
            this.messageContent.trim(),
            new Date().toISOString()
          )
          try {
            await sendMessage(message)
            this.messageContent = ''
          } finally {
            this.isLoading = false
          }
        }
      },
      onMessage(event) {
        const message = JSON.parse(event.data);
        this.messages.push(message);
      },
    },
  };
</script>

<style scoped>
.messenger-sidebar {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 10px;
  background-color: #394E6B;
  transition: width 0.3s;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.messenger-sidebar.open {
  width: 300px;
}

.toggle-button {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button img {
  width: 100%;
  height: 100%;
}

.messenger-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messenger-title {
  color: white;
  padding: 10px;
  text-align: center;
}

.message-list {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #333333;
}

.message-list ul {
  list-style-type: none;
  padding: 0;
}

.message-form {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #333333;
  border-top: 1px solid #bdc3c7;
}

.message-input {
  flex-grow: 1;
  margin-right: 10px;
  border-radius: 20px;
  padding: 10px;
  border: 1px solid #bdc3c7;
}

.send-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.send-button img {
  width: 24px;
  height: 24px;
}
</style>
  