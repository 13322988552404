<template>
  <div class="card">
    <div class="card-body">
      <div class="col-11 ml-5">
        <form @submit.prevent="updateCharacter">
          <div class="d-flex justify-content-between mb-4">
            <div class="center-list-label">
              <h3>Modifier votre personnage</h3>
            </div>
          </div>

          <label for="name">Nom</label>
          <input id="name" class="form-control m-1" type="text" v-model="name" placeholder="Nom" required/>

          <label for="race">Race</label>
          <input id="race" class="form-control m-1" type="text" v-model="race" placeholder="Race" required/>

          <label for="martial-class">Classe</label>
          <input id="martial-class" class="form-control m-1" type="text" v-model="martialClass" placeholder="Classe" required/>

          <label for="personality">Personnalité</label>
          <textarea id="personality" rows="8" class="form-control m-1" v-model="personality" placeholder="Personnalité" required minlength="1"></textarea>

          <label for="background">Historique</label>
          <textarea id="background" rows="15" class="form-control m-1" v-model="background" placeholder="Historique" required minlength="1"></textarea>

          <div class="d-flex justify-content-center m-4">
            <button type="submit" class="btn btn-success">Modifier</button>
            <button type="button" class="btn btn-danger ms-2" @click="exit">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getGameCharacterDetails,
  updateGameCharacter
} from "@/services/dndgate/endpoints/game-characters";
    import GameCharacterCreationRequestDto from "@/dto/request/GameCharacterCreationRequestDto";
    
    export default {
      data() {
        return {
          name: '',
          race: '',
          martialClass: '',
          personality: '',
          background: '',
          character: null,
        };
      },
      watch: {
        gameCharacterId() {
          this.getCharacterDetails()
        }
      },
      computed: {
        gameCharacterId() {
          return this.$route.params.gameCharacterId;
        }
      },
      mounted: async function() {
        await this.getCharacterDetails()
        this.name = this.character.name
        this.race = this.character.race
        this.martialClass = this.character.martialClass
        this.personality = this.character.personality
        this.background = this.character.background
      },
      methods: {
        async getCharacterDetails() {
          this.isLoading = true
          try {
            this.character = await getGameCharacterDetails(this.gameCharacterId)
          } finally {
            this.isLoading = false
          }
        },
        async updateCharacter() {
          const gameCharacterData = new GameCharacterCreationRequestDto(
              this.adventureId,
              this.name.trim(),
              this.race.trim(),
              this.martialClass.trim(),
              this.personality.trim(),
              this.background.trim()
          );
          try {
            await updateGameCharacter(this.gameCharacterId, gameCharacterData)
            this.$router.push({name: 'tavern/adventure/gameCharacterList'})
          } finally {
            this.isLoading = false
          }
        },
        exit() {
          this.$router.push({name: 'tavern/adventure/gameCharacterList'})
        },
      },
    };
</script>
  
<style scoped>

  .form-adventure label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

</style>
