<template>
  <div class="menu-title" @click="handleClick">
    For Game Master
    <i v-if="!isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i>
    <i v-if="isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-up theme--light"></i>
  </div>
  <ul id="sub-menu-list" class="category" v-show="this.isVisible">
    <li>
      <div class="menu-title" @click="select('monsterList')">Bestiary</div>
    </li>
  </ul>
</template>

<script>

export default {
  name: "ForGameMasterCategory",
  data() {
    return {
      subVisibility: {
      },
    };
  },
  props: {
    isVisible: Boolean(false)
  },
  emits: [
    'clicked'
  ],
  methods: {
    handleClick() {
      this.$emit('clicked', 'forGameMaster');
    },
    toggleSubCategory(subCategory) {
      event.stopPropagation();
      for (let visibility in this.subVisibility) {
        if (visibility.toString() !== subCategory.toString()) {
          this.subVisibility[visibility] = false
        }
      }
      this.subVisibility[subCategory] = !this.subVisibility[subCategory];
    },
    select(index) {
      this.toggleSubCategory('')
      this.$router.push({ name: `library/${index}`});
    }
  }
}
</script>

<style scoped>


</style>