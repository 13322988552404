export default class AdventureUpdateRequestDto {
    constructor(name, description, maxPlayer, visibility, status, postalCode, city) {
        this.name = name
        this.description = description
        this.maxPlayer = maxPlayer
        this.visibility = visibility
        this.status = status
        this.postalCode = postalCode
        this.city = city
    }
}