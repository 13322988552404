<template>
  <div v-if="this.character !== null" class="card">
    <div class="card-body">
      <div class="d-flex justify-content-center m-3">
        <img :src="this.getPicture(this.character.picturePath)" class="preview" alt="Aperçu de l'image">
      </div>
      <div class="d-flex justify-content-between mb-4">
        <h3>{{ this.character.name }}</h3>
        <div v-if="canModifyCharacter()" class="d-flex justify-content-between">
          <button class="btn btn-primary" @click="updateCharacter"><font-awesome-icon icon="edit" /></button>
          <button class="btn btn-danger ms-1" @click="deleteCharacter"><font-awesome-icon icon="trash" /></button>
        </div>
      </div>
      <p>Race : {{ this.character.race }} </p>
      <p>Classe : {{ this.character.martialClass }} </p>
      <br>
      <h4>Personnalité</h4>
      <hr>
      <p>{{ this.character.personality }}</p>
      <br>
      <h4>Historique</h4>
      <hr>
      <p>{{ this.character.background }}</p>
    </div>
  </div>
</template>

<script>
import {deleteGameCharacter, getGameCharacterDetails} from "@/services/dndgate/endpoints/game-characters";
import {MinioClass} from "@/services/dndgate/minio";

export default {
  data() {
    return {
      isLoading: false,
      character: null,
    };
  },
  watch: {
    gameCharacterId() {
      this.getCharacterDetails()
    }
  },
  computed: {
    gameCharacterId() {
      return this.$route.params.gameCharacterId;
    }
  },
  mounted: async function() {
    await this.getCharacterDetails()
  },
  methods: {
    getPicture(url) {
      return MinioClass.getImageUrl(url)
    },
    canModifyCharacter() {
      return this.character.ownerId === localStorage.getItem('id')
    },
    updateCharacter() {
      this.$router.push({
        name: 'tavern/adventure/updateGameCharacter',
        params: {gameCharacterId: this.character.id}
      })
    },
    async deleteCharacter() {
      await deleteGameCharacter(this.character.id)
      this.$router.push({name: 'tavern/adventure/gameCharacterList'})
    },
    async getCharacterDetails() {
      this.isLoading = true
      try {
        this.character = await getGameCharacterDetails(this.gameCharacterId)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

.preview {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
  max-height: 40vh;
  max-width: 60vw;
}

ul {
  list-style-type: none;
}

h4 {
  color: #435A3F;
  margin-bottom: 0;
}

hr {
  margin-top: 2px;
}

p {
  margin-top: 3px;
  margin-bottom: 3px;
}

</style>