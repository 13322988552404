<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <h2 class="create-label" aria-hidden="true">Créer ton aventure</h2>
        <form @submit.prevent="handleCreate">

          <label for="name">Nom</label>
          <input class="add-input" type="text" id="name" v-model="name" placeholder="Nom" required>

          <label for="description">Description</label>
          <textarea rows="5" id="description" class="add-input" v-model="description" placeholder="Description" required></textarea>

          <div class="div-player">
            <label for="maxPlayer">Nombre de joueur maximum : </label>
            <input class="add-input num-input" type="number" id="maxPlayer" v-model="maxPlayer" placeholder="" min="1" required>
          </div>

          <div class="div-player">
            <label for="visibility">Visibilité</label>
            <select class="add-input select-input" id="visibility" v-model="visibility">
              <option value="PRIVATE">Privé</option>
              <option value="PUBLIC">Public</option>
            </select>
          </div>

          <div class="div-player div-inline">

            <div class="div-location">
              <label for="postalCode">Code postal</label>
              <select class="add-input location-input" id="postalCode" v-model="selectedPostalCode" @change="updateCities">
                <option v-for="code in postalCodes" :key="code" :value="code">{{ code }}</option>
              </select>
            </div>

            <div class="div-location">
              <label for="city" v-if="cities.length > 1">Ville</label>
              <select class="add-input location-input" id="city" v-model="selectedCity" v-if="cities.length > 1">
                <option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
              </select>
              <label for="city" v-if="cities.length === 1">Ville</label>
              <input class="add-input location-input" type="text" id="city" v-if="cities.length === 1" v-model="selectedCity" readonly>
            </div>

          </div>

          <button class="add-button" type="submit">Créer</button>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import postalCodeData from '@/data/postalCodeData.json';
import AdventureCreationRequestDto from "@/dto/request/AdventureCreationRequestDto";
import {createAdventure} from "@/services/dndgate/endpoints/adventures";

export default {
  data() {
    return {
      name: '',
      description: '',
      maxPlayer: null,
      visibility: 'PRIVATE',
      selectedPostalCode: '',
      selectedCity: '',
      postalCodes: Object.keys(postalCodeData),
      cities: [],
    };
  },
  methods: {
    updateCities() {
      this.cities = postalCodeData[this.selectedPostalCode] || [];
      this.selectedCity = this.cities.length === 1 ? this.cities[0] : '';
    },
    async handleCreate() {
      const adventureData = new AdventureCreationRequestDto(
          this.name,
          this.description,
          parseInt(this.maxPlayer, 10),
          this.visibility,
          this.selectedPostalCode,
          this.selectedCity
      );
      try {
        console.log(adventureData)
        const adventure = await createAdventure(adventureData)
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Aventure créé',
        });
        this.$router.push({name: 'tavern/adventure', params:{adventureId: adventure.id}})
      } catch (error) {
        this.$store.dispatch('showPopup', {
          title: '/!\\ Erreur /!\\',
          message: error.response.data,
          isSuccess: false
        });
      }
    },
  },
};
</script>

<style scoped>
.create-label{
  text-align: center;
}
.add-button{
  margin-top: 40px;
}


#creation-content {
  background: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-adventure label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.add-input, .num-input, .add-input select {
  width: 100%;
  margin-left: 5px;
  padding: 8px 5px 8px 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 10%;
  margin: 5px;
}

.div-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.add-button:hover {
  background-color: #45a049;
}

.num-input{
  width: 50px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.select-input{
  width: 100px;
}

.div-player{
  margin-top: 10px;
}

.location-input{
  width: 30% ;
}

.div-location{
  width: 50%;
}

.div-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-location {
  flex: 1;
  margin-right: 10px;
}

.div-location:last-child {
  margin-right: 0;
}

.location-input {
  width: 100%;
}

</style>