import {createNoise2D} from 'simplex-noise';

/*
export function generatePerlinNoise(width, height, valuesList) {
    const noise2D = createNoise2D()
    const noiseArray = [];
    const listLength = valuesList.length;
    for (let y = 0; y < height; y++) {
        const row = [];
        for (let x = 0; x < width; x++) {
            const noiseValue = noise2D(x / 10, y / 10);
            const listIndex = Math.floor((noiseValue + 1) / 2 * listLength);
            row.push(valuesList[listIndex]);
        }
        noiseArray.push(row);
    }
    return noiseArray;
}
*/

export function generatePerlinNoise(width, height, valuesList, probabilities) {
    if (valuesList.length !== probabilities.length) {
        throw new Error("valuesList and probabilities must have the same length");
    }

    // Normalize probabilities to sum to 1
    const totalProbability = probabilities.reduce((acc, prob) => acc + prob, 0);
    const normalizedProbabilities = probabilities.map(prob => prob / totalProbability);

    // Generate cumulative distribution array
    const cumulativeDistribution = [];
    normalizedProbabilities.reduce((acc, prob) => {
        cumulativeDistribution.push(acc + prob);
        return acc + prob;
    }, 0);

    const noise2D = createNoise2D();
    const noiseArray = [];

    for (let y = 0; y < height; y++) {
        const row = [];
        for (let x = 0; x < width; x++) {
            const noiseValue = (noise2D(x / 10, y / 10) + 1) / 2; // Normalizing to range [0, 1]

            // Find the corresponding value from valuesList based on noiseValue and cumulativeDistribution
            let listIndex = cumulativeDistribution.findIndex(cumProb => noiseValue <= cumProb);
            if (listIndex === -1) listIndex = valuesList.length - 1; // In case of rounding errors

            row.push(valuesList[listIndex]);
        }
        noiseArray.push(row);
    }
    return noiseArray;
}