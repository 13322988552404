<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="calendarTab-popup">
          <div class="title-bar">
            <h3>{{ tabTitle }}</h3>
          </div>
          <table v-if="planningDates.length > 0">
            <thead>
            <tr class="month-line">
              <th></th>
              <th v-for="(month, index) in uniqueMonths" :key="'month-header-' + index" :colspan="daysInMonth(month)"
                  class="month-header">
                {{ month }}
              </th>
            </tr>
            <tr>
              <th></th>
              <th v-for="(date, index) in planningDates" :key="'day-header-' + index" class="day-header">
                <div>{{ dayOfWeek(date) }}</div>
                <div>{{ date[2] }}</div>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(user, userIdx) in disponibilities" :key="'user-row-' + userIdx">
                <td class="data-cell">{{ user.pseudo }}</td>
                <td v-for="(date, dIndex) in planningDates" :key="'data-cell-' + userIdx + '-' + dIndex" class="data-cell"
                    :style="getCellBackgroundColor(user, date)">
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <p>Aucune date de proposition disponible.</p>
          </div>
          <div v-if="isResponding" class="save-button-container">
            <button @click="saveResponses" class="save-button">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPlanningByAdventure,
  getPlanningById,
  getDisponibilitiesByPlanningId
} from "@/services/dndgate/endpoints/plannings";
import {getUserById} from "@/services/dndgate/endpoints/users";

export default {
  data() {
    return {
      tabTitle: "Planning",
      planningId: null,
      planningDates: [],
      monthYear: '',
      disponibilities: [],
      currentUserResponse: null,
      showOptions: {},
      isResponding: false,
      errorLoading: false
    };
  },
  async mounted() {
    await this.fetchPlanning();
  },
  watch: {
    adventureId() {
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    },
    uniqueMonths() {
      const months = this.planningDates.map(date => this.monthName(date[1]) + ' ' + date[0]);
      return [...new Set(months)].sort((a, b) => new Date(a.split(' ')[1], new Date(0).setMonth(this.monthNumber(a.split(' ')[0]) - 1, 1)) - new Date(b.split(' ')[1], new Date(0).setMonth(this.monthNumber(b.split(' ')[0]) - 1, 1)));
    }
  },
  methods: {
    async fetchPlanning() {
      try {
        const planning = await getPlanningByAdventure(this.adventureId);
        if (planning) {
          this.planningId = planning.id;
          const details = await getPlanningById(planning.id);
          this.planningDates = details.propositionDates.sort((a, b) => new Date(a[0], a[1] - 1, a[2]) - new Date(b[0], b[1] - 1, b[2]));
          if (this.planningDates.length > 0) {
            const sampleDate = this.planningDates[0];
            this.monthYear = this.monthName(sampleDate[1]) + ' ' + sampleDate[0];
          }
          const disponibilities = await getDisponibilitiesByPlanningId(planning.id);
          await this.fetchUserDetails(disponibilities);
          this.errorLoading = false;
        } else {
          this.errorLoading = true;
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du planning:', error);
        this.errorLoading = true;
      }
    },
    getCellBackgroundColor(user, date) {
      const [year, month, day] = date;
      const availabilityDates = user.disponibilityDates.map(d => d[0] + '-' + d[1] + '-' + d[2]);
      const currentDate = year + '-' + month + '-' + day;
      return {
        backgroundColor: availabilityDates.includes(currentDate) ? '#99D698' : 'rgb(193,63,69,0.7)'
      };
    },

    daysInMonth(month) {
      return this.planningDates.filter(date => {
        const fmt = this.monthName(date[1]) + ' ' + date[0];
        return fmt === month;
      }).length;
    },
    async fetchUserDetails(disponibilities) {
      const disponibilitiesWithData = await Promise.all(disponibilities.map(async disponibility => {
        const user = await getUserById(disponibility.userId);
        return {
          ...disponibility,
          pseudo: user.pseudo
        };
      }));
      this.disponibilities = disponibilitiesWithData;
    },
    dayOfWeek(date) {
      const [year, month, day] = date;
      return new Date(year, month - 1, day).toLocaleDateString('fr-FR', {weekday: 'short'});
    },
    monthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleDateString('fr-FR', {month: 'long'});
    },
    monthNumber(monthName) {
      return new Date(Date.parse(monthName + " 1, 2000")).getMonth() + 1;
    },
  }
};
</script>

<style scoped>

table {
  width: 100%;
  max-width: 700px;
  border-collapse: collapse;
}

.month-header {
  text-align: center;
  color: #36393b;
  font-size: 16px;
  border: 1px solid #F8F7FF;
}

.day-header {
  background-color: #a5d8ff;
  color: black;
  text-align: center;
  font-size: 14px;
  border: 1px solid #F8F7FF;
  width: 100px;
}

.data-cell {
  text-align: center;
  background-color: white;
  border: 1px solid #DDD;
}

.planning-date h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.planning-date table {
  width: 100%;
  border-collapse: collapse;
}

.planning-date th, .planning-date td {
  border: 1px solid #ccc;
  padding: 8px;
}

.planning-date th {
  background-color: #f0f0f0;
  text-align: left;
}

.calendarTab-popup {
  position: relative;
  background-color: #F2F2F2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.title-bar h3 {
  margin: 0;
  flex: 1;
  text-align: center;
}

.save-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

</style>