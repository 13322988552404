import {gql_instance} from "../axios";

const getBackgrounds = async () => {
    const query = {
        query: `
query GetBackgrounds {
  backgrounds {
    index
    name
  }
}
`
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting backgrounds: ', error)
        throw error;
    }
}

const getBackgroundDescription = async (index) => {
    const query = {
        query: `
query GetBackgroundDescriptionByIndex($index: String) {
  background(index: $index) {
    name
    feature {
      name
      desc
    }
    personality_traits {
      type
      choose
      from {
        options {
          string
        }
      }
    }
    ideals {
      type
      choose
      from {
        options {
          desc
        }
      }
    }
    bonds {
      type
      choose
      from {
        options {
          string
        }
      }
    }
    flaws {
      type
      choose
      from {
        options {
          string
        }
      }
    }
    language_options {
      type
      choose
    }
  }
}
`,
        variables: {
            index: index
        }
};

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting background description: ', error)
        throw error;
    }
}

export { getBackgrounds, getBackgroundDescription };