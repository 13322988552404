import axios from '../axios';

const endpoint = "/adventures"

/**
 * @param {AdventureCreationRequestDto} requestBody
 */
const createAdventure = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating adventure: ', error)
        throw error;
    }
}


/**
 * @param id
 * @param {AdventureUpdateRequestDto} requestBody
 */
const updateAdventure = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating spell book: ', error)
        throw error;
    }
}

const getAdventureDetails = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while getting adventure: ', error)
        throw error;
    }
}

const getAvailableAdventures = async () => {
    try {
        const response = await axios.get(`${endpoint}/available`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch available adventures:', error);
        if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
        } else if (error.request) {
            console.error(error.request);
        } else {
            console.error('Error', error.message);
        }
        throw error;
    }
};


const joinAdventure = async (id) => {
    try {
        const response = await axios.post(`${endpoint}/${id}/join`);
        return response.data;
    } catch (error) {
        console.error('Error while joining adventure: ', error)
        throw error;
    }
}

const getAdventuresWhereImPlayer = async () => {
    try {
        const response = await axios.get(`${endpoint}/getWhereImPlayer`);
        return response.data;
    } catch (error) {
        console.error('Error while getting adventure where you are player: ', error)
        throw error;
    }
}

const getAdventuresWhereImMaster = async () => {
    try {
        const response = await axios.get(`${endpoint}/getWhereImMaster`);
        return response.data;
    } catch (error) {
        console.error('Error while getting adventure where you are master: ', error)
        throw error;
    }
}

const changeAdventureStatus = async (id, newStatus) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}/setStatut`, {
            status: newStatus
        });
        return response.data;
    } catch (error) {
        console.error(`Error while changing adventure status for adventure ${id}: `, error);
        throw error;
    }
}

const quitAdventure = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}/quit`);
        return response.data;
    } catch (error) {
        console.error('Error while quitting adventure: ', error)
        throw error;
    }
}

const deleteAdventure = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting adventure: ', error)
        throw error;
    }
}

const setAdventureStatus = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}/setStatus`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while change adventure status: ', error)
        throw error;
    }
}

const sendInvitationCodeToUser = async (adventureId, userId) => {
    try {
        const response = await axios.post(`${endpoint}/${adventureId}/sendInvitationCode/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error while sending adventure code: ', error)
        throw error;
    }
}

const joinAdventureByCode = async (code) => {
    try {
        const response = await axios.post(`${endpoint}/joinByInvitationCode`, {code: code});
        return response.data;
    } catch (error) {
        console.error('Error while joining adventure by code: ', error)
        throw error;
    }
}

export {
    createAdventure,
    updateAdventure,
    getAdventureDetails,
    getAvailableAdventures,
    joinAdventure,
    getAdventuresWhereImPlayer,
    getAdventuresWhereImMaster,
    changeAdventureStatus,
    quitAdventure,
    deleteAdventure,
    setAdventureStatus,
    sendInvitationCodeToUser,
    joinAdventureByCode,
}