<template>
  <div>
    <div class="video-container">
      <div class="video-box">
        <video id="localVideo" autoplay playsinline></video>
      </div>
      <div class="video-box" v-for="(remoteStream, id) in remoteStreams" :key="id">
        <video :id="'remoteVideo-' + id" autoplay playsinline :srcObject="remoteStream"></video>
      </div>
    </div>
    <div class="controls">
      <button @click="toggleAudio" class="btn" :class="{'btn-danger': !isAudioEnabled, 'btn-primary': isAudioEnabled}">
        <font-awesome-icon :icon="isAudioEnabled ? 'microphone' : 'microphone-slash'"/>
      </button>
      <button @click="toggleVideo" class="btn" :class="{'btn-danger': !isVideoEnabled, 'btn-primary': isVideoEnabled}">
        <font-awesome-icon :icon="isVideoEnabled ? 'video' : 'video-slash'"/>
      </button>
      <button @click="leaveCall" class="btn btn-danger">
        <font-awesome-icon icon="phone-slash"/>
      </button>
    </div>
  </div>
</template>

<script>
import {getAdventureDetails} from "@/services/dndgate/endpoints/adventures";
import {getUserById} from "@/services/dndgate/endpoints/users";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import SockJS from "sockjs-client";
import {Stomp} from "@stomp/stompjs";
import {sendAnswer, sendCandidate, sendOffer} from "@/services/dndgate/websocket";
import WebRTCMessage from "@/dto/webrtc/WebRTCMessage";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
      adventure: null,
      isParticipant: false,
      isAudioEnabled: true,
      isVideoEnabled: true,
      localStream: null,
      peerConnections: {},
      remoteVideos: [],
      stompClient: null,
    };
  },
  watch: {
    adventureId() {
      this.getAdventureDetails()
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    }
  },
  mounted: async function() {
    await this.getAdventureDetails();
    await this.startLocalStream();

  },
  created() {
    const socket = new SockJS(`${process.env.DNDGATE_API_URL}/ws`);
    this.stompClient = Stomp.over(socket);

    this.stompClient.connect({}, () => {
      this.stompClient.subscribe('/topic/adventure/webrtc/' + this.adventureId, message => {
        const data = JSON.parse(message.body);
        this.handleWebRTCMessage(data);
      });
    })
  },
  methods: {
    async getAdventureDetails() {
      this.isLoading = true
      try {
        this.adventure = await getAdventureDetails(this.adventureId)
        this.isParticipant = this.adventure.players.includes(localStorage.getItem('id'))
        this.adventure.players = await Promise.all(this.adventure.players.map(async (playerId) => {
          return await getUserById(playerId);
        }))
      } finally {
        this.isLoading = false
      }
    },
    async startLocalStream() {
      const constraints = {video: true, audio: true};
      this.localStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.$refs.localVideo.srcObject = this.localStream;
    },
    createPeerConnection(id) {
      console.log(id)
      const pc = new RTCPeerConnection();

      // Ajouter les pistes locales à la connexion
      this.localStream.getTracks().forEach(track => {
        pc.addTrack(track, this.localStream);
      });

      // Gérer les candidats ICE
      pc.onicecandidate = event => {
        if (event.candidate) {
          this.sendCandidate(event.candidate, id);
        }
      };

      // Gérer les pistes distantes
      pc.ontrack = event => {
        if (!this.remoteStreams[id]) {
          this.remoteStreams[id] = new MediaStream();
        }
        this.remoteStreams[id].addTrack(event.track);
      };

      this.peerConnections[id] = pc;
      return pc;
    },
    async receiveOffer(offer, id) {
      const pc = this.peerConnections[id] || this.createPeerConnection(id);

      const remoteDesc = new RTCSessionDescription(JSON.parse(offer));
      await pc.setRemoteDescription(remoteDesc);

      const answer = await pc.createAnswer();
      await pc.setLocalDescription(answer);

      this.sendAnswer(answer, id);
    },
    async receiveAnswer(answer, id) {
      const pc = this.peerConnections[id];
      const remoteDesc = new RTCSessionDescription(JSON.parse(answer));
      await pc.setRemoteDescription(remoteDesc);
    },
    async receiveCandidate(candidate, id) {
      const pc = this.peerConnections[id];
      const iceCandidate = new RTCIceCandidate(JSON.parse(candidate));
      await pc.addIceCandidate(iceCandidate);
    },
    async sendOffer(offer) {
      const message = new WebRTCMessage(
          'offer',
          JSON.stringify(offer),
          this.adventureId,
          localStorage.getItem("id")
      )
      await sendOffer(message);
    },
    async sendAnswer(answer) {
      const message = new WebRTCMessage(
          'answer',
          JSON.stringify(answer),
          this.adventureId,
          localStorage.getItem("id")
      )
      await sendAnswer(message);
    },
    async sendCandidate(candidate) {
      const message = new WebRTCMessage(
          'candidate',
          JSON.stringify(candidate),
          this.adventureId,
          localStorage.getItem("id")
      )
      await sendCandidate(message);
    },
    async joinCall() {
      // Créer la connexion pour soi-même
      const pc = this.createPeerConnection(localStorage.getItem("id"));
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);
      this.sendOffer(offer);
    },
    handleWebRTCMessage(data) {
      console.log(data)
      const { id, type, content } = data;
      if (type === 'offer') {
        this.receiveOffer(content, id);
      } else if (type === 'answer') {
        this.receiveAnswer(content, id);
      } else if (type === 'candidate') {
        this.receiveCandidate(content, id);
      }
    },
    toggleAudio() {
      this.isAudioEnabled = !this.isAudioEnabled;
      this.localStream.getAudioTracks().forEach(track => {
        track.enabled = this.isAudioEnabled;
      });
    },
    toggleVideo() {
      this.isVideoEnabled = !this.isVideoEnabled;
      this.localStream.getVideoTracks().forEach(track => {
        track.enabled = this.isVideoEnabled;
      });
    },
    leaveCall() {
      // Arrêter tous les flux multimédia
      if (this.localStream) {
        this.localStream.getTracks().forEach(track => track.stop());
      }

      // Fermer toutes les connexions WebRTC
      Object.values(this.peerConnections).forEach(pc => {
        pc.close();
      });
      this.peerConnections = {};

      // Déconnecter le client WebSocket
      if (this.stompClient) {
        this.stompClient.disconnect();
      }

      // Fermer la fenêtre
      window.close();
    },
  },
};
</script>


<style>
.video-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.video-box {
  flex: 1 1 calc(33.333% - 20px); /* Adjust the percentage to change the number of videos per row */
  max-width: calc(33.333% - 20px); /* Adjust the percentage to change the number of videos per row */
  box-sizing: border-box;
}

.video-box video {
  width: 100%;
  height: auto;
}

.controls {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>