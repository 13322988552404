import { createStore } from 'vuex';

const store = createStore({
    state: {
        isAuthenticated: !!localStorage.getItem('token'),
        popup: {
            visible: false,
            title: '',
            message: '',
            isSuccess: true
        },
        temporaryPopup: {
            visible: false,
            message: '',
            duration: 5000
        }
    },
    mutations: {
        setAuthentication(state, status) {
            state.isAuthenticated = status;
        },
        SHOW_POPUP(state, payload) {
            state.popup = { ...payload, visible: true }
        },
        HIDE_POPUP(state) {
            state.popup.visible = false
        },
        SHOW_TEMPORARY_POPUP(state, payload) {
            state.temporaryPopup = { ...payload, visible: true }
        },
        HIDE_TEMPORARY_POPUP(state) {
            state.temporaryPopup.visible = false
        }
    },
    actions: {
        updateAuthentication({ commit }, status) {
            commit('setAuthentication', status);
        },
        showPopup({ commit }, payload) {
            commit('SHOW_POPUP', payload)
        },
        hidePopup({ commit }) {
            commit('HIDE_POPUP')
        },
        showTemporaryPopup({ commit }, payload) {
            commit('SHOW_TEMPORARY_POPUP', payload)
            setTimeout(() => {
                commit('HIDE_TEMPORARY_POPUP')
            }, payload.duration || 3500);
        }
    }
});

export default store;