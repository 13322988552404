import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/global-style.css'
import process from 'process';


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faPaperPlane,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhoneSlash,
    faTrash,
    faEdit,
    faSync,
    faUser,
    faPlus,
    faSignOutAlt,
    faHome,
    faBook,
    faFileImport,
    faFileExport,
    faEye,
    faDice,
    faDiceThree,
    faChessKnight,
    faPerson,
    faStickyNote,
    faFile,
    faHistory
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faPaperPlane,
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhoneSlash,
    faTrash,
    faEdit,
    faSync,
    faUser,
    faPlus,
    faSignOutAlt,
    faHome,
    faBook,
    faFileImport,
    faFileExport,
    faEye,
    faDice,
    faDiceThree,
    faChessKnight,
    faPerson,
    faStickyNote,
    faFile,
    faHistory
);

window.process = process;

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(store)
app.mount('#app')
