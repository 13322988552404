<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="d-flex justify-content-between">
          <h3 v-if="this.adventure.historicalNotes.length === 0">Aucun historique</h3>
          <h3 v-else>Historique de l'aventure</h3>
          <button class="btn btn-outline-primary" @click="getAdventureDetails"><font-awesome-icon icon="sync" /></button>
          <button v-if="imMaster" class="btn btn-outline-success" @click="createHistoricalNote"><font-awesome-icon icon="plus" /> <font-awesome-icon icon="history" /></button>
        </div>
        <div class="overflow-auto mt-4">
          <ul>
            <li v-for="historic in this.adventure.historicalNotes" :key="historic">
              <historical-note-item
                  :im-master="imMaster"
                  :historicalNote=historic
                  @deleted="handleHistoricalNoteDeleted"></historical-note-item>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HistoricalNoteItem from "@/views/tavern/content/adventure/historicalnote/HistoricalNoteItem";
import {getAdventureDetails} from "@/services/dndgate/endpoints/adventures";
import {getUserById} from "@/services/dndgate/endpoints/users";
import {getHistoricalNotesByAdventureId} from "@/services/dndgate/endpoints/historical-notes";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { HistoricalNoteItem , FontAwesomeIcon},
  data() {
    return {
      isLoading: false,
      adventure: null,
      imMaster: false,
    };
  },
  watch: {
    adventureId() {
      this.getAdventureDetails()
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    }
  },
  mounted: async function() {
    await this.getAdventureDetails();
    this.imMaster = (this.adventure.gameMaster.id.toString() === localStorage.getItem('id').toString())
  },
  methods: {
    async getAdventureDetails() {
      this.isLoading = true
      try {
        this.adventure = await getAdventureDetails(this.adventureId)

        const gameMaster = await getUserById(this.adventure.creatorId);
        this.adventure = { ...this.adventure, gameMaster: gameMaster }
        this.adventure.historicalNotes = await getHistoricalNotesByAdventureId(this.adventureId)
      } finally {
        this.isLoading = false
      }
    },
    createHistoricalNote() {
      this.$router.push({name: 'tavern/adventure/createHistoricalNote'})
    },
    handleHistoricalNoteDeleted() {
      this.getAdventureDetails()
    }
  },
}
</script>

<style scoped>

</style>