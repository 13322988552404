<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="calendar-content">
          <vue-cal
              :events="events"
              @cell-click="handleDateClick"
              :disable-views="['years', 'year', 'week', 'day']"
              default-view="month"
              :show-all-day-events="true"
              :time="false"
          >
            <template #cell-content="{ cell, view }">
              <div
                  v-if="view.id === 'month'"
                  :key="cell.date"
                  class="vuecal__cell-date full-height column justify-center"
                  :class="[view.id, getColorClass(cell)]"
              >
                {{ cell.content }}
              </div>
            </template>
          </vue-cal>
          <button class="validate-button" @click="submitDates">
            <img src="@/assets/tavern/adventures/accept.png" alt="Validate">
          </button>
        </div>

        <div class="success-message" v-if="successMessage">{{ successMessage }}</div>
        <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import { defineComponent, ref, onMounted } from 'vue';
import { getPlanningByAdventure, updatePlanning } from "@/services/dndgate/endpoints/plannings";

export default defineComponent({
  components: { VueCal },
  props: {
    adventureId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const selectedDates = ref(new Set());
    const successMessage = ref('');
    const errorMessage = ref('');

    const showSuccessMessage = (message) => {
      successMessage.value = message;
      setTimeout(() => {
        successMessage.value = '';
      }, 1500);
    };

    const showErrorMessage = (message) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = '';
      }, 3000);
    };

    onMounted(async () => {
      try {
        const planning = await getPlanningByAdventure(props.adventureId);
        if (planning && planning.propositionDates) {
          planning.propositionDates.forEach(date => {
            const formattedDate = formatDate(new Date(...date));
            selectedDates.value.add(formattedDate);
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du planning :', error);
      }
    });

    const formatDate = (date) => {
      const d = new Date(date);
      return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
    };

    const isDatePast = (date) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return new Date(date) < today;
    };

    const getColorClass = (cell) => {
      const formattedDate = formatDate(cell.date);
      if (isDatePast(cell.date)) {
        return 'past-date';
      }
      return selectedDates.value.has(formattedDate) ? 'selected-date' : 'unselected-date';
    };

    const handleDateClick = (date) => {
      if (isDatePast(date)) return;
      const formattedDate = formatDate(date);
      if (selectedDates.value.has(formattedDate)) {
        selectedDates.value.delete(formattedDate);
      } else {
        selectedDates.value.add(formattedDate);
      }
      selectedDates.value = new Set([...selectedDates.value]);
      console.log("Dates sélectionnées :", selectedDates.value);
    };

    const submitDates = async () => {
      const datesArray = Array.from(selectedDates.value);
      if (datesArray.length === 0) {
        console.error("No dates selected for submission.");
        return;
      }
      const requestBody = {
        adventureId: props.adventureId,
        propositionDates: datesArray
      };
      try {
        const data = await updatePlanning(requestBody);
        if (data && data.id) {
          showSuccessMessage("Le planning a bien été mis à jour.");
        } else {
          showErrorMessage("Une erreur s'est produite lors de la mise à jour du planning.");
        }
      } catch (error) {
        console.error('Erreur lors de la mise à jour du planning :', error);
        showErrorMessage("Erreur lors de la mise à jour du planning.");
      }
    };

    return {
      selectedDates,
      getColorClass,
      handleDateClick,
      submitDates,
      successMessage,
      errorMessage
    };
  }
});
</script>


<style scoped>
.validate-button {
  position: absolute;
  top: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.validate-button img {
  width: 24px;
  height: 24px;
}

.validate-button {
  left: 10px;
}

.calendar-content {
  width: 100%;
  position: relative;
  background-color: #F2F2F2;
}

.success-message {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
</style>
