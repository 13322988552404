<template>
  <div class="loading-dice-container" v-if="isLoading">
    <div ref="diceContainer" class="dice-container"></div>
    <div class="loading-text">Chargement ...</div>
  </div>
</template>

<script>
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {nextTick} from 'vue';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    nextTick(() => {
      this.initThreeJS();
    });
  },
  methods: {
    initThreeJS() {
      const container = this.$refs.diceContainer;
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // Utiliser alpha pour la transparence
      renderer.setSize(container.clientWidth, container.clientHeight);
      renderer.setClearColor(0x000000, 0); // Rendre le fond transparent
      container.appendChild(renderer.domElement);

      const geometry = new THREE.IcosahedronGeometry(1);
      const material = new THREE.MeshBasicMaterial({color: 0xffffff, wireframe: true});
      const dice = new THREE.Mesh(geometry, material);

      // Rotation initiale pour aligner l'axe Y avec deux sommets opposés
      dice.rotation.x = Math.PI / 5.0;
      dice.rotation.z = Math.atan(Math.sqrt(2));
      scene.add(dice);

      camera.position.z = 3;

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = false;

      const animate = () => {
        requestAnimationFrame(animate);
        dice.rotation.y += 0.01;
        controls.update();
        renderer.render(scene, camera);
      };

      animate();
    }
  }
};
</script>

<style scoped>
.loading-dice-container {

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(20, 20, 20, 0.8);
}

.dice-container {
  width: 200px;
  height: 200px;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #FFF;
}
</style>
