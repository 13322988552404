<template>
  <div class="menu-title" @click="handleClick">
    Backgrounds
    <i v-if="!isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i>
    <i v-if="isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-up theme--light"></i>
  </div>
  <ul id="sub-menu-list" v-show="this.isVisible">
    <li class="menu-title" v-for="background in this.backgrounds" :key="background" @click="select(background.index)">{{ background.name }}</li>
  </ul>
</template>

<script>

import {getBackgrounds} from "@/services/dndofficial/endpoints/backgrounds";

export default {
  name: "BackgroundsSubCategory",
  data() {
    return {
      backgrounds: [],
    };
  },
  props: {
    isVisible: Boolean(false),
  },
  emits: [
    'clicked'
  ],
  mounted: async function() {
    await this.getBackgrounds()
  },
  methods: {
    async getBackgrounds() {
      this.isLoading = true
      try {
        const response = await getBackgrounds()
        this.backgrounds = response.data.backgrounds
      } catch (error) {
        this.backgrounds = []
      } finally {
        this.isLoading = false
      }
    },
    handleClick() {
      this.$emit('clicked', 'backgrounds');
    },
    select(backgroundIndex) {
      this.$router.push({ name: `library/background`, params: {backgroundIndex: backgroundIndex}});
    }
  }
}
</script>

<style scoped>


</style>