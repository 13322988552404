<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="classe != null && !isLoading">
          <div id="header">
            <img :src="getImagePath(classIndex)" alt="icon">
            <h1>{{classe.name.toUpperCase()}}</h1>
          </div>

          <h2 class="mt-4">{{classe.name}} evolution</h2>
          <hr>
          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
                <tr>
                  <th class="center-align">Level</th>
                  <th class="center-align">Prof bonus</th>
                  <th class="left-align">Features</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="level in levels" :key="level">
                  <td class="center-align"><b>{{ level.level }}</b></td>
                  <td class="center-align">+{{ level.prof_bonus }}</td>
                  <td class="left-align">
                    <a v-if="level.features.length === 0">-</a>
                    <a v-for="(feature, index) in level.features" :key="feature" :href="'#'+feature.index">
                      {{ feature.name }}<span v-if="index < level.features.length - 1">, </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2>Class abilities</h2>
          <hr>
          <label>As a {{ classe.name.toLowerCase() }}, you benefit from the following class abilities.</label>
          <h3 class="mt-4">Health points</h3>
          <hr>
          <p><b>Hit dice : </b>1d{{ classe.hit_die }} by {{ classe.name.toLowerCase() }} level</p>
          <p><b>Hit points at level 1 : </b>{{ classe.hit_die }} + your Constitution modifier</p>
          <p><b>Hit points at higher levels : </b>1d{{ classe.hit_die }} (or {{ classe.hit_die / 2 + 1}}) + your Constitution modifier after level 1</p>
          <h3 class="mt-4">Masteries</h3>
          <hr>
          <p>
            <b>Armors : </b>
            <a v-if="classe.proficiencies.filter(p => p.type == 'ARMOR').length === 0">none</a>
            <a v-for="armor in classe.proficiencies.filter(p => p.type == 'ARMOR')" :key="armor">{{ armor.name }}, </a>
          </p>
          <p>
            <b>Weapons : </b>
            <a v-if="classe.proficiencies.filter(p => p.type == 'WEAPONS').length === 0">none</a>
            <a v-for="weapon in classe.proficiencies.filter(p => p.type == 'WEAPONS')" :key="weapon">{{ weapon.name }}, </a>
          </p>
          <p>
            <b>Tools : </b>
            <a v-if="classe.proficiencies.filter(p => p.type == 'ARTISANS_TOOLS').length === 0">none</a>
            <a v-for="tool in classe.proficiencies.filter(p => p.type == 'ARTISANS_TOOLS')" :key="tool">{{ tool.name }}, </a>
          </p>
          <p>
            <b>Saving throw : </b>
            <a v-for="savingThrow in classe.saving_throws" :key="savingThrow">{{ savingThrow.full_name }}, </a>
          </p>
          <p><b>Proficiencies : </b>{{ classe.proficiency_choices[0].desc }}</p>

          <h3 class="mt-4">Equipment</h3>
          <hr>
          <p>You start with the following equipment, in addition to the equipment granted by your history :</p>
          <ul>
            <li v-for="equipment in classe.starting_equipment" :key="equipment">{{ equipment.quantity }} {{ equipment.equipment.name.toLowerCase() }}</li>
            <li v-for="equipmentOption in classe.starting_equipment_options" :key="equipmentOption">{{ equipmentOption.desc }}</li>
          </ul>

          <div v-for="feature in uniqueFeatures().filter(f =>(f.subclass == null && f.parent == null))" :key="feature">
            <h3 class="mt-4" :id="feature.index">{{ feature.name }}</h3>
            <hr>
            <p v-for="desc in feature.desc" :key="desc">{{ desc }}</p>
            <div v-for="subFeature in uniqueFeatures().filter(f =>(f.parent != null && f.parent.index === feature.index))" :key="subFeature">
              <h4 class="mt-4">{{ subFeature.name }}</h4>
              <p v-for="desc in subFeature.desc" :key="desc">{{ desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {getClassDescription, getClassLevels} from "@/services/dndofficial/endpoints/classes";

export default {
    data() {
      return {
        isLoading: false,
        classe: null,
        features: [],
        levels: []
      };
    },
    watch: {
      classIndex() {
        this.getClassDescription()
        this.getClassLevels()
      }
    },
    computed: {
      classIndex() {
        return this.$route.params.classIndex;
      }
    },
    mounted: async function(){
      await this.getClassDescription()
      await this.getClassLevels()
    },
    methods: {
      async getClassDescription() {
        try {
          this.isLoading = true
          const response = await getClassDescription(this.classIndex)
          this.classe = response.data.class
          this.features = response.data.features
        } catch (error) {
          this.classe = null
          this.features = []
        } finally {
          this.isLoading = false
        }
      },
      async getClassLevels() {
        this.isLoading = true
        try {
          const response = await getClassLevels(this.classIndex)
          this.levels = response.data.levels.filter(level => level.subclass == null)
        } catch (error) {
          this.levels = []
        } finally {
          this.isLoading = false
        }
      },
      getImagePath(name) {
        try {
          return require(`@/assets/library/classes/${name}.svg`);
        } catch (error) {
          console.error('Failed to load the image:', error);
          return ''; 
        }
      },
      uniqueFeatures() {
        let uniqueFeatures = {};
        return this.features.filter(item => {
          if (!uniqueFeatures[item.name]) {
            uniqueFeatures[item.name] = true;
            return true;
          }
          return false;
        });
      }
    }
  };
</script>
  
<style scoped>

  #header {
    text-align: center;
  }

  #header img {
    height: 400px;
  }

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  h4 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin: 2px 0;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .table-wrapper{
    margin: 10px 70px 70px;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }

  .fl-table td, .fl-table th {
    padding: 8px;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: left;
  }

  .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }

  .fl-table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }


  .fl-table tr:nth-child(even) {
    background: #F8F8F8;
  }

</style>