<template>
  <div id="library-component">
    <a href="/library" alt="Library">
      <div class="library-card">
        <div class="library-wrapper">
          <img src="@/assets/home/library.jpg" class="cover-image" />
        </div>
        <img src="@/assets/home/library-title.png" class="library-title" />
        <img src="@/assets/home/hover-library.png" class="library-character" />
    </div>
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
  };
</script>

<style scoped>

  * {
    box-sizing: border-box;
  }

  #library-component {    
    position: fixed;
    border-radius: 6%;
    top: 120px;
    text-align: center;
    left: 80px;
    height: 45%;
    height: 30px;
  }

  .library-card {    
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    height: 45%;
    width: 40%;
    border-radius: 6%;
    top: 120px;
    left: 80px;
  }

  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6%;
  }

  .library-wrapper {
    transition: all 0.5s;
    position: absolute;
    border-radius: 6%;
    width: 100%;
    border-radius: 6%;
    width: 100%;
    height: 100%;
  }

  .library-card:hover .library-wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(15deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  }

  .library-wrapper::before,
  .library-wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    border-radius: 6%;
    left: 0;
  }
  .library-wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .library-wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
    border-radius: 6%;
  }

  .library-card:hover .library-wrapper::before,
  .library-wrapper::after {
    opacity: 1;
    border-radius: 6%;
  }

  .library-card:hover .library-wrapper::after {
    height: 100px;
    border-radius: 6%;
  }

  .library-title {
    width: 40%;
    transform: translate3d(0%, -0px, 50px); 
    transition: transform 0.5s;
  }

  .library-card:hover .library-title {
    transform: translate3d(1%, -30px, 50px);
  }

  .library-character {
    width: 60%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
  }

  .library-card:hover .library-character {
    opacity: 1;
    transform: translate3d(0%, -40%, 20px);
  }
</style>