<template>
  <div class="popup-overlay">
    <div class="popup-content">
      <h2>Adding spell "{{ spell.name }}"</h2>

      <form v-if="spellBookList.length > 0" @submit.prevent="addSpellToBook">
        <label for="spell-book-option">Targeted spell book : </label>
        <select id="spell-book-option" v-model="selectedSpellBook" required>
          <option v-for="spellBook in spellBookList" :key="spellBook" :value="spellBook.id">
            {{ spellBook.name }}
          </option>
        </select>

        <div class="d-flex justify-content-center m-4">
          <button type="submit" class="btn btn-outline-success">Add</button>
          <button class="btn btn-outline-danger" @click="cancel">Cancel</button>
        </div>
      </form>

      <form v-else @submit.prevent="navigateToCreateSpellBook">
        <h4>No spell book available</h4>
        <div class="d-flex justify-content-center m-4">
          <button type="submit" class="btn btn-outline-success">Create spell book</button>
          <button class="btn btn-outline-danger" @click="cancel">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {addSpellToBook, getPersonalSpellBooks} from "@/services/dndgate/endpoints/spellbooks";
import AddSpellToBookRequestDto from "@/dto/request/AddSpellToBookRequestDto";

export default {
  data() {
    return {
      spellBookList: [],
      selectedSpellBook: null
    }
  },
  props: {
    spell: null
  },
  mounted: async function(){
    await this.getMySpellBooks()
  },
  methods: {
    async getMySpellBooks() {
      this.isLoading = true
      try {
        this.spellBookList = await getPersonalSpellBooks()
      } finally {
        this.isLoading = false
      }
    },
    async addSpellToBook() {
      this.isLoading = true
      let request = new AddSpellToBookRequestDto(
        this.spell.index
      )
      try {
        await addSpellToBook(this.selectedSpellBook, request)
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Spell added',
        });
        this.$emit('close')
      } finally {
        this.isLoading = false
      }
    },
    navigateToCreateSpellBook() {
      this.$router.push({ name: `library/createSpellBook`});
    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>



</style>