<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <h1>Générateur de map</h1>
        <hr>

        <div class="d-flex justify-content-between p-2 bg-dark align-items-center">
          <button class="btn btn-success" @click="handleGenerateMap">Générer une map</button>

          <div>
            <label for="gridHeightSlider">Hauteur: {{ gridHeight }}</label>
            <input type="range" id="gridHeightSlider" v-model="gridHeight" min="10" max="75" />
          </div>

          <div>
            <label for="gridWidthSlider">Largeur: {{ gridWidth }}</label>
            <input type="range" id="gridWidthSlider" v-model="gridWidth" min="10" max="75" />
          </div>

          <button class="btn btn-primary" @click="toggleFullScreen">Plein écran</button>
        </div>

        <div ref="mapContainer" id="map-container"></div>

        <div v-if="isFullScreen" id="menu" class="d-flex justify-content-between p-2 bg-dark align-items-center">
          <button class="btn btn-success" @click="handleGenerateMap">Générer une map</button>
          <div class=" align-items-center">
            <label for="gridHeightSlider">Hauteur: {{ gridHeight }}</label>
            <input type="range" id="gridHeightSlider" v-model="gridHeight" min="10" max="75" />
          </div>

          <div>
            <label for="gridWidthSlider">Largeur: {{ gridWidth }}</label>
            <input type="range" id="gridWidthSlider" v-model="gridWidth" min="10" max="75" />
          </div>
          <button class="btn btn-danger" @click="resetSceneSize">Quitter</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import * as THREE from 'three';
import {generatePerlinNoise} from "@/utils/perlinNoise";
import {shallowRef} from "vue";
import {OrbitControls} from "three/addons/controls/OrbitControls";
import {generateCube} from "@/views/forge/content/map/CubeGenerator";

export default {
  data() {
    return {
      isLoading: false,
      gridHeight: 50,
      gridWidth: 50, // 50x50 cases
      cellSize: 1,
      valuesList: [
          "stone",
          "dirt",
          "grass",
          "leaves"
      ],
      probabilityList: [
        0.1,
        0.125,
        0.375,
        0.4
      ],
      scene: shallowRef(null),
      camera: shallowRef(null),
      renderer: shallowRef(null),
      controls: shallowRef(null),
      animationId: shallowRef(null),
      cubes: [],
      isFullScreen: false,
      originalContainerSize: { width: null, height: null }
    }
  },
  mounted() {
    this.initThreeJS()
    this.startAnimation()
  },
  methods: {
    handleGenerateMap() {
      if(!this.scene) {
        this.initThree();
      }
      else {
        this.resetSimulation();
      }
      this.generateMap()
      this.startAnimation()
    },
    initThreeJS() {
      // Obtenir les dimensions de la div
      const container = this.$refs.mapContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Sauvegarder la taille initiale
      this.originalContainerSize.width = width;
      this.originalContainerSize.height = height;

      // Setup the scene
      this.scene = new THREE.Scene();

      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(width, height);
      this.renderer.setClearColor(0x123456); // Couleur de l'arrière-plan
      this.$refs.mapContainer.appendChild(this.renderer.domElement);

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.set(0, 50, 0);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      window.addEventListener('resize', this.onWindowResize);
    },
    generateMap() {
      console.log(this.scene)
      // Ajouter la logique pour générer la map ici
      this.cubes.forEach(cube => this.scene.remove(cube));
      this.cubes = [];

      // Generate the Perlin noise data
      const noise = generatePerlinNoise(this.gridWidth, this.gridHeight, this.valuesList, this.probabilityList);

      // Add new cubes to the scene based on the noise data
      for (let y = 0; y < this.gridHeight; y++) {
        for (let x = 0; x < this.gridWidth; x++) {
          const type = noise[y][x];
          const cube = generateCube(type, this.cellSize)
          cube.position.set(x * this.cellSize - (this.gridWidth * this.cellSize) / 2, this.cellSize / 2, y * this.cellSize - (this.gridHeight * this.cellSize) / 2);
          this.scene.add(cube);
          this.cubes.push(cube);
        }
      }
    },
    resetSimulation() {
      this.cubes.forEach(cube => {
        this.scene.remove(cube);
        if (cube.geometry) cube.geometry.dispose();
        if (cube.material) {
          if (Array.isArray(cube.material)) {
            cube.material.forEach(material => {
              if (material.dispose) material.dispose();
            });
          } else {
            if (cube.material.dispose) cube.material.dispose();
          }
        }
        this.scene.children = []
      });
      this.cubes = [];
      this.renderer.dispose();
      if (this.animationId) {
        cancelAnimationFrame(this.animationId);
      }
    },
    startAnimation() {
      const animate = () => {
        this.animationId = requestAnimationFrame(animate);
        this.renderScene();
      };

      animate();
    },
    onWindowResize() {
      const container = this.$refs.mapContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.domElement.style.position = 'absolute';
      this.renderer.domElement.style.top = '0';
      this.renderer.domElement.style.left = '0';
      this.renderer.domElement.style.width = '100%';
      this.renderer.domElement.style.height = '100%';

      this.isFullScreen = true;
      this.renderScene();
    },
    toggleFullScreen() {
      const width = window.innerWidth;
      const height = window.innerHeight;

      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.domElement.style.position = 'absolute';
      this.renderer.domElement.style.top = '0';
      this.renderer.domElement.style.left = '0';
      this.renderer.domElement.style.width = '100%';
      this.renderer.domElement.style.height = '100%';

      this.isFullScreen = true;
      this.renderScene();
    },
    resetSceneSize() {
      const width = this.originalContainerSize.width;
      const height = this.originalContainerSize.height;

      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
      this.renderer.domElement.style.position = 'relative';
      this.renderer.domElement.style.width = `${width}px`;
      this.renderer.domElement.style.height = `${height}px`;

      this.isFullScreen = false;
      this.renderScene();
    },
    renderScene() {
      this.renderer.render(this.scene, this.camera);
    },
  }
}
</script>

<style scoped>

  #map-container {
    border: 4px solid black;
    width: 100%;
    height: 60vh;
    background-color: #f0f0f0;
  }

  h1 {
    color: #4C6477;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  label {
    color: white;
    margin: 0;  }

  #menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

</style>