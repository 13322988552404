<template>
  <loading-spinner :isLoading="isLoading" />

  <div v-if="!isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div class="d-flex justify-content-between mb-4">
          <div class="center-list-label">
            <h3 v-if="this.spellBookList == null || this.spellBookList.length === 0">No spell book</h3>
            <h3 v-else>Your spell books</h3>
          </div>
          <div>
            <button class="btn btn-outline-primary me-2" @click="this.importSpellBook"><font-awesome-icon icon="file-import" /></button>
            <button class="btn btn-outline-success" @click="this.createSpellBook"><font-awesome-icon icon="plus" /> <font-awesome-icon icon="book" /></button>
          </div>
        </div>
        <div id="test" class="card w-100">
          <div class="card-body">
            <ul class="spell-book-list">
              <li v-for="spellBook in this.spellBookList" :key="spellBook">
                <spell-book-item :spellBook=spellBook class ="item-list m-1" @spellBookDeletedEvent="getMySpellBooks"></spell-book-item>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ImportSpellBookPopup v-if="importingSpellBook" @close="closeImportSpellBookPopup"></ImportSpellBookPopup>

</template>

<script>
import {getPersonalSpellBooks} from "@/services/dndgate/endpoints/spellbooks";
import SpellBookItem from './SpellBookItem.vue'
import ImportSpellBookPopup from "@/views/library/content/personal_library/spellbook/ImportSpellBookPopup";
import LoadingSpinner from "@/views/shared/LoadingSpinner";

export default {
  components: { LoadingSpinner, SpellBookItem, ImportSpellBookPopup },
  data() {
    return {
      spellBookList: [],
      isLoading: false,
      importingSpellBook: false,
    };
  },
  emits: ['clicked'],
  mounted: async function(){
    await this.getMySpellBooks()
  },
  methods: {
    async getMySpellBooks() {
      this.isLoading = true
      try {
        this.spellBookList = await getPersonalSpellBooks()
      } finally {
        this.isLoading = false
      }
    },
    async closeImportSpellBookPopup() {
      this.importingSpellBook = false
      await this.getMySpellBooks()
    },
    createSpellBook() {
      this.$router.push({ name: `library/createSpellBook`});
    },
    importSpellBook() {
      this.importingSpellBook = true
    },
  },
};
</script>

<style>
/* Add some styles if needed */
button {
  margin: 10px;
  padding: 5px 10px;
}

#test {
  list-style-type: none;
  overflow-y: auto;
  max-height: 60vh;
}

.spell-book-list {
  list-style-type: none;
  padding-inline-start: 10px;
}
</style>