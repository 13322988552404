import {gql_instance} from "../axios";


const getSpells = async (limit, skip) => {
    const query = {
        query: `
query Spells($limit: Int!, $skip: Int, $order: SpellOrder) {
  spells(limit: $limit, skip: $skip, order: $order) {
    index
    name
    level
    school {
      name
    }
    casting_time
    duration
    concentration
    ritual
    components
  }
}
`,
        variables: {
            limit: limit,
            skip: skip,
            order: {
                by: "NAME"
            },
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting spells: ', error)
        throw error;
    }
}

const getSpellDescription = async (index) => {
    const query = {
        query: `
query Spell($index: String) {
  spell(index: $index) {
    index
    name
    level
    school {
      name
    }
    casting_time
    duration
    concentration
    ritual
    components
    range
    material
    classes {
      name
    }
    desc
    higher_level
  }
}
`,
        variables: {
            index: index,
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting spell description: ', error)
        throw error;
    }
}


export { getSpells, getSpellDescription };