import {gql_instance} from "../axios";


const getMonsters = async (limit, skip) => {
    const query = {
        query: `
query Monsters($limit: Int!, $skip: Int, $order: MonsterOrder) {
  monsters(limit: $limit, skip: $skip, order: $order) {
    index
    name
    challenge_rating
    type
    subtype
    size
  }
}
`,
        variables: {
            limit: limit,
            skip: skip,
            order: {
                by: "NAME"
            },
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting monsters: ', error)
        throw error;
    }
}

const getMonsterDescription = async (index) => {
    const query = {
        query: `
query Monster($index: String) {
  monster(index: $index) {
    index
    name
    desc
    type
    subtype
    size
    alignment
    armor_class {
      value
      type
    }
    hit_points
    hit_points_roll
    speed {
      walk
      swim
      fly
      burrow
      climb
      hover
    }
    strength
    dexterity
    constitution
    intelligence
    wisdom
    charisma
    proficiencies {
      proficiency {
        type
        reference {
          ... on Skill {
            name
          }
          ... on AbilityScore {
            name
          }
        }
      }
      value
    }
    damage_immunities
    condition_immunities {
      name
    }
    damage_resistances
    damage_vulnerabilities
    senses {
      blindsight
      darkvision
      truesight
      tremorsense
      passive_perception
    }
    languages
    challenge_rating
    xp
    special_abilities {
      name
      desc
    }
    actions {
      name
      desc
      attack_bonus
    }
    legendary_actions {
      name
      desc
    }
    reactions {
      name
      desc
    }
  }
}
`,
        variables: {
            index: index,
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting monster description: ', error)
        throw error;
    }
}

export { getMonsters, getMonsterDescription };