<template>
  <div class="popup-overlay">
    <div class="popup-content">
      <h2>Updating spell book</h2>

      <form @submit.prevent="updateSpellBook">

        <input class="form-control m-1" type="text" v-model="name" placeholder="Name" required/>

        <textarea rows="10" class="form-control m-1" v-model="description" placeholder="Description" required></textarea>

        <div class="d-flex justify-content-center m-4">
          <button type="submit" class="btn btn-outline-success">Update</button>
          <button class="btn btn-outline-danger" @click="cancel">Cancel</button>
        </div>
      </form>

    </div>
  </div>
</template>

<script>

import {updateSpellBook} from "@/services/dndgate/endpoints/spellbooks";
import SpellBookCreationRequestDto from "@/dto/request/SpellBookCreationRequestDto";

export default {
  data() {
    return {
      file: null,
      isLoading: false,
      name: null,
      description: null,
    }
  },
  props: {
    spellBook: null
  },
  mounted() {
    this.name = this.spellBook.name
    this.description = this.spellBook.description
  },
  methods: {
    async updateSpellBook() {
      this.isLoading = true
      const spellBookData = new SpellBookCreationRequestDto(
          this.name,
          this.description
      )
      try {
        await updateSpellBook(this.spellBook.id, spellBookData);
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Spell book updated',
        });
        this.$emit('close')
      } catch(error) {
        this.$store.dispatch('showPopup', {
          title: '/!\\ Erreur /!\\',
          message: error.response.data,
          isSuccess: false
        });
      } finally {
        this.isLoading = false
      }

    },
    cancel() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>



</style>