<template>
  <loading-spinner :isLoading="isLoading" />

  <div v-if="allMonsters != null && !isLoading" id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <h2>Bestiary ({{ this.allMonsters.length }} monsters)</h2>
          <hr>

          <label for="spell-per-page-option">Monster per page : </label>
          <select id="spell-per-page-option" v-model="monsterPerPage">
            <option v-for="option in monsterPerPageOption" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>

          <div class="table-wrapper">
            <table class="fl-table">
              <thead>
                <tr>
                  <th class="left-align">Name</th>
                  <th class="center-align">ID</th>
                  <th class="left-align">Type</th>
                  <th class="left-align">Sub-type</th>
                  <th class="left-align">Size</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="monster in paginatedMonsters" :key="monster">
                  <td><a class="name" @click="this.select(monster.index)"><b>{{ monster.name }}</b></a></td>
                  <td>{{ Number.isInteger(monster.challenge_rating) ? monster.challenge_rating : floatToFraction(monster.challenge_rating) }}</td>
                  <td>{{ capitalizeFirstLetter(monster.type) }}</td>
                  <td><p v-if="monster.subtype">{{ capitalizeFirstLetter(monster.subtype) }}</p></td>
                  <td>{{ capitalizeFirstLetter(monster.size) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div id="page-selection">
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
            </button>
            <button class="page-button" v-if="currentPage > 2" @click="changePage(1)">1</button>
            <span v-if="currentPage > 3">...</span>
            <button class="page-button" v-if="currentPage > 1" @click="changePage(currentPage - 1)">{{ currentPage - 1 }}</button>
            <button id="selected-page-button" class="page-button"> {{ currentPage }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">{{ currentPage + 1 }}</button>
            <span v-if="currentPage < totalOfPages-2">...</span>
            <button class="page-button" v-if="currentPage < totalOfPages-1" @click="changePage(totalOfPages)">{{ totalOfPages }}</button>
            <button class="page-button" v-if="currentPage < totalOfPages" @click="changePage(currentPage + 1)">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {getMonsters} from "@/services/dndofficial/endpoints/monsters";
import {capitalizeFirstLetter} from "@/utils/stringutils";
import {floatToFraction} from "@/utils/floatutils.js"
import LoadingSpinner from "@/views/shared/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  data() {
    return {
      monsterPerPage: 10,
      monsterPerPageOption: [
        {text: '5', value: 5},
        {text: '10', value: 10},
        {text: '15', value: 15},
        {text: 'All', value: 0},
      ],
      currentPage: 1,
      currentPageOption: this.setupPagination(),
      isLoading: false,
      allMonsters: [],
      filteredMonsters: [],
    };
  },
  watch: {
    monsterPerPage(newValue) {
      if (newValue === 0)
        this.monsterPerPage = this.allMonsters.length
      this.currentPageOption = this.setupPagination()
      this.currentPage = 1
    },
  },
  computed: {
    paginatedMonsters() {
      const start = (this.currentPage - 1) * this.monsterPerPage;
      const end = start + this.monsterPerPage;
      return this.allMonsters.slice(start, end);
    },
    totalOfPages() {
      return Math.ceil(this.allMonsters.length / this.monsterPerPage);
    }
  },
  mounted: async function(){
    await this.getMonsters()
  },
  methods: {
    setupPagination() {
      let options = [];
      for (let i = 1; i <= this.totalOfPages; i++) {
        options.push({ text: `Page ${i}`, value: i });
      }
      return options;
    },
    changePage(page) {
      this.currentPage = page;
    },
    async getMonsters() {
      this.isLoading = true
      try {
        const response = await getMonsters(0,0)
        this.allMonsters = response.data.monsters
        this.filteredMonsters = this.allMonsters
      } catch (error) {
        this.allMonsters = []
        this.filteredMonsters = []
      } finally {
        this.isLoading = false
      }
    },
    select(monsterIndex) {
      this.$router.push({ name: `library/monster`, params: {monsterIndex: monsterIndex}});
    },
    capitalizeFirstLetter(string) {
      return capitalizeFirstLetter(string)
    },
    floatToFraction(x) {
      return floatToFraction(x)
    }
  },
}

</script>

<style scoped>

  #page-selection {
    text-align: center;
  }

  #selected-page-button {
    background-color: #4C6477;
    color: white;
  }

  .name {
    cursor: pointer;
  }

  .page-button {
    background-color: white;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
    margin: 4px;
  }

  .page-button:hover {
    cursor: pointer;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  select {
    width: 30%;
    padding: 8px;
    margin-bottom: 20px;
  }

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  /* Table Styles */
  .table-wrapper{
    margin: 10px 0;
    box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.2 );
  }

  .fl-table {
    border-radius: 5px;
    font-size: 18px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }

  .fl-table td, .fl-table th {
    padding: 15px;
  }

  .fl-table td b {
    border-right: 1px solid #f8f8f8;
    color: #4C6477;
  }

  .fl-table thead th {
    color: #ffffff;
    background: #6F6F6F;
  }

  .center-align {
    text-align: center;
  }

  .left-align {
    text-align: start;
  }

</style>