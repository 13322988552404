<template>
    <a href="/forge" alt="forge" @click="selectOption">
      <div class="forge-card">
        <div class="forge-wrapper">
          <img src="@/assets/home/forge.png" class="cover-image" />
        </div>
        <img src="@/assets/home/forge-title.png" class="forge-title" />
        <img src="@/assets/home/hover-forge.png" class="forge-character" />
    </div>
    </a>
</template>

<script>
  export default {
    data() {
      return {
      };
    },
    components: {
    },
    methods: {
      selectOption() {
        this.$emit('option-selected', 'Forge'); 
      }
    },
  };
</script>

<style scoped>

  #forge-component {    
    position: fixed;
    border-radius: 6%;
    text-align: center;
    height: 23%;
    width: 89%;
    top: 65%;
    left: 80px;
  }

  .forge-card {    
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: flex-end;
    border-radius: 6%;
    height: 23%;
    width: 89%;
    top: 65%;
    left: 80px;
  }

  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6%;
  }

  .forge-wrapper {
    transition: all 0.5s;
    position: absolute;
    border-radius: 6%;
    width: 100%;
    border-radius: 6%;
    width: 100%;
    height: 100%;
  }

  .forge-card:hover .forge-wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(15deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  }

  .forge-wrapper::before,
  .forge-wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    border-radius: 6%;
    left: 0;
  }
  .forge-wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .forge-wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
      to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
    border-radius: 6%;
  }

  .forge-card:hover .forge-wrapper::before,
  .forge-wrapper::after {
    opacity: 1;
    border-radius: 6%;
  }

  .forge-card:hover .forge-wrapper::after {
    height: 100px;
    border-radius: 6%;
  }

  .forge-title {
    width: 10%;
    transform: translate3d(0%, -0px, 50px); 
    transition: transform 0.5s;
  }

  .forge-card:hover .forge-title {
    transform: translate3d(1%, -30px, 50px);
  }

  .forge-character {
    width: 10%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
  }

  .forge-card:hover .forge-character {
    opacity: 1;
    transform: translate3d(0%, -40%, 20px);
  }
</style>