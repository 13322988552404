<template>

  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div>
          <h1>Fiche de personnage</h1>
          <hr>
          <button @click="downloadPdf" class="downloadButton">Télécharger la fiche</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
    };
  },
  methods: {
    downloadPdf() {
      const link = document.createElement('a');
      link.href = 'https://dndgate-minio.barlords.fr/dndgate/files/DnD5E_Form.pdf';
      link.download = 'DnD5E_Form.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style scoped>

label {
  display: block;
  margin-bottom: 5px;
}

h1 {
  color: #4C6477;
}

h2 {
  color: #4C6477;
  margin-bottom: 0;
}

h3 {
  color: #435A3F;
  margin-bottom: 0;
}

hr {
  margin-top: 2px;
}

p {
  margin-top: 3px;
  margin-bottom: 3px;
}

.downloadButton{
  border-radius: 7px;
  text-align: center;
}

</style>