import {gql_instance} from "../axios";

const getClasses = async () => {
    const query = {
        query: `
query GetClasses {
  classes {
    index
    name
  }
}
`
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting classes: ', error)
        throw error;
    }
}

const getClassDescription = async (index) => {
    const query = {
        query: `
query Class($index: String, $class: StringFilter, $order: FeatureOrder) {
  class(index: $index){
    index
    name
    hit_die
    proficiencies {
      name
      type
    }
    saving_throws {
      name
      full_name
    }
    proficiency_choices {
      desc
    }
    starting_equipment {
      quantity
      equipment {
        name
      }
    }
    starting_equipment_options {
      desc
    }
  }
  features(class: $class, order: $order) {
    index
    level
    name
    parent {
      index
    }
    subclass {
      index
    }
    desc
  }
}
`,
        variables: {
            index: index,
            class: index,
            order: {
                by: "LEVEL"
            }
        }
    };

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting class description: ', error)
        throw error;
    }
}

const getClassLevels = async (index) => {
    const query = {
        query: `
query ClassLevels($order: LevelOrder, $classe: StringFilter) {
  levels(order: $order, class: $classe) {
    level
    prof_bonus
    class {
      name
    }
    subclass {
      name
    }
    features {
      index
      name
      desc
    }
    class_specific {
      ... on BarbarianSpecific {
        rage_count
        rage_damage_bonus
        brutal_critical_dice
      }
      ... on WizardSpecific {
        arcane_recovery_levels
      }
      ... on WarlockSpecific {
        invocations_known
        mystic_arcanum_level_6
        mystic_arcanum_level_7
        mystic_arcanum_level_8
        mystic_arcanum_level_9
      }
      ... on SorcererSpecific {
        sorcery_points
        metamagic_known
        creating_spell_slots {
          sorcery_point_cost
          spell_slot_level
        }
      }
      ... on RogueSpecific {
        sneak_attack {
          dice_count
          dice_value
        }
      }
      ... on RangerSpecific {
        favored_enemies
        favored_terrain
      }
      ... on PaladinSpecific {
        aura_range
      }
      ... on MonkSpecific {
        martial_arts {
          dice_count
          dice_value
        }
        ki_points
        unarmored_movement
      }
      ... on FighterSpecific {
        action_surges
        indomitable_uses
        extra_attacks
      }
      ... on DruidSpecific {
        wild_shape_max_cr
        wild_shape_swim
        wild_shape_fly
      }
      ... on ClericSpecific {
        channel_divinity_charges
        destroy_undead_cr
      }
      ... on BardSpecific {
        bardic_inspiration_die
        song_of_rest_die
        magical_secrets_max_5
        magical_secrets_max_7
        magical_secrets_max_9
      }
    }
  }
}
`,
        variables: {
            classe: index,
            order: {
                by: "LEVEL"
            },
        }
    };

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting class levels: ', error)
        throw error;
    }
}


export { getClasses, getClassDescription, getClassLevels };