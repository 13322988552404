<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <button class="close-button" @click="close">&times;</button>
      <div class="header">
        <h2>{{ adventure.name }}</h2>
        <span class="player-count">{{ adventure.minPlayer }}/{{ adventure.maxPlayer }}</span>
      </div>
      <p>{{ adventure.description }}</p>
      <button class="join-button" @click="joinAdventure">Rejoindre</button>
      <p v-if="message" :class="messageClass">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import {joinAdventure} from "@/services/dndgate/endpoints/adventures";

export default {
  data() {
    return {
      isLoading: false,
      message: '',
      messageClass: '',
    };
  },
  props: {
    visible: Boolean,
    adventure: Object,
  },
  methods: {
    async joinAdventure() {
      this.isLoading = true
      try {
        await joinAdventure(this.adventure.id);
        this.message = 'Aventure rejoint';
        this.messageClass = 'success-message';
        setTimeout(() => {
          this.$emit('adventure-joined');
          this.$emit('close');
        }, 2500);
      } catch (error) {
        this.message = 'Problème lors de l\'enregistrement, veuillez réessayer.';
        this.messageClass = 'error-message';
      } finally {
        this.isLoading = false
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.player-count {
  font-size: 1rem;
  font-weight: bold;
}

.join-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 8%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}
</style>