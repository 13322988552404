<template>
  <router-view></router-view>
  <InfoPopup/>
  <TemporaryPopup/>
</template>


<script>
import InfoPopup from "@/views/shared/InfoPopup";
import TemporaryPopup from "@/views/shared/TemporaryPopup";

export default {
  name: 'App',
  components: {InfoPopup, TemporaryPopup},
}
</script>


<style>
  router-view{
    padding: 0;
    margin: 0;
    top : 0;
    left : 0;
    right: 0;
    bottom: 0;
  }

  body {
    font-family: 'Encode Sans Condensed', sans-serif;
    letter-spacing: .03em;
    color: #212121;
    margin: 0;
    padding: 0;
  }

</style>
