
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from './views/home/HomeView.vue';
import ForgeView from './views/forge/ForgeView.vue';
import LibraryView from './views/library/LibraryView.vue';
import TavernView from './views/tavern/TavernView.vue'
import RaceContent from "@/views/library/content/player/RaceContent";
import ClassContent from "@/views/library/content/player/ClassContent";
import BackgroundContent from "@/views/library/content/player/BackgroundContent";
import RuleContent from "@/views/library/content/rule/RuleContent";
import PersonalityContent from "@/views/library/content/player/PersonalityContent";
import SpellListContent from "@/views/library/content/player/SpellListContent";
import SpellContent from "@/views/library/content/player/SpellContent";
import MonsterListContent from "@/views/library/content/master/MonsterListContent";
import MonsterContent from "@/views/library/content/master/MonsterContent";
import SpellBookListContent from "@/views/library/content/personal_library/spellbook/SpellBookListContent";
import SpellBookContent from "@/views/library/content/personal_library/spellbook/SpellBookContent";
import CreateSpellBook from "@/views/library/content/personal_library/spellbook/CreateSpellBook";
import AbilitiesCalculator from "@/views/forge/content/character/AbilitiesCalculator";
import UnimplementedFunctionality from "@/views/shared/UnimplementedFunctionality";
import AdventuresContent from "@/views/tavern/content/adventure/AdventuresContent";
import MyAdventuresContent from "@/views/tavern/content/adventure/MyAdventuresContent";
import CreationAdventureContent from "@/views/tavern/content/adventure/CreationAdventureContent";
import AdventureContent from "@/views/tavern/content/adventure/AdventureContent";
import DiceRoller from "@/views/forge/content/DiceRoller";
import VideoCall from "@/views/tavern/content/adventure/video/VideoCall";
import GameCharacterList from "@/views/tavern/content/adventure/character/GameCharacterList";
import CreateGameCharacter from "@/views/tavern/content/adventure/character/CreateGameCharacter";
import UpdateGameCharacter from "@/views/tavern/content/adventure/character/UpdateGameCharacter";
import GameCharacterView from "@/views/tavern/content/adventure/character/GameCharacterView";
import HistoricalNoteList from "@/views/tavern/content/adventure/historicalnote/HistoricalNoteList";
import CreateHistoricalNote from "@/views/tavern/content/adventure/historicalnote/CreateHistoricalNote";
import UpdateHistoricalNote from "@/views/tavern/content/adventure/historicalnote/UpdateHistoricalNote";
import AdventureNoteList from "@/views/tavern/content/adventure/adventurenote/AdventureNoteList";
import CreateAdventureNote from "@/views/tavern/content/adventure/adventurenote/CreateAdventureNote";
import UpdateAdventureNote from "@/views/tavern/content/adventure/adventurenote/UpdateAdventureNote";
import CharacterSheet from "@/views/forge/content/character/CharacterSheet";
import MyAdventuresFinishedContent from "@/views/tavern/content/adventure/MyAdventuresFinishedContent";
import CalendarContent from "@/views/tavern/content/adventure/CalendarContent";
import ViewCalendarContent from "@/views/tavern/content/adventure/ViewCalendarContent";
import UpdateAdventureContent from "@/views/tavern/content/adventure/UpdateAdventureContent";
import ShowCalendarVote from "@/views/tavern/content/adventure/ShowCalendarVote";
import MapGenerator from "@/views/forge/content/map/MapGenerator";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'DnD Gate' }
  },
  {
    path: '/forge',
    name: 'forge',
    component: ForgeView,
    meta: { title: 'DnD Gate | Forge' },
    children: [
      {
        path: 'abilitiesCalculator',
        name:'forge/abilitiesCalculator',
        component: AbilitiesCalculator
      },
      {
        path: 'diceRoller',
        name:'forge/diceRoller',
        component: DiceRoller
      },
      {
        path: 'mapGenerator',
        name:'forge/mapGenerator',
        component: MapGenerator
      },
      {
        path: 'characterSheet',
        name:'forge/characterSheet',
        component: CharacterSheet
      },
    ]
  },
  {
    path: '/library',
    name: 'library',
    component: LibraryView,
    meta: { title: 'DnD Gate | Bibliothèque' },
    children: [
      {
        path: 'race/:raceIndex',
        name: 'library/race',
        component: RaceContent
      },
      {
        path: 'class/:classIndex',
        name: 'library/class',
        component: ClassContent
      },
      {
        path: 'background/:backgroundIndex',
        name: 'library/background',
        component: BackgroundContent
      },
      {
        path: 'rule/:ruleIndex',
        name: 'library/rule',
        component: RuleContent
      },
      {
        path: 'personality',
        name: 'library/personality',
        component: PersonalityContent
      },
      {
        path: 'spellList',
        name: 'library/spellList',
        component: SpellListContent
      },
      {
        path: 'spell/:spellIndex',
        name: 'library/spell',
        component: SpellContent
      },
      {
        path: 'monsterList',
        name: 'library/monsterList',
        component: MonsterListContent
      },
      {
        path: 'monster/:monsterIndex',
        name: 'library/monster',
        component: MonsterContent
      },
      {
        path: 'spellBookList',
        name: 'library/spellBookList',
        component: SpellBookListContent
      },
      {
        path: 'spellBook/:spellBookId',
        name: 'library/spellBook',
        component: SpellBookContent
      },
      {
        path: 'createSpellBook',
        name: 'library/createSpellBook',
        component: CreateSpellBook
      },
      {
        path: 'bestiaryList',
        name: 'library/bestiaryList',
        component: UnimplementedFunctionality
      },
      {
        path: 'bestiary/:bestiaryId',
        name: 'library/bestiary',
        component: UnimplementedFunctionality
      },
      {
        path: 'createBestiary',
        name: 'library/createBestiary',
        component: UnimplementedFunctionality
      },
      {
        path: 'artefactList',
        name: 'library/artefactList',
        component: UnimplementedFunctionality
      },
      {
        path: 'artefact/:artefactId',
        name: 'library/artefact',
        component: UnimplementedFunctionality
      },
      {
        path: 'createArtefact',
        name: 'library/createArtefact',
        component: UnimplementedFunctionality
      },
    ]
  },
  {
    path: '/tavern',
    component: TavernView,
    meta: { title: 'DnD Gate | Taverne' },
    children: [
      {
        path: 'adventures',
        name: 'tavern/adventures',
        component: AdventuresContent
      },
      {
        path: 'myAdventures',
        name: 'tavern/myAdventures',
        component: MyAdventuresContent
      },
      {
        path: 'myAdventuresFinished',
        name: 'tavern/myAdventuresFinished',
        component: MyAdventuresFinishedContent
      },
      {
        path: 'createAdventure',
        name: 'tavern/createAdventure',
        component: CreationAdventureContent
      },
      {
        path: 'adventure/:adventureId/updateAdventure',
        name: 'tavern/updateAdventureContent',
        component: UpdateAdventureContent,
        props: true
      },
      {
        path: 'adventure/:adventureId',
        name: 'tavern/adventure',
        component: AdventureContent,
        children: [
          {
            path: 'gameCharacterList',
            name: 'tavern/adventure/gameCharacterList',
            component: GameCharacterList
          },
          {
            path: 'createGameCharacter',
            name: 'tavern/adventure/createGameCharacter',
            component: CreateGameCharacter
          },
          {
            path: 'updateGameCharacter/:gameCharacterId',
            name: 'tavern/adventure/updateGameCharacter',
            component: UpdateGameCharacter
          },
          {
            path: 'gameCharacterView/:gameCharacterId',
            name: 'tavern/adventure/gameCharacterView',
            component: GameCharacterView
          },
          {
            path: 'historicalNoteList',
            name: 'tavern/adventure/historicalNoteList',
            component: HistoricalNoteList
          },
          {
            path: 'createHistoricalNote',
            name: 'tavern/adventure/createHistoricalNote',
            component: CreateHistoricalNote
          },
          {
            path: 'updateHistoricalNote/:historicalNoteId',
            name: 'tavern/adventure/updateHistoricalNote',
            component: UpdateHistoricalNote
          },
          {
            path: 'historicalNoteList',
            name: 'tavern/adventure/adventureNoteList',
            component: AdventureNoteList
          },
          {
            path: 'createHistoricalNote',
            name: 'tavern/adventure/createAdventureNote',
            component: CreateAdventureNote
          },
          {
            path: 'updateHistoricalNote/:adventureNoteId',
            name: 'tavern/adventure/updateAdventureNote',
            component: UpdateAdventureNote
          },
          {
            path: 'calendarContent',
            name: 'tavern/adventure/calendarContent',
            component: CalendarContent,
            props: true
          },
          {
            path: 'ViewCalendarContent',
            name: 'tavern/adventure/viewCalendarContent',
            component: ViewCalendarContent
          },
          {
            path: 'ShowCalendarVote',
            name: 'tavern/adventure/showCalendarVote',
            component: ShowCalendarVote
          }
        ]
      },
    ]
  },
  {
    path: '/videoCall/:adventureId',
    name: 'VideoCall',
    component: VideoCall,
    meta: { title: 'DnD Gate | Visio' },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || 'DnD Gate';
});

export default router

