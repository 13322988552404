import {gql_instance} from "../axios";

const getRaces = async () => {
    const query = {
        query: `
query GetRaces {
  races {
    index
    name
  }
}
`
}

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting races: ', error)
        throw error;
    }
}

const getRacesWithAbilities = async () => {
    const query = {
        query: `
query Races($order: RaceOrder) {
  races(order: $order) {
    name
    ability_bonuses {
      ability_score {
        index
        name
        full_name
      }
      bonus
    }
    ability_bonus_options {
      choose
      from {
        options {
          ability_score {
            index
            name
            full_name
          }
          bonus
        }
      }
    }
    subraces {
      name
      ability_bonuses {
        ability_score {
          index
          name
          full_name
        }
        bonus
      }
    }
  }
}        
`
}
    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting races: ', error)
        throw error;
    }
}

const getRaceDescription = async (index) => {
    const query = {
        query: `
query GetRaceDescriptionByIndex($index: String) {
  race(index: $index) {
    index
    name
    ability_bonuses {
      bonus
      ability_score {
        full_name
      }
    }
    ability_bonus_options {
      choose
      from {
        option_set_type
      }
      type
    }
    age
    alignment
    size_description
    speed
    traits {
      desc
      name
    }
    language_desc
    subraces {
      name
      desc
      ability_bonuses {
        bonus
        ability_score {
          full_name
        }
      }
      racial_traits {
        name
        desc
      }
    }
  }
}
`,
        variables: {
            index: index
        }
};

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting race description: ', error)
        throw error;
    }
}

export { getRaces, getRacesWithAbilities, getRaceDescription };