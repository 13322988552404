<template>
  <div id="menu-content">
    <ul id="menu-list">
      <li>
        <for-player-category :isVisible="this.isVisible.forPlayer" @clicked="toggleCategory"/>
      </li>
      <li>
        <for-game-master-category :isVisible="this.isVisible.forGameMaster" @clicked="toggleCategory"/>
      </li>
      <li>
        <rules-category :isVisible="this.isVisible.rules" @clicked="toggleCategory"/>
      </li>
      <li>
        <personal-library-category v-if="isAuthenticated" :isVisible="this.isVisible.personalLibrary" @clicked="toggleCategory"/>
      </li>
    </ul>
  </div>
</template>
  
<script>
import ForPlayerCategory from "@/views/library/menu/category/player/ForPlayerCategory";
import RulesCategory from "@/views/library/menu/category/rules/RulesCategory";
import ForGameMasterCategory from "@/views/library/menu/category/master/ForGameMasterCategory";
import PersonalLibraryCategory from "@/views/library/menu/category/personal_library/PersonalLibraryCategory";
import {mapState} from "vuex";

  export default {
    components: { ForGameMasterCategory, ForPlayerCategory, RulesCategory, PersonalLibraryCategory },
    data() {
      return {
        isLoading: false,
        isVisible: {
          forPlayer: false,
          forGameMaster: false,
          rules: false,
          personalLibrary: false,
        },
      };
    },
    computed: {
      ...mapState(['isAuthenticated'])
    },
    methods: {
      toggleCategory(category) {
        event.stopPropagation();
        for (let visibility in this.isVisible) {
          if (visibility.toString() !== category.toString()) {
            this.isVisible[visibility] = false
          }
        }
        this.isVisible[category] = !this.isVisible[category];
      },
    },
  };
</script>
  
<style scoped>

</style>