<template>
  <div class="page">
    <header tabindex="0">
      <div v-if="!isHome()" id="logo-content">
        <img id="dnd-logo" src="@/assets/headerBar/dnd-logo.png"  alt="D&D logo">
      </div>
      <div id="navigation-content" class="d-flex justify-content-between align-items-center ms-3 me-3">
        <a class="centered" href="/">
          <font-awesome-icon icon="home" class="header-button"/>
        </a>

        <span class="header-bar-title"><b>{{ title }}</b></span>

        <a class="centered" v-if="isAuthenticated" @click="handleLogout" >
          <font-awesome-icon icon="sign-out-alt" class="header-button"/>
        </a>
        <a class="centered" v-if="!isAuthenticated" @click="handleLogin" >
          <font-awesome-icon icon="user" class="header-button"/>
        </a>
      </div>
    </header>

  </div>
</template>

<script>

import {mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { FontAwesomeIcon },
  props: {
    title: null,
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.isAuthenticated
    })
  },
  methods: {
    isHome() {
      return this.$route.path === '/'
    },
    handleLogout() {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      this.$store.dispatch('showTemporaryPopup', {
        message: 'Déconnecté',
      });
      this.$store.dispatch('updateAuthentication', false);
      this.$emit('logout');
    },
    handleLogin() {
      this.$emit('login');
    }
  }
};
</script>


<style scoped>

#logo-content {
  width: 250px;
  height: 70px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(255, 0, 0, 0.5);
}

#dnd-logo {
  max-width: 250px;
  max-height: 60px;
}

#navigation-content {
  flex: 1;
}

.header-bar-title {
  text-align: center;
  margin: 0 40px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: var(--darkblack-color);
  color: white;
  font-size: 45px;
  top: 0;
  left: 0;
}

.header-button {
  color: white;
  font-size: 30px;
  margin: 5px;
  justify-content: center;
}

.header-button:hover {
  color: var(--smokeblue-color);
  cursor: pointer;
}

</style>