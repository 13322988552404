<template>
  <transition name="slide-fade">
    <div class="temporary-popup" v-if="temporaryPopup.visible">
      <p class="popup-message">{{ temporaryPopup.message }}</p>
    </div>
  </transition>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      temporaryPopup: state => state.temporaryPopup
    })
  },
}
</script>

<style scoped>
.temporary-popup {
  position: fixed;
  top: 80px;
  right: 10px;
  background: whitesmoke;
  border: 5px solid limegreen;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-message {
  margin: 0;
  color: black;
}


/* Animation */
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
