<template>
  <div class="col-12 d-flex justify-content-evenly">
    <div class="col-10 d-flex justify-content-evenly">
      <div class="card w-100">
        <div class="card-body d-flex">
          <div class="d-flex picture-container">
            <img :src="this.getPicture(this.character.picturePath)" class="item-picture" alt="Character picture"/>
          </div>
          <div class="ms-4 h-100 w-100 d-flex flex-column justify-content-between">
            <ul v-if="this.character != null" class="p-0 mb-2">
              <li> <h3>{{ this.character.name }}</h3> </li>
              <li> {{ this.character.race }} - {{ this.character.martialClass }}</li>
              <li> <i>Propriétaire : {{ this.character.ownerPseudo }}</i></li>
            </ul>
            <div class="d-flex justify-content-between">
              <button class="btn btn-outline-primary" @click="viewCharacter"><font-awesome-icon icon="eye" /></button>
              <div v-if="canModifyCharacter()" class="d-flex justify-content-between">
                <button class="btn btn-outline-primary" @click="updateCharacter"><font-awesome-icon icon="edit" /></button>
                <button class="btn btn-outline-danger ms-1" @click="deleteCharacter"><font-awesome-icon icon="trash" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteGameCharacter} from "@/services/dndgate/endpoints/game-characters";
import {MinioClass} from "@/services/dndgate/minio";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    character: {
      type: Object,
      required: true
    },
    imMaster: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    canModifyCharacter() {
      return this.character.ownerId === localStorage.getItem('id')
    },
    viewCharacter() {
      this.$router.push({
        name: 'tavern/adventure/gameCharacterView',
        params: {gameCharacterId: this.character.id}
      })
    },
    updateCharacter() {
      this.$router.push({
        name: 'tavern/adventure/updateGameCharacter',
        params: {gameCharacterId: this.character.id}
      })
    },
    async deleteCharacter() {
      this.isLoading = true
      try {
        await deleteGameCharacter(this.character.id)
        this.$emit('deleted')
      } finally {
        this.isLoading = false
      }
    },
    getPicture(url) {
      return MinioClass.getImageUrl(url)
    }
  }
}
</script>

<style scoped>

ul {
  list-style-type: none;
}

</style>