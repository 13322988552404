<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <form @submit.prevent="updateAdventure">
          <button @click="goBack" class="btn-back">Retour</button>
          <h2 class="create-label" aria-hidden="true">Modifier l'aventure</h2>
          <label for="name">Nom</label>
          <input class="add-input" type="text" id="name" v-model="name" placeholder="Nom" required value=adventure.name>
          <label for="description">Description</label>
          <input class="add-input" type="text" id="description" v-model="description" placeholder="Description" required>

          <div class="div-player">
            <label for="maxPlayer">Nombre de joueur maximum : </label>
            <input class="add-input num-input" type="number" id="maxPlayer" v-model="maxPlayer" placeholder="" min="1" required>
          </div>

          <div class="div-player">
            <label for="visibility">Visibilité</label>
            <select class="add-input select-input" id="visibility" v-model="visibility">
              <option value="PRIVATE">Privé</option>
              <option value="PUBLIC">Public</option>
            </select>
          </div>

          <div class="div-player">
            <label for="status">Status</label>
            <select class="add-input select-input" id="status" v-model="status">
              <option value="NOT_STARTED">Non commencé</option>
              <option value="ONGOING">En cours</option>
              <option value="ABORT">Abandonné</option>
              <option value="FINISH">Terminé</option>
            </select>
          </div>

          <div class="div-player div-inline">
            <div class="div-location">
              <label for="postalCode">Code postal</label>
              <select class="add-input location-input" id="postalCode" v-model="selectedPostalCode" @change="updateCities">
                <option v-for="code in postalCodes" :key="code" :value="code">{{ code }}</option>
              </select>
            </div>

            <div class="div-location">
              <label for="city" v-if="cities.length > 1">Ville</label>
              <select class="add-input location-input" id="city" v-model="selectedCity" v-if="cities.length > 1">
                <option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
              </select>
              <label for="city" v-if="cities.length === 1">Ville</label>
              <input class="add-input location-input" type="text" id="city" v-if="cities.length === 1" v-model="selectedCity" readonly>
            </div>
          </div>

          <button class="add-button" type="submit">Modifier</button>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAdventureDetails
} from "@/services/dndgate/endpoints/adventures";
import postalCodeData from "@/data/postalCodeData.json";
import {updateAdventure} from "@/services/dndgate/endpoints/adventures";
import AdventureUpdateRequestDto from "@/dto/request/AdventureUpdateResquestDto";

export default {
  data() {
    return {
      isLoading: false,
      adventure: null,
      name: '',
      description: '',
      maxPlayer: null,
      visibility: 'PRIVATE',
      selectedPostalCode: '',
      selectedCity: '',
      cities: [],
      postalCodes: Object.keys(postalCodeData),
    };
  },
  watch: {
    adventureId() {
      this.getAdventureDetails()
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  mounted: async function () {
    await this.getAdventureDetails();
    this.name = this.adventure.name
    this.description = this.adventure.description
    this.minPlayer = this.adventure.minPlayer
    this.maxPlayer = this.adventure.maxPlayer
    this.visibility = this.adventure.visibility
    this.status = this.adventure.status
    this.selectedPostalCode = this.adventure.postalCode
    this.selectedCity = this.adventure.city
  },
  methods: {
    updateCities() {
      this.cities = postalCodeData[this.selectedPostalCode] || [];
      this.selectedCity = this.cities.length === 1 ? this.cities[0] : '';
    },
    goBack() {
      this.$router.back();
    },
    showSuccessMessage(message) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = '';
      }, 1500);
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      setTimeout(() => {
        this.errorMessage = '';
      }, 3000);
    },
    isMe(id) {
      return id === localStorage.getItem('id')
    },
    async getAdventureDetails() {
      this.isLoading = true
      try {
        this.adventure = await getAdventureDetails(this.adventureId)
      } finally {
        this.isLoading = false
      }
    },
    async updateAdventure() {
      this.isLoading = true
      const adventureData = new AdventureUpdateRequestDto(
          this.name,
          this.description,
          this.maxPlayer,
          this.visibility,
          this.status,
          this.selectedPostalCode,
          this.selectedCity
      )

      try {
        const adventure = await updateAdventure(this.adventure.id, adventureData);
        this.$store.dispatch('showTemporaryPopup', {
          message: 'Aventure modifier',
        });
        this.$router.push({name: 'tavern/adventure', params:{adventureId: adventure.id}})
      } catch (error) {
        this.$store.dispatch('showPopup', {
          title: '/!\\ Erreur /!\\',
          message: error.response.data,
          isSuccess: false
        });
      } finally {
        this.isLoading = false
      }
    }
  },
};
</script>

<style scoped>

.adventure-participants li {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

hr {
  margin-top: 2px;
}

.create-label{
  text-align: center;
}
.add-button{
  margin-top: 40px;
}

.form-adventure label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.add-input, .num-input, .add-input select {
  width: 100%;
  margin-left: 5px;
  padding: 8px 5px 8px 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 10%;
  margin: 5px;
}

.div-inline {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.add-button:hover {
  background-color: #45a049;
}

.num-input{
  width: 50px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.select-input{
  width: 100px;
}

.div-player{
  margin-top: 10px;
}

.location-input{
  width: 30% ;
}

.div-location{
  width: 50%;
}

.div-inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-location {
  flex: 1;
  margin-right: 10px;
}

.div-location:last-child {
  margin-right: 0;
}

.location-input {
  width: 100%;
}

</style>