import {gql_instance} from "../axios";

const getPersonality = async () => {
    const query = {
        query: `
query Personality($orderDirection: OrderByDirection, $order: LanguageOrder) {
  alignments(order_direction: $orderDirection) {
    name
    abbreviation
    desc
  }
  languages(order: $order) {
    name
    script
    type
    typical_speakers
  }
}
`,
        variables: {
            orderDirection: "DESCENDING",
            order: {
                by: "TYPE"
            }
        }
};

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting personality: ', error)
        throw error;
    }
}

export { getPersonality };