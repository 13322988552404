<template>
  <div v-if="this.adventure !== null" class="card">
    <div class="card-body">
      <div class=" ml-5">
        <div class="calendarTab-popup">
          <div class="title-bar">
            <button v-if="!isResponding" @click="addResponseRow" class="response-button">Répondre</button>
            <h3>{{ tabTitle }}</h3>
          </div>
          <table v-if="planningDates.length > 0">
            <thead>
            <tr class="month-line">
              <th></th>
              <th v-for="(month, index) in uniqueMonths" :key="'month-header-' + index" :colspan="daysInMonth(month)"
                  class="month-header">
                {{ month }}
              </th>
            </tr>
            <tr>
              <th></th>
              <th v-for="(date, index) in planningDates" :key="'day-header-' + index" class="day-header">
                <div>{{ dayOfWeek(date) }}</div>
                <div>{{ date[2] }}</div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, userIdx) in disponibilities" :key="'user-row-' + userIdx">
              <td class="data-cell">{{ user.pseudo }}</td>
              <td v-for="(date, dIndex) in planningDates" :key="'data-cell-' + userIdx + '-' + dIndex" class="data-cell"
                  :style="getCellBackgroundColor(user, date)">
              </td>
            </tr>

            <tr v-if="currentUserResponse">
              <td>{{ currentUserResponse.pseudo }}</td>
              <td v-for="(date, dIndex) in planningDates" :key="'response-cell-' + dIndex" class="data-cell"
                  @mouseover="handleMouseOver(date)" @mouseleave="handleMouseLeave(date)">
                <template v-if="shouldShowOptions(date)">
                  <button class="button-choice" @click="setResponse(date, 'yes')">✔️</button>
                  <button class="button-choice" @click="setResponse(date, 'no')">❌</button>
                </template>
                <template v-else>
                  {{ displayResponseIcon(date) }}
                </template>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            <p>Aucune date de proposition disponible.</p>
          </div>
          <div v-if="isResponding" class="save-button-container">
            <button @click="saveResponses" class="save-button">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPlanningByAdventure,
  getPlanningById,
  getDisponibilitiesByPlanningId,
  updateUserDisponibility
} from "@/services/dndgate/endpoints/plannings";
import {getUserById, getMyProfile} from "@/services/dndgate/endpoints/users";

export default {
  data() {
    return {
      tabTitle: "Planning",
      planningId: null,
      planningDates: [],
      monthYear: '',
      disponibilities: [],
      currentUserResponse: null,
      showOptions: {},
      isResponding: false,
      errorLoading: false
    };
  },
  async mounted() {
    await this.fetchPlanning();
  },
  watch: {
    adventureId() {
    }
  },
  computed: {
    adventureId() {
      return this.$route.params.adventureId;
    },
    uniqueMonths() {
      const months = this.planningDates.map(date => this.monthName(date[1]) + ' ' + date[0]);
      return [...new Set(months)].sort((a, b) => new Date(a.split(' ')[1], new Date(0).setMonth(this.monthNumber(a.split(' ')[0]) - 1, 1)) - new Date(b.split(' ')[1], new Date(0).setMonth(this.monthNumber(b.split(' ')[0]) - 1, 1)));
    }
  },
  methods: {
    formatDateString(date) {
      const parts = date.split('-');
      return `${parts[0]}-${parts[1].padStart(2, '0')}-${parts[2].padStart(2, '0')}`;
    },
    cancelResponse() {
      this.isResponding = false;
    },
    saveResponses() {
      const responses = this.currentUserResponse.responses;
      const propositionDates = Object.keys(responses)
          .filter(key => responses[key] === 'yes')
          .map(key => this.formatDateString(key));

      const requestBody = {
        planningId: this.planningId,
        propositionDates: propositionDates
      };

      const headers = {Authorization: `Bearer ${this.getCurrentUserToken()}`};

      updateUserDisponibility(headers, requestBody)
          .then(response => {
            console.log('Disponibility updated successfully:', response);
            this.isResponding = false;
          })
          .catch(error => {
            console.error('Failed to update disponibility:', error);
          });
    },
    async fetchPlanning() {
      try {
        const planning = await getPlanningByAdventure(this.adventureId);
        if (planning) {
          this.planningId = planning.id;
          const details = await getPlanningById(planning.id);
          this.planningDates = details.propositionDates.sort((a, b) => new Date(a[0], a[1] - 1, a[2]) - new Date(b[0], b[1] - 1, b[2]));
          if (this.planningDates.length > 0) {
            const sampleDate = this.planningDates[0];
            this.monthYear = this.monthName(sampleDate[1]) + ' ' + sampleDate[0];
          }
          const disponibilities = await getDisponibilitiesByPlanningId(planning.id);
          await this.fetchUserDetails(disponibilities);
          this.errorLoading = false;
        } else {
          this.errorLoading = true;
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du planning:', error);
        this.errorLoading = true;
      }
    },

    daysInMonth(month) {
      return this.planningDates.filter(date => {
        const fmt = this.monthName(date[1]) + ' ' + date[0];
        return fmt === month;
      }).length;
    },
    async fetchUserDetails(disponibilities) {
      const disponibilitiesWithData = await Promise.all(disponibilities.map(async disponibility => {
        const user = await getUserById(disponibility.userId);
        return {
          ...disponibility,
          pseudo: user.pseudo
        };
      }));
      this.disponibilities = disponibilitiesWithData;
    },
    dayOfWeek(date) {
      const [year, month, day] = date;
      return new Date(year, month - 1, day).toLocaleDateString('fr-FR', {weekday: 'short'});
    },
    monthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);
      return date.toLocaleDateString('fr-FR', {month: 'long'});
    },
    monthNumber(monthName) {
      return new Date(Date.parse(monthName + " 1, 2000")).getMonth() + 1;
    },
    getCellBackgroundColor(user, date) {
      const [year, month, day] = date;
      const availabilityDates = user.disponibilityDates.map(d => d[0] + '-' + d[1] + '-' + d[2]);
      const currentDate = year + '-' + month + '-' + day;
      return {
        backgroundColor: availabilityDates.includes(currentDate) ? '#99D698' : 'rgb(193,63,69,0.7)'
      };
    },
    async addResponseRow() {
      this.isResponding = true;

      try {
        const userProfile = await getMyProfile();
        if (userProfile) {
          this.currentUserResponse = {
            pseudo: userProfile.pseudo,
            responses: this.planningDates.reduce((acc, date) => {
              const dateKey = `${date[0]}-${date[1]}-${date[2]}`;
              acc[dateKey] = '?';
              return acc;
            }, {})
          };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du profil utilisateur:', error);
      }
    },
    handleMouseOver(date) {
      const key = this.getDateKey(date);
      this.showOptions[key] = true;
    },
    handleMouseLeave(date) {
      const key = this.getDateKey(date);
      this.showOptions[key] = false;
    },

    setResponse(date, response) {
      const key = this.getDateKey(date);
      this.currentUserResponse.responses[key] = response;
      this.showOptions[key] = false;
      const validatedDates = Object.keys(this.currentUserResponse.responses)
          .filter(dateKey => this.currentUserResponse.responses[dateKey] === 'yes');
      console.log('Dates validées:', validatedDates);
    },
    shouldShowOptions(date) {
      const key = this.getDateKey(date);
      return !!this.showOptions[key];
    },
    displayResponseIcon(date) {
      const response = this.currentUserResponse.responses[this.getDateKey(date)];
      return response === 'yes' ? '✔️' : response === 'no' ? '❌' : '?';
    },
    getDateKey(date) {
      return date[0] + '-' + date[1] + '-' + date[2];
    },
    getCurrentUserToken() {
      return localStorage.getItem('userToken');
    }
  }
};
</script>

<style scoped>
.response-button, .save-button {
  padding: 10px 20px;
  margin: 10px;
  background-color: rgb(82, 190, 128);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.response-button:hover, .save-button:hover {
  background-color: rgb(82, 190, 128);
}

table {
  width: 100%;
  max-width: 700px;
  border-collapse: collapse;
}

.month-header {
  text-align: center;
  color: #36393b;
  font-size: 16px;
  border: 1px solid #F8F7FF;
}

.day-header {
  background-color: #a5d8ff;
  color: black;
  text-align: center;
  font-size: 14px;
  border: 1px solid #F8F7FF;
  width: 100px;
}

.data-cell {
  text-align: center;
  background-color: white;
  border: 1px solid #DDD;
}

.planning-date h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.planning-date table {
  width: 100%;
  border-collapse: collapse;
}

.planning-date th, .planning-date td {
  border: 1px solid #ccc;
  padding: 8px;
}

.planning-date th {
  background-color: #f0f0f0;
  text-align: left;
}

.calendarTab-popup {
  position: relative;
  background-color: #F2F2F2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.response-button {
  margin-right: 10px;
}

.button-choice {
  width: 35px;
  height: 28px;
  font-size: 10px;
  margin-right: 4px;
}

.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.title-bar h3 {
  margin: 0;
  flex: 1;
  text-align: center;
}

.save-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

</style>