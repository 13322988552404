<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <h1>Lanceur de dés</h1>
        <hr>
        <div id="dice-settings">
          <div>
            <label for="dice-count">Nombre de dés :</label>
            <input type="number" v-model="diceCount" id="dice-count" min="1" />
          </div>
          <div>
            <label for="dice-type">Type de dés :</label>
            <select v-model="selectedDiceType" id="dice-type">
              <option v-for="dice in diceTypes" :key="dice" :value="dice.value">
                {{ dice.label }}
              </option>
            </select>
          </div>
          <button class="btn btn-outline-success" @click="rollDice"><font-awesome-icon icon="dice" /></button>
        </div>
        <div ref="diceContainer" class="dice-container"></div>
      </div>
    </div>
  </div>
</template>

<script>

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import * as CANNON from 'cannon-es';
import { shallowRef } from 'vue';
import {createDice} from "@/models/DiceModel";

export default {
  data() {
    return {
      diceTypes: [
        { label: 'd4', value: 4 },
        { label: 'd6', value: 6 },
        { label: 'd8', value: 8 },
        { label: 'd10', value: 10 },
        { label: 'd12', value: 12 },
        { label: 'd20', value: 20 },
      ],
      diceCount: 1,
      selectedDiceType: 6,
      diceList: [],
      scene: shallowRef(null),
      controls: shallowRef(null),
      camera: shallowRef(null),
      renderer: shallowRef(null),
      world: shallowRef(null),
      animationId: shallowRef(null),
    }
  },
  methods: {
    rollDice() {
      if(!this.scene) {
        this.initThree();
      }
      else {
        this.resetSimulation();
      }
      this.initCannon();
      this.createDice();
      this.startAnimation();
    },
    initThree() {
      this.scene = new THREE.Scene();

      // Obtenir les dimensions de la div
      const container = this.$refs.diceContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Changer la couleur de l'arrière-plan
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(width, height);
      this.renderer.setClearColor(0x123456); // Couleur de l'arrière-plan
      this.$refs.diceContainer.appendChild(this.renderer.domElement);

      this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      this.camera.position.set(0, 20, 0);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(5, 10, 7.5);
      this.scene.add(light);

      const ambientLight = new THREE.AmbientLight(0x404040);
      this.scene.add(ambientLight);

      // Création du sol visuel
      const groundGeometry = new THREE.PlaneGeometry(30, 30);
      const groundMaterial = new THREE.MeshBasicMaterial({ color: 0x654321 }); // Couleur du sol
      this.groundMesh = new THREE.Mesh(groundGeometry, groundMaterial);
      this.groundMesh.rotation.x = -Math.PI / 2;
      this.scene.add(this.groundMesh);
    },
    initCannon() {
      this.world = new CANNON.World();
      this.world.gravity.set(0, -9.82, 0);

      const groundBody = new CANNON.Body({
        mass: 0,
        shape: new CANNON.Plane(),
        material: new CANNON.Material(),
      });
      groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
      this.world.addBody(groundBody);
    },
    createDice() {
      this.diceList = [];
      for (let i = 0; i < this.diceCount; i++) {
        const dice = createDice(this.selectedDiceType);

        // Définir la position initiale
        const position = {
          x: -15,  // Position de départ à gauche
          y: 2 + i,
          z: (Math.random() - 0.5) * 2,
        };
        dice.body.position.set(position.x, position.y, position.z);

        // Vitesse initiale
        const initialVelocity = new CANNON.Vec3(
            Math.random() * 10 + 5,  // Composante vers la droite
            Math.random() * 5,       // Composante verticale aléatoire
            (Math.random() - 0.5) * 5 // Composante aléatoire en profondeur
        );

        // Vitesse angulaire initiale
        const initialAngularVelocity = new CANNON.Vec3(
            (Math.random() - 0.5) * 20,
            (Math.random() - 0.5) * 20,
            (Math.random() - 0.5) * 20
        );

        dice.body.velocity.set(initialVelocity.x, initialVelocity.y, initialVelocity.z);
        dice.body.angularVelocity.set(initialAngularVelocity.x, initialAngularVelocity.y, initialAngularVelocity.z);

        this.world.addBody(dice.body)
        this.scene.add(dice.getObject())
        this.diceList.push(dice);
      }
    },
    resetSimulation() {
      this.diceList.forEach(dice => {
        this.world.removeBody(dice.body);
        this.scene.remove(dice.getObject());
      });
      this.diceList = [];
      cancelAnimationFrame(this.animationId);
    },
    startAnimation() {
      const animate = () => {
        const delta = 1 / 20;
        this.world.step(delta);

        this.diceList.forEach(dice => {
          dice.updateMeshFromBody();
        });

        this.renderer.render(this.scene, this.camera);
        this.animationId = requestAnimationFrame(animate);
      };
      animate();
    },
  },
};
</script>

<style scoped>

#dice-count {
  width: 50px;
}

#dice-type {
  width: 100px;
}

#dice-settings {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dice-container {
  width: 100%;
  height: 60vh;
  background-color: #f0f0f0;
}

h1 {
  color: #4C6477;
}

hr {
  margin-top: 2px;
}

</style>