import axios from "@/services/dndgate/axios";
import {formatDateForInput} from "@/utils/dateutils";

const endpoint = "/adventure-notes"

/**
 * @param {AdventureNoteCreationRequestDto} requestBody
 */
const createAdventureNote = async (requestBody) => {
    try {
        const response = await axios.post(`${endpoint}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while creating adventure note: ', error)
        throw error;
    }
}

const getAdventureNotesByAdventureId = async (adventureId) => {
    try {
        const response = await axios.get(`${endpoint}/getByAdventureId/${adventureId}`);
        response.data.map((adventureNote) => {
            adventureNote.date = formatDateForInput(adventureNote.date)
            return adventureNote
        });
        return response.data.sort((a, b) => b.date.localeCompare(a.date))
    } catch (error) {
        console.error('Error while getting adventure note: ', error)
        throw error;
    }
}

const getAdventureNoteDetails = async (id) => {
    try {
        const response = await axios.get(`${endpoint}/${id}`);
        response.data.date = formatDateForInput(response.data.date)
        return response.data;
    } catch (error) {
        console.error('Error while getting adventure note: ', error)
        throw error;
    }
}

/**
 * @param id
 * @param {AdventureNoteCreationRequestDto} requestBody
 */
const updateAdventureNote = async (id, requestBody) => {
    try {
        const response = await axios.patch(`${endpoint}/${id}`, requestBody);
        return response.data;
    } catch (error) {
        console.error('Error while patching adventure note: ', error)
        throw error;
    }
}

const deleteAdventureNote = async (id) => {
    try {
        const response = await axios.delete(`${endpoint}/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error while deleting adventure note: ', error)
        throw error;
    }
}

export {
    createAdventureNote,
    getAdventureNotesByAdventureId,
    getAdventureNoteDetails,
    updateAdventureNote,
    deleteAdventureNote
}