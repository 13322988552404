<template>
  <div class="card d-flex justify-content-evenly">
    <div class="d-flex justify-content-evenly">
      <div class="w-100">
        <div class="card-body">
          <ul v-if="this.spellBook != null" class="spellbook-details-list">
            <li><h3> {{ this.spellBook.name  }} </h3></li>
            <li v-if="this.spellBook.description"> {{ this.spellBook.description.length > maxLength ? this.spellBook.description.slice(0, maxLength) + "..." : this.spellBook.description }} </li>
            <li><i>Count of spell : {{ this.spellBook.spellList.length }} </i></li>
          </ul>
          <div class="d-flex justify-content-between">
            <button class="btn btn-outline-primary" @click="navigateToSpellBookView"><font-awesome-icon icon="eye" /></button>
            <button class="btn btn-outline-primary" @click="exportSpellBook"><font-awesome-icon icon="file-export" /></button>
            <button class="btn btn-outline-danger" @click="deleteSpellBook"><font-awesome-icon icon="trash" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {deleteSpellBook, exportSpellBook} from '@/services/dndgate/endpoints/spellbooks'

  export default {
    data() {
      return {
        maxLength: 100,
        isLoading: false,
      };
    },
    props: {
      spellBook: null,
    },
    emits: ['spellBookDeletedEvent'],
    methods: {
      navigateToSpellBookView() {
        this.$router.push({ name: `library/spellBook`, params: {spellBookId: this.spellBook.id}});
      },
      async exportSpellBook() {
        this.isLoading = true
        try {
          const response = await exportSpellBook(this.spellBook.id);
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `spellBook-${this.spellBook.name.replace(/\s/g, '_')}.json`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.$store.dispatch('showTemporaryPopup', {
            message: 'Spell book exported',
          });
        } finally {
          this.isLoading = false
        }
      },
      async deleteSpellBook() {
        this.isLoading = true
        try {
          await deleteSpellBook(this.spellBook.id)
          this.$store.dispatch('showTemporaryPopup', {
            message: 'Spell book deleted',
          });
          this.$emit('spellBookDeletedEvent');
        } finally {
          this.isLoading = false
        }
      }
    }
  };
</script>

<style>
/* Add some styles if needed */
button {
  margin: 10px;
  padding: 5px 10px;
}

.spellbook-details-list {
  list-style-type: none;
  padding-inline-start: 0;
}
</style>