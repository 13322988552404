<template>
  <div class="col-12 d-flex justify-content-evenly">
    <div class="col-10 d-flex justify-content-evenly">
      <div class="card w-100">
        <div class="card-body">
          <div v-if="imMaster" class="d-flex justify-content-between m-2">
            <button class="btn btn-outline-primary" @click="updateAdventureNote"><font-awesome-icon icon="edit" /></button>
            <button class="btn btn-outline-danger" @click="deleteAdventureNote"><font-awesome-icon icon="trash" /></button>
          </div>
          <p>{{ this.adventureNote.content }}</p>
          <i>{{ this.adventureNote.date }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteAdventureNote} from "@/services/dndgate/endpoints/adventure-notes";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: { FontAwesomeIcon },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    adventureNote: {
      type: Object,
      required: true
    },
    imMaster: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    updateAdventureNote() {
      this.$router.push({
        name: 'tavern/adventure/updateAdventureNote',
        params: {adventureNoteId: this.adventureNote.id}
      })
    },
    async deleteAdventureNote() {
      this.isLoading = true
      try {
        await deleteAdventureNote(this.adventureNote.id)
        this.$emit('deleted')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>