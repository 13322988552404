<template>
  <div class="menu-title" @click="handleClick">
    Personnage
    <i v-if="!isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i>
    <i v-if="isVisible" aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-up theme--light"></i>
  </div>
  <ul id="sub-menu-list" class="category" v-show="this.isVisible">
    <li>
      <div class="menu-title" @click="select('abilitiesCalculator')">Calculateur de caractéristiques</div>
    </li>
    <li>
      <div class="menu-title" @click="select('characterSheet')">Fiche de personnage</div>
    </li>
    <li>
      <div class="menu-title" @click="redirectToFigurineBuilder">Figurine</div>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    isVisible: Boolean(false)
  },
  emits: [
    'clicked'
  ],
  methods: {
    handleClick() {
      this.$emit('clicked', 'character');
    },
    select(index) {
      this.$router.push({ name: `forge/${index}`});
    },
    redirectToFigurineBuilder() {
      window.location.href = 'https://eldritch-foundry.com/';
    }
  }
}
</script>

<style scoped>


</style>