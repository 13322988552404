<template>
    <div id="my-adventures-content">
      <div class="card-list">
        <CardContent v-for="adventure in adventures" :key="adventure.id" :adventure="adventure" @view-details="viewDetails"></CardContent>
      </div>
    </div>
</template>

<script>
  import CardContent from './CardContent.vue';
  import {
    getAdventuresWhereImMaster,
    getAdventuresWhereImPlayer
  } from "@/services/dndgate/endpoints/adventures";
  import {getUserById} from "@/services/dndgate/endpoints/users";
  
  export default {
    components: { CardContent },
    data() {
      return {
        adventures: [],
        showInfoVisible: false,
        selectedAdventure: null,
      };
    },
    methods: {
      async fetchAdventures() {
        this.isLoading = true
        try {
          const responsePlayer = await getAdventuresWhereImPlayer()
          const responseMaster = await getAdventuresWhereImMaster()
  
          const combinedAdventures = [...responsePlayer, ...responseMaster];

          const filteredAdventure = combinedAdventures.filter((adventure) => {
            return adventure.status.toString() !== 'FINISH'.toString()
          })

          this.adventures = await Promise.all(filteredAdventure.map(async (adventure) => {
            const user = await getUserById(adventure.creatorId);
            return {...adventure, creatorPseudo: user.pseudo};
          }))
        } finally {
          this.isLoading = false
        }
      },
      viewDetails(adventure) {
        this.$emit('view-details', adventure);
        this.$router.push({name: `tavern/adventure`, params: {adventureId: adventure.id}})
      },
    },
    mounted: async function() {
      await this.fetchAdventures();
    },
  };
</script>  

<style scoped>
  .card-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0 5% 5%;
    padding: 20px 5% 5%;
  }
</style>
