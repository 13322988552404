<template>
  <div class="popup-overlay" v-if="popup.visible">
    <div class="popup-content" :class="{'success': popup.isSuccess, 'error': !popup.isSuccess}">
      <h1  class="popup-title">{{ popup.title }}</h1>
      <div>
      </div>
      <div class="popup-message">
        <div class="p-2 pb-0">
          <div v-if="Array.isArray(popup.message)">
            <p v-for="message in popup.message" :key="message">{{message.field}} : {{ message.message }}</p>
          </div>
          <p v-else>{{ popup.message.message }}</p>
        </div>
        <div class="align-items-center">
          <button @click="hidePopup" class="btn btn-danger m-2">Fermer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      popup: state => state.popup
    })
  },
  methods: {
    hidePopup() {
      this.$store.dispatch('hidePopup')
    }
  }
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  border-radius: 10px;
  padding: 1px;
  background: white;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.popup-title {
  border-radius: 10px 10px 0 0;
  background: black;
  color: white;
  margin: 0;
  padding: 5px;
}

.popup-message {
  margin: 0;
  border: 5px solid;
  border-radius: 0 0 10px 10px;
}

.success .popup-message {
  border-color: limegreen;
}

.error .popup-message {
  border-color: red;
}

</style>