<template>
  <HeaderBar :title="this.selectedOption" @login="handleLogin" @logout="handleLogout"></HeaderBar>
  <div id="background-homeview">
    <div id="body-content">
      <library-button></library-button>
      <tavern-button @showLoginPopup="showLoginPopup"></tavern-button>
    </div>
    <div id="forge-button">
      <forge-button></forge-button>
    </div>

    <LoginComponents v-if="showLogin"
                     @close-popup="closeLoginPopup"
                     @userCreateAccountEvent="handleUserCreateAccountEvent"
                     @userConnectedEvent="handleUserConnectedEvent"
    ></LoginComponents>
  </div>
</template>

<script>
  import LibraryButton from "@/views/home/LibraryButton";
  import TavernButton from "@/views/home/TavernButton";
  import ForgeButton from "@/views/home/ForgeButton";
  import LoginComponents from "@/views/login/LoginComponents";
  import HeaderBar from "@/views/shared/HeaderBar";

  export default {
    data() {
      return {
        selectedOption: 'DnD Gate',
        showLogin: false,
        isAuthenticated: !!localStorage.getItem('token'),
      };
    },
    components: {
      LibraryButton,
      TavernButton,
      ForgeButton,
      LoginComponents,
      HeaderBar,
    },
    methods: {
      showLoginPopup() {
        this.showLogin = true;
      },
      closeLoginPopup() {
        this.showLogin = false;
      },
      handleLogin() {
        this.showLogin = true;
      },
      handleLogout() {
        this.showLogin = false; 
      },
      handleUserConnectedEvent() {
        this.showLogin = false;
      },
      handleUserCreateAccountEvent() {
        alert("Nous avons envoyer lien de vérification sur votre adresse mail")
      }
    },
    created() {
      this.isAuthenticated = !!localStorage.getItem('token');
    }
  };
</script>



<style scoped>

  #background-homeview {
    position: absolute;
    background-image: url('@/assets/home/background.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  #background-homeview::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6); 
  }

</style>