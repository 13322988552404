<template>
  <div class="card">
    <div class="card-body">
      <div class="col-11 ml-5">
        <form @submit.prevent="createCharacter">
          <div class="d-flex justify-content-between mb-4">
            <div class="center-list-label">
              <h3>Créez votre personnage</h3>
            </div>
          </div>

          <label for="name">Nom</label>
          <input id="name" class="form-control m-1" type="text" v-model="name" placeholder="Nom" required/>

          <label for="race">Race</label>
          <input id="race" class="form-control m-1" type="text" v-model="race" placeholder="Race" required/>

          <label for="martial-class">Classe</label>
          <input id="martial-class" class="form-control m-1" type="text" v-model="martialClass" placeholder="Classe" required/>

          <label for="personality">Personnalité</label>
          <textarea id="personality" rows="3" class="form-control m-1" v-model="personality" placeholder="Personnalité" required minlength="1"></textarea>

          <label for="background">Historique</label>
          <textarea id="background" rows="5" class="form-control m-1" v-model="background" placeholder="Historique" required minlength="1"></textarea>

          <label for="picture">Image du personnage</label>
          <input id="picture" class="form-control m-1" type="file" @change="handleFileUpload" placeholder="Image du personnage"/>
          <div class="d-flex justify-content-center m-4">
            <img v-if="imagePreview" :src="imagePreview" class="preview" alt="Aperçu de l'image">
          </div>

          <div class="d-flex justify-content-center m-4">
            <button type="submit" class="btn btn-success">Créer</button>
            <button type="button" class="btn btn-danger ms-2" @click="cancel">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
    import {createGameCharacter} from "@/services/dndgate/endpoints/game-characters";
    import GameCharacterCreationRequestDto from "@/dto/request/GameCharacterCreationRequestDto";
    
    export default {
      data() {
        return {
          name: '',
          race: '',
          martialClass: '',
          personality: '',
          background: '',
          successMessage: '',
          errorMessage: '',
          picturePresentation: null,
          imagePreview: null
        };
      },
      watch: {
        adventureId() {
          this.getAdventureDetails()
        }
      },
      computed: {
        adventureId() {
          return this.$route.params.adventureId;
        }
      },
      methods: {
        handleFileUpload(event) {
          this.picturePresentation = event.target.files[0];
          const validTypes = ['image/png', 'image/jpeg'];

          if (!validTypes.includes(this.picturePresentation.type)) {
            this.imagePreview = null;
            alert("Le type n'est pas valide. Seul les .png sont autorisé");
            return;
          }

          const reader = new FileReader();
          reader.onload = e => {
            this.imagePreview = e.target.result;
          };
          reader.readAsDataURL(this.picturePresentation);
        },
        async createCharacter() {
          this.isLoading = true
          const gameCharacterData = new GameCharacterCreationRequestDto(
              this.adventureId,
              this.name.trim(),
              this.race.trim(),
              this.martialClass.trim(),
              this.personality.trim(),
              this.background.trim(),
          );
          try {
            await createGameCharacter(gameCharacterData, this.picturePresentation)
            this.$router.push({name: 'tavern/adventure/gameCharacterList'})
          } finally {
            this.isLoading = false
          }
        },
        cancel() {
          this.$router.push({name: 'tavern/adventure/gameCharacterList'})
        },
      },
    };
</script>
  
<style scoped>

.preview {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.8);
  max-height: 40vh;
  max-width: 60vw;
}

  .form-adventure label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }


</style>
