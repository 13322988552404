<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content" class="align-content-center">
        <h2>Cette fonctionalité n'est pas encore implémenté</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnimplementedFonctionality"
}
</script>

<style scoped>

</style>