import * as THREE from "three";

import stone from '@/assets/forge/map/stone.png'
import dirt from '@/assets/forge/map/dirt.png'
import grass from '@/assets/forge/map/grass.png'
import leaves from '@/assets/forge/map/leaves.png'

const textureLoader = new THREE.TextureLoader();

const textures = {
    stone: textureLoader.load(stone),
    dirt: textureLoader.load(dirt),
    grass: textureLoader.load(grass),
    leaves: textureLoader.load(leaves),
}

function getMaterials(type) {
    return [
        new THREE.MeshBasicMaterial({ map: textures.dirt }), // right
        new THREE.MeshBasicMaterial({ map: textures.dirt }), // left
        new THREE.MeshBasicMaterial({ map: textures[type] }),  // top
        new THREE.MeshBasicMaterial({ map: textures.dirt }), // bottom
        new THREE.MeshBasicMaterial({ map: textures.dirt }), // front
        new THREE.MeshBasicMaterial({ map: textures.dirt })  // back
    ];
}

export function generateCube(type, size) {
    const geometry = new THREE.BoxGeometry(size, size, size);
    const materials = getMaterials(type)
    return new THREE.Mesh(geometry, materials);
}