<template>
  <div class="card">
    <div class="card-body">
      <div class="col-11 ml-5">
        <form @submit.prevent="updateHistoricalNote">
          <div class="d-flex justify-content-between mb-4">
            <div class="center-list-label">
              <h3>Modifier la note</h3>
            </div>
          </div>

          <label for="date">Date</label>
          <input id="date" class="form-control m-1" type="date" v-model="date" required/>

          <label for="visibility">Visibilité</label>
          <select id="visibility" v-model="visibility">
            <option value="PRIVATE">Privé</option>
            <option value="PUBLIC">Public</option>
          </select>

          <label for="content">Contenue</label>
          <textarea id="content" rows="15" class="form-control m-1" v-model="content" placeholder="Vous avez ..." required minlength="1"></textarea>

          <div class="d-flex justify-content-center m-4">
            <button type="submit" class="btn btn-success">Ajouter</button>
            <button type="button" class="btn btn-danger ms-2" @click="cancel">Annuler</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {getHistoricalNoteDetails, updateHistoricalNote} from "@/services/dndgate/endpoints/historical-notes";
import HistoricalNoteCreationRequestDto from "@/dto/request/HistoricalNoteCreationRequestDto";
    
    export default {
      data() {
        return {
          date: null,
          content: '',
          historic: null,
        };
      },
      watch: {
        adventureId() {
          this.getHistoricalNoteDetails()
        },
        historicalNoteId() {
          this.getHistoricalNoteDetails()
        }
      },
      computed: {
        adventureId() {
          return this.$route.params.adventureId;
        },
        historicalNoteId() {
          return this.$route.params.historicalNoteId;
        },
      },
      mounted: async function() {
        await this.getHistoricalNoteDetails()
        this.date = this.historic.date
        this.content = this.historic.content
      },
      methods: {
        async getHistoricalNoteDetails() {
          this.isLoading = true
          try {
            this.historic = await getHistoricalNoteDetails(this.historicalNoteId)
          } finally {
            this.isLoading = false
          }
        },
        async updateHistoricalNote() {
          const historicalNoteData = new HistoricalNoteCreationRequestDto(
              this.adventureId,
              this.content.trim(),
              this.date,
          );
          try {
            await updateHistoricalNote(this.historicalNoteId, historicalNoteData)
            this.$router.push({name: 'tavern/adventure/historicalNoteList'})
          } finally {
            this.isLoading = false
          }
        },
        exit() {
          this.$router.push({name: 'tavern/adventure/historicalNoteList'})
        },
      },
    };
</script>
  
<style scoped>

  .form-adventure label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

</style>
