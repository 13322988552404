<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="race != null && !isLoading">
          <div id="header">
            <img :src="getImagePath(raceIndex)" alt="class image">
          </div>
          <h1>{{race.name}}</h1>
          <h2 class="mt-4">{{race.name}} traits</h2>
          <hr>
          <p>
            <b>Characteristics increase : </b>
            <a v-for="ability in race.ability_bonuses" :key="ability">
              Your value of {{ ability.ability_score.full_name }} increase by {{ ability.bonus }}.
            </a>
            <a v-if="race.ability_bonus_options != null">
              {{ race.ability_bonus_options.choose }} other ability of your choice increase by 1.
            </a>
          </p>
          <p><b>Age : </b>{{race.age}}</p>
          <p><b>Alignment : </b>{{race.alignment}}</p>
          <p><b>Size : </b>{{race.size_description}}</p>
          <p><b>Speed : </b> Your base ground speed is {{race.speed}} feet.</p>
          <p v-for="trait in race.traits" :key="trait">
            <b>{{ trait.name }} : </b>
            <a v-for="desc in trait.desc" :key="desc">{{ desc }}</a>
          </p>
          <p><b>Languages : </b>{{ race.language_desc }}</p>
          <div v-for="subrace in race.subraces" :key="subrace">
            <h3 class="mt-4">Sub race : {{ subrace.name }}</h3>
            <hr>
            <p>
              <b>Description : </b>{{ subrace.desc }}
            </p>
            <p v-if="subrace.ability_bonuses != null">
              <b>Characteristics increase : </b>
              <a v-for="ability in subrace.ability_bonuses" :key="ability">
                Your value of {{ ability.ability_score.full_name }} increase by {{ ability.bonus }}.
              </a>
              <a v-if="subrace.ability_bonus_options != null">
                {{ subrace.ability_bonus_options.choose }} other ability of your choice increase by 1.
              </a>
            </p>
            <p v-for="racial_traits in subrace.racial_traits" :key="racial_traits">
              <b>{{ racial_traits.name }} : </b>
              <a v-for="desc in racial_traits.desc" :key="desc">{{ desc }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {getRaceDescription} from "@/services/dndofficial/endpoints/races";

  export default {
    data() {
      return {
        isLoading: false,
        race: null
      };
    },
    watch: {
      raceIndex() {
        this.getRaceDescription()
      }
    },
    computed: {
      raceIndex() {
        return this.$route.params.raceIndex;
      }
    },
    mounted: async function(){
      await this.getRaceDescription()
    },
    methods: {
      async getRaceDescription() {
        this.isLoading = true
        try {
          const response = await getRaceDescription(this.raceIndex)
          this.race = response.data.race
        } catch (error) {
          this.race = null
        } finally {
          this.isLoading = false
        }
      },
      getImagePath(name) {
        try {
          return require(`@/assets/library/races/${name}.png`);
        } catch (error) {
          console.error('Failed to load the image:', error);
          return ''; 
        }
      }
    }
  };
</script>
  
<style scoped>

  #header {
    text-align: center;
  }

  #header img {
    height: 400px;
  }

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin: 2px 0;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

</style>