import {gql_instance} from "../axios";


const getAbilityScores = async () => {
    const query = {
        query: `
query AbilityScores($orderDirection: OrderByDirection) {
  abilityScores(order_direction: $orderDirection) {
    index
    name
    full_name
  }
}
`,
        variables: {
            orderDirection: "ASCENDING"
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting ability scores: ', error)
        throw error;
    }
}

export { getAbilityScores };