import {gql_instance} from "../axios";

const getRules = async () => {
    const query = {
        query: `
query Rules {
  rules {
    index
    name
    subsections {
      index
      name
    }
  }
}
`
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting rules: ', error)
        throw error;
    }
}

const getRuleDescription = async (index) => {
    const query = {
        query: `
query RuleDescription($index: String) {
  rule(index: $index) {
    index
    name
    desc
    subsections {
      index
      name
      desc
    }
  }
}
`,
        variables: {
            index: index
        }
    }

    try {
        const response = await gql_instance.post("", query);
        return response.data
    } catch (error) {
        console.error('Error while getting rule description: ', error)
        throw error;
    }
}


export { getRules, getRuleDescription };