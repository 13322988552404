<template>
  <div id="body-content">
    <div id="body-card">
      <div id="body-card-content">
        <div v-if="spell != null && !isLoading">
          <h1>{{spell.name}}</h1>
          <h3>{{spell.school.name}} - level {{ spell.level }}</h3>
          <p><b>Casting time : </b>{{ spell.casting_time }}</p>
          <p><b>Range : </b>{{ spell.range }}</p>
          <p><b>Components : </b>{{ spell.components.join(', ') }} <span v-if="spell.material">({{ spell.material }})</span></p>
          <p><b>Duration : </b><span v-if="spell.concentration">concentration, </span>{{ spell.duration }}</p>
          <p><b>Classes : </b>
            <span v-for="(c, index) in spell.classes" :key="c">
              {{ c.name }}<span v-if="index < spell.classes.length - 1">, </span>
            </span>
          </p>
          <br>
          <div v-if="formattedDesc.length > 0">
            <div v-for="desc in formattedDesc" :key="desc">
              <p v-html="desc" ></p>
            </div>
          </div>
          <div v-if="formattedHigherLevelDesc.length > 0">
            <p><b>At a higher level</b></p>
            <div v-for="higherLevel in formattedHigherLevelDesc" :key="higherLevel">
              <p v-html="higherLevel"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

  import {getSpellDescription} from "@/services/dndofficial/endpoints/spells";
  import MarkdownIt from "markdown-it/lib/index.mjs";

  export default {
    data() {
      return {
        isLoading: false,
        spell: null,
        formattedDesc: [],
        formattedHigherLevelDesc: []
      };
    },
    watch: {
      spellIndex() {
        this.getSpellDescription()
      }
    },
    computed: {
      spellIndex() {
        return this.$route.params.spellIndex;
      }
    },
    mounted: async function(){
      await this.getSpellDescription()
    },
    methods: {
      async getSpellDescription() {
        this.isLoading = true
        try {
          const response = await getSpellDescription(this.spellIndex)
          this.spell = response.data.spell
          this.convertMarkdownToHtml()
        } catch (error) {
          this.spell = null
          this.formattedDesc = []
          this.formattedHigherLevelDesc = []
        } finally {
          this.isLoading = false
        }
      },
      convertMarkdownToHtml() {
        const md = new MarkdownIt();
        this.formattedDesc = this.spell.desc.map(desc => (md.render(desc)))
        if (this.spell.higher_level) {
          this.formattedHigherLevelDesc = this.spell.higher_level.map(higher_level => (md.render(higher_level)))
        }
      }
    }
  };
</script>
  
<style scoped>

  h1 {
    color: #4C6477;
  }

  h2 {
    color: #4C6477;
    margin-bottom: 0;
  }

  h3 {
    color: #435A3F;
    margin-bottom: 0;
  }

  hr {
    margin-top: 2px;
  }

  p {
    margin-top: 3px;
    margin-bottom: 3px;
  }

</style>